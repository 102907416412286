import { createSlice } from '@reduxjs/toolkit';
import { arrayGroupedBy, arrayToMap } from '../../../utils/Utils';
import { isAfter } from 'date-fns';
import { isValueEmpty } from '@grethics/commons';

const initialState = { list: [], paging: { size: 5, current: 1 } };

export const MakeListSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setGamesAction: (state, action) => {
      const { games } = action.payload;
      state.list = [...games];
    },
    addGameAction: (state, action) => {
      const { game } = action.payload;
      state.list.unshift(game);
    },
    updateGameAction: (state, action) => {
      const { game } = action.payload;
      const idx = state.list.findIndex((g) => g.slug === game?.slug);
      if (idx >= 0) {
        state.list[idx] = game;
      }
    },
    deleteGameAction: (state, action) => {
      const { slug } = action.payload;
      state.list = state.list.filter((game) => game.slug !== slug);
    },
    clearGamesAction: () => {
      return initialState;
    },
    onNextTablePage: (state, action) => {
      state.paging.current++;
    },
    onPrevTablePage: (state, action) => {
      state.paging.current--;
    },
    onNavToTablePage: (state, action) => {
      const { page } = action.payload;
      state.paging.current = page;
    },
  },
});

export const { setGamesAction, addGameAction, updateGameAction, deleteGameAction, clearGamesAction, onNavToTablePage, onNextTablePage, onPrevTablePage } = MakeListSlice.actions;
export default MakeListSlice.reducer;
