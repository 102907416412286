import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const PlayListSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    setPlayListAction: (state, action) => {
      const { games } = action.payload;
      return games;
    },
    clearPlayListAction: () => {
      return initialState;
    },
  },
  /* extraReducers: (builder) => {
    builder
      .addCase([authSlice.actions.logoutAction], () => {
        return initialState;
      })
      .addCase('play/playerConnectedAction', (state, action) => {
        const { playerId } = action.payload;
        const player = Object.values(state.players).find((p) => p?.id === playerId);
        if (player) {
          player.connected = true;
        }
      })
      .addCase('play/playerDisconnectedAction', (state, action) => {
        const { playerId } = action.payload;
        const player = Object.values(state.players).find((p) => p?.id === playerId);
        if (player) {
          state.players[player.side].connected = false;
        }
      });
  }, */
});

export const { setPlayListAction, clearPlayListAction } = PlayListSlice.actions;
export default PlayListSlice.reducer;
