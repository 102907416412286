import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSysGames from '../../../../hooks/admin/useSysGames';

export default function GameSelector({ value, onValueChange }) {
  const { games } = useSysGames();
  return (
    <FormControl fullWidth sx={{ my: 2 }}>
      <Autocomplete
        id='game-selector'
        fullWidth
        multiple
        options={games
          .filter((g) => !g.archived)
          .map((g) => {
            return { id: g.id, title: g.title, slug: g.slug };
          })}
        value={value}
        autoHighlight
        disableClearable
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        getOptionLabel={(game) => game.title}
        renderOption={(props, game) => (
          <Box component='li' {...props}>
            {game.title}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Games'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(event, games) => {
          console.log(games);
          onValueChange(games);
        }}
      />
    </FormControl>
  );
}
