import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import axios, { all } from 'axios';
import React, { useEffect, useState } from 'react';

export default function CitySelector({ country, value, onValueChange }) {
  const [allCountries, setAllCountries] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    setCities([]);
    axios
      .get('https://countriesnow.space/api/v0.1/countries')
      .then((response) => {
        const allCountries = response.data.data;
        setAllCountries(allCountries);
      })
      .catch((error) => console.log('could not get countries'));
  }, []);

  useEffect(() => {
    if (allCountries.length > 0 && Array.isArray(country)) {
      const selectedCountries = allCountries.filter((record) => country.includes(record.country));
      const allCities = selectedCountries.reduce((acc, record) => [...acc, ...record.cities], []);
      setCities(allCities);
    }
  }, [allCountries, country]);
  return (
    <FormControl fullWidth sx={{ my: 2 }}>
      <Autocomplete
        id='city-selector'
        fullWidth
        multiple
        options={cities}
        value={value}
        autoHighlight
        disableClearable
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => {
          return option === value;
        }}
        getOptionLabel={(city) => city}
        renderOption={(props, city) => (
          <Box component='li' {...props}>
            {city}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Cities'
            placeholder='Any'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(event, value) => {
          onValueChange(value);
        }}
      />
    </FormControl>
  );
}
