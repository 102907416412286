import React, { useEffect, useState } from 'react';
import MediaViewer from './MediaViewer';
import parse from 'html-react-parser';

export default function InfoContent({ content, onContentShown }) {
  const [mediaLoaded] = useState(false);

  useEffect(() => {
    if (mediaLoaded && onContentShown) {
      onContentShown(true);
    }
  }, [onContentShown, mediaLoaded]);

  if (!content) {
    return <></>;
  }
  return (
    <div className='w-full flex flex-col items-center justify-start'>
      {content.mediaId && (
        <MediaViewer
          mediaId={content.mediaId}
          onMediaLoad={() => {
            if (onContentShown) onContentShown(true);
          }}
          onMediaError={() => {
            if (onContentShown) onContentShown(true);
          }}
        />
      )}
      {content.text && <div>{parse(content.text, { trim: true })}</div>}
    </div>
  );
}
