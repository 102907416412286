import { Navigate, Outlet } from 'react-router-dom';
import ProtectedRoute from '../view/ProtectedRoute';
import NewTablePage from '../view/screens/play/NewTablePage';
import GameDesignPage from '../view/screens/make/design/GameDesignPage';
import BoardDesignPage from '../view/screens/make/design/BoardDesignPage';
import GameConfigPage from '../view/screens/make/config/GameConfigPage';
import BasicsConfigPage from '../view/screens/make/config/BasicsConfigPage';
import BoardConfigPage from '../view/screens/make/config/BoardConfigPage';
import AccessConfigPage from '../view/screens/make/config/AccessConfigPage';
import { USER_ROLE } from '@grethics/commons';
import GameBrowserPage from '../view/screens/make/GameBrowserPage';
import MakeGameRootPage from '../view/screens/make/MakeGameRootPage';
import QuizDesignPage from '../view/screens/make/design/QuizDesignPage';
import FactDesignPage from '../view/screens/make/design/FactDesignPage';
import StoryDesignPage from '../view/screens/make/design/StoryDesignPage';
import DidYouKnowDesignPage from '../view/screens/make/design/DidYouKnowDesignPage';
import SoundsConfigPage from '../view/screens/make/config/SoundsConfigPage';
import IconDesignPage from '../view/screens/make/design/IconDesignPage';

export const MakeRoutes = [
  {
    path: 'make',
    element: (
      <ProtectedRoute allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.AUTHOR]}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={'games'} />,
      },
      {
        path: 'games',
        children: [
          { path: '', element: <GameBrowserPage /> },
          {
            path: 'new',
            element: <NewTablePage />,
            //errorElement: <PlayErrorBoundary />,
          },
          {
            path: ':slug',
            element: <MakeGameRootPage />,
            children: [
              { path: '', element: <Navigate to={'design'} /> },
              {
                path: 'design',
                element: <GameDesignPage />,
                children: [
                  {
                    path: '',
                    index: true,
                    element: <Navigate to='board' />,
                  },
                  {
                    path: 'board',
                    index: true,
                    element: <BoardDesignPage />,
                  },
                  {
                    path: 'facts/:contentId',
                    index: true,
                    element: <FactDesignPage />,
                  },
                  {
                    path: 'quizzes/:contentId',
                    index: true,
                    element: <QuizDesignPage />,
                  },
                  {
                    path: 'stories/:contentId',
                    index: true,
                    element: <StoryDesignPage />,
                  },
                  {
                    path: 'icons/:contentId',
                    index: true,
                    element: <IconDesignPage />,
                  },
                  {
                    path: 'dyks/:contentId',
                    index: true,
                    element: <DidYouKnowDesignPage />,
                  },
                ],
              },
              {
                path: 'config',
                element: <GameConfigPage />,
                children: [
                  {
                    path: '',
                    index: true,
                    element: <Navigate to='basics' />,
                  },
                  {
                    path: 'basics',
                    index: true,
                    element: <BasicsConfigPage />,
                  },
                  {
                    path: 'board',
                    element: <BoardConfigPage />,
                  },
                  {
                    path: 'sounds',
                    element: <SoundsConfigPage />,
                  },
                  {
                    path: 'access',
                    element: <AccessConfigPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
