import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { ActiveGameController } from '../../../control/play/ActiveGameController';
import { useDispatch, useSelector } from 'react-redux';
import InfoContent from './InfoContent';
import { ContentType } from '@grethics/commons';
import QuizContent from './QuizContent';
import { isEmpty } from 'lodash';
import { areEqual } from '../../../utils/Utils';
import { setPlayerAnswerAction } from '../../../model/store/slices/PlaySlice';
import { GameObserver } from '../../../control/play/GameObserver';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import { CropSquareOutlined } from '@mui/icons-material';

const ContentModal = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const board = useSelector((state) => state.play.current.spec.board);

  const activeSide = useSelector((state) => state.play.current.table.activeSide);
  const isActing = ActiveGameController.isPrimaryActor();
  const [player, setPlayer] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [content, setContent] = useState(null);
  const [closable, setClosable] = useState(false);
  const dispatch = useDispatch();

  /* set the current content type */
  useEffect(() => {
    if (player) {
      const curSquare = player?.state?.curSquare ?? 0;
      const { content, type } = board.path.asArray[curSquare];
      setContentType(type);
      setContent(content);
      if (type !== ContentType.QUIZ || !content) {
        setClosable(true);
      }
    }
  }, [player, board]);

  const onQuizAnswered = useCallback(
    (answer) => {
      console.log(`Current Answer: `, JSON.stringify({ answer }));
      let isCorrect = true;
      const { correctAnswer } = content?.body;
      if (correctAnswer) {
        isCorrect = areEqual(answer, correctAnswer);
        console.log('isCorrect: ', isCorrect);
      }
      dispatch(setPlayerAnswerAction({ side: activeSide, answer, isCorrect }));
      GameObserver.setPlayerAnswer({ side: activeSide, answer, isCorrect });
      setClosable(!isEmpty(answer));
    },
    [activeSide, content, dispatch]
  );

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setContent(null);
    setClosable(false);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(player) {
      setPlayer(player);
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <>
      {/*  <button
        className='w-full bg-red-700 text-white'
        onClick={() => {
          setPlayer(players[activeSide]);
          //setContent(board.path.asArray[3].content);
          //setContentType(ContentType.QUIZ);
          setOpen(true);
        }}>
        Open Modal
      </button> */}
      <div>
        <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
          <DisposableDialogTitle id='customized-dialog-title'>
            {'[' + player?.state?.curSquare + ']'}: {content?.title ?? ''} {true && <>{` (${player?.name} plays)`}</>}
          </DisposableDialogTitle>
          <DialogContent dividers sx={{}}>
            {[ContentType.FACT, ContentType.DID_YOU_KNOW, ContentType.STORY].includes(contentType) && (
              <InfoContent
                content={content?.body}
                onContentShown={() => {
                  setClosable(true);
                }}
              />
            )}
            {contentType === ContentType.QUIZ && <QuizContent content={content} onQuizAnswered={onQuizAnswered} />}
          </DialogContent>
          {isActing && (
            <DialogActions sx={{ justifyContent: 'end', bgcolor: 'primary.light' }}>
              <Button variant='contained' autoFocus onClick={() => ActiveGameController.submitPlayerResponse()} disabled={!closable}>
                OK
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    </>
  );
});

export default ContentModal;
