import { isValueEmpty } from '@grethics/commons';
import { AccountCircleOutlined, BarChart, CalendarMonthOutlined, DesignServices, LanguageOutlined, Tune, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Interweave } from 'interweave';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import useMediaHook from '../../../hooks/useMediaHook';
import { GameItemErrorFallback } from '../common/ErrorFallbacks';
import LightTooltip from '../common/LightTooltip';

export default function GameItem({ game }) {
  const { media } = useMediaHook(game.logoMediaId);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  const isPublished = !!game.publishedAt;
  const editGame = (mode) => {
    navigate(`${game.slug}/${mode}`);
  };

  return (
    <Grid item md={12} sm={12}>
      <Card>
        <ErrorBoundary fallback={<GameItemErrorFallback />}>
          <CardContent sx={{ padding: 0 }}>
            <Box component={'div'} className='flex flex-row '>
              <div className='p-1 flex flex-1 flex-row items-center justify-between'>
                <Typography variant='h5'>{game.title}</Typography>
              </div>
            </Box>
            <Box>
              <Stack direction={{ md: 'row', sm: 'column' }} spacing={{ xs: 0, md: 2 }} sx={{ p: 1, borderTop: 0.5, borderColor: 'lightgray', color: 'gray' }}>
                {!imageLoaded && <Skeleton variant='rectangular' sx={{ top: 0, minHeight: 100, minWidth: 100, m: 0 }} animation={isValueEmpty(game?.logoMediaId) ? '' : 'pulse'} />}
                {!isEmpty(media) && <img onLoad={() => setImageLoaded(true)} src={media.url} alt='Game Logo' className='w-[100px] h-[100px] border-gray-200 border p-1 rounded shadow-md' />}
                <div className='line-clamp-4 px-2'>
                  <Interweave content={game.description ?? 'No Description given'} />
                </div>
              </Stack>
              <div className='flex flex-row p-2 flex-wrap'></div>
              <div className='flex flex-1 flex-row flex-wrap border-t py-2' sx={{ p: 1, borderTop: 0.5, borderColor: 'lightgray', color: 'gray' }}>
                <div className='flex flex-row items-center p-1 border-r-2'>
                  <LightTooltip title={`Owner: ${game.owner?.name}`}>
                    <span className='flex items-center'>
                      <AccountCircleOutlined sx={{ fontSize: 20, color: 'primary.light' }} />
                      <span>{game.owner?.name}</span>
                    </span>
                  </LightTooltip>
                </div>
                <div className='flex flex-row items-center  p-1 border-r-2'>
                  <LightTooltip title={`Last update on: ${format(new Date(game.updatedAt), 'yyyy/MM/dd')}`}>
                    <span className='flex items-center'>
                      <CalendarMonthOutlined sx={{ fontSize: 20, color: 'primary.light' }} />
                      <span>{format(new Date(game.updatedAt), 'yyyy/MM/dd')}</span>
                    </span>
                  </LightTooltip>
                </div>
                <div className='flex flex-row items-center  p-1 border-r-2'>
                  <LightTooltip title={`Game Language: ${game.lang}`}>
                    <span className='flex items-center'>
                      <LanguageOutlined sx={{ fontSize: 20, color: 'primary.light' }} />
                      {game.lang}
                    </span>
                  </LightTooltip>
                </div>
                <div className='flex flex-row items-center  p-1'>
                  <LightTooltip title={`${isPublished ? 'Publicly available' : 'Not published yet'}`}>
                    <span className='flex items-center'>
                      {isPublished && <Visibility sx={{ fontSize: 20, color: 'primary.light' }} />}
                      {!isPublished && <VisibilityOff sx={{ fontSize: 20, color: 'primary.light' }} />}
                    </span>
                  </LightTooltip>
                </div>
              </div>
            </Box>
          </CardContent>
          <Stack direction={'row-reverse'} spacing={2} className='border-t border-gray-200 p-2 justify-between'>
            <div className='flex flex-row gap-4'>
              <IconButton color='primary' title='Design' sx={{ border: 1 }} onClick={() => editGame('design')}>
                <DesignServices />
              </IconButton>
              <IconButton color='secondary' title='Configure' sx={{ border: 1 }} onClick={() => editGame('config')}>
                <Tune />
              </IconButton>
            </div>
            {game.leaderboards && (
              <Button
                variant='text'
                startIcon={<BarChart />}
                onClick={() => {
                  navigate(`../../rankings/${game.slug}`);
                }}>
                Rankings
              </Button>
            )}
          </Stack>
        </ErrorBoundary>
      </Card>
    </Grid>
  );
}
