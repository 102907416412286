import React, { useCallback, useEffect, useState } from 'react';
import { SystemDao } from '../../model/dao/SystemDao';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import { setTables, setUsers, updateTableAction, updateUserAction } from '../../model/store/slices/admin/AdminSlice';
import { enqueueSnackbar } from 'notistack';
import { isValueEmpty } from '@grethics/commons';

export default function useSysTables() {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { tables } = useSelector((state) => state.admin);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch(setLoading(true));
        const tables = await SystemDao.getTables();
        console.log('tables fetched', tables);
        dispatch(setTables({ tables }));
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (!fetched) {
      fetchUsers();
    }
  }, [fetched, dispatch]);

  const fetchTable = useCallback(async (id) => {
    try {
      console.log('fetching table');
      const table = await SystemDao.getTabe(id);
      return { ...table };
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }, []);

  const updateTable = useCallback(
    async (data) => {
      try {
        dispatch(setLoading(true));
        const table = await SystemDao.updateTable(data);
        enqueueSnackbar('User updated...', { variant: 'success' });
        dispatch(updateTableAction({ table }));
        return table;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  return { tables, fetchTable, updateTable };
}
