import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, gridClasses } from '@mui/x-data-grid';
import { Badge, Button, IconButton, Tooltip, Typography } from '@mui/material';
import PlayerAvatar from '../play/PlayerAvatar';
import { AccountBox, Edit } from '@mui/icons-material';
import PlayerEditModal from './PlayerEditModal';
import NoDataRows from './NoDataRows';

function PlayerGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter className='flex flex-grow w-full' />
    </GridToolbarContainer>
  );
}

export default function PlayerDataGrid({ players }) {
  const playerEditorRef = React.createRef();
  const handleClick = (event, cellValues) => {
    if (playerEditorRef.current) {
      playerEditorRef.current.show(cellValues.row.id);
    }
  };

  const onFilterChange = (value) => {
    console.log(value);
  };

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const columns = [
    {
      field: 'imageId',
      headerName: 'Photo',
      minWidth: 50,
      filterable: false,
      valueGetter: (cellValues) => {
        return cellValues.row.player.imageId;
      },
      renderCell: (cellValues) => {
        return <PlayerAvatar imageId={cellValues.value} />;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'place',
      headerName: 'City/Country',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.player.city ?? 'Some'}/${params.row.player.country ?? 'Some'}`;
      },
    },
    {
      field: 'ageGroup',
      headerName: 'Age Group',
      minWidth: 100,
      editable: false,
      valueGetter: (params) => {
        const value = params.row.player.ageGroup;
        return value;
      },
      renderCell: (params) => {
        const value = params.row.player.ageGroup;
        return (
          <Tooltip title={value}>
            <span>{`${value
              .split('_')
              .map((t) => t[0])
              .join('')}`}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'user',
      headerName: 'Account',
      flex: 1,
      minWidth: 250,
      editable: false,
      valueGetter: (cellValues) => {
        const user = cellValues.row.user;
        return user ? `${user.name} (${user.email})` : 'Unregistered';
      },
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.user?.name ?? 'Unregistered'}</div>
            <div className='text-gray-500'>{params.row.user?.email}</div>
          </div>
        );
      },
    },
    {
      field: 'Edit',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              handleClick(event, cellValues);
            }}>
            <Edit />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box sx={{ minHeight: 600, minWidth: 0, flex: 1 }}>
      <PlayerEditModal ref={playerEditorRef} />
      <DataGrid
        sx={{
          [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
            outline: 'transparent',
          },
          [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
        }}
        rows={players}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        slots={{ toolbar: PlayerGridToolbar, noRowsOverlay: () => <NoDataRows msg={'No players yet'} /> }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        onFilterModelChange={onFilterChange}
        autoPageSize
        density='comfortable'
        disableRowSelectionOnClick
        disableColumnSelector
        onCellClick={handleCellClick}
      />
    </Box>
  );
}
