import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemDao } from '../../model/dao/SystemDao';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import { updateUserAction } from '../../model/store/slices/admin/AdminSlice';
import { loginAction, updateProfileAction } from '../../model/store/slices/AuthSlice';
import { AuthDao } from '../../model/dao/AuthDao';

export default function useProfile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { player } = user;

  const updateUserProfile = useCallback(
    async (data) => {
      try {
        dispatch(setLoading(true));
        const user = await AuthDao.updateMyUserProfile(data);
        enqueueSnackbar('User updated...', { variant: 'success' });
        dispatch(updateProfileAction(user));
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const upsertPlayerProfile = useCallback(
    async (data) => {
      try {
        let player;
        dispatch(setLoading(true));
        player = await AuthDao.upsertMyPlayerProfile(data);
        enqueueSnackbar('Done...', { variant: 'success' });
        dispatch(updateProfileAction({ player }));
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  return { user: { ...user, player: undefined }, player: player ?? {}, updateUserProfile, upsertPlayerProfile };
}
