import { Collapse, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { getActiveRoute } from '../../../utils/Utils';
import { PALLETE } from '../../../config/constants';

export default function AdminHomePage() {
  const location = useLocation();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [route, setRoute] = useState('dashboard');

  useEffect(() => {
    const newRoute = getActiveRoute(location?.pathname, ['dashboard', 'users', 'players', 'games', 'tables', 'leaderboards']) ?? 'dashboard';
    setRoute(newRoute);
  }, [location.pathname]);

  return (
    <div className='flex flex-col flex-1 w-full'>
      {/* <Typography variant='h4' className='w-full border-b'>
        Administrate
      </Typography> */}
      <Paper className='flex flex-col w-full items-start mt-2'>
        <div className='flex flex-row w-full mt-2'>
          <Collapse orientation='horizontal' in={drawerOpen} className='h-full'>
            <div className='flex flex-col w-[250px] mr-1 p-1 justify-between h-full '>
              <div className='w-full rounded-sm p-2 text-lg' style={{ backgroundColor: PALLETE.EMERLAND.extraLight }}>
                <Typography variant='h5'>Administration</Typography>
              </div>
              <Paper className='flex flex-1 flex-col justify-between h-full mt-2'>
                <Tabs orientation='vertical' variant='fullWidth' value={route}>
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='dashboard' label={<span className='text-left w-full p-2 text-reg'>Dashboard</span>} value='dashboard' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='games' label={<span className='text-left w-full p-2 text-reg'>Games</span>} value='games' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='users' label={<span className='text-left w-full p-2 text-reg'>Users</span>} value='users' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='players' label={<span className='text-left w-full p-2 text-reg'>Players</span>} value='players' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='tables' label={<span className='text-left w-full p-2 text-reg'>Tables</span>} value='tables' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='leaderboards' label={<span className='text-left w-full p-2 text-reg'>Leaderboards</span>} value='leaderboards' />
                </Tabs>
              </Paper>
            </div>
          </Collapse>
          <div className='flex flex-col flex-1 p-1 ml-1'>
            <Outlet />
          </div>
        </div>
      </Paper>
    </div>
  );
}
