import { Edit } from '@mui/icons-material';
import { Badge, IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, gridClasses } from '@mui/x-data-grid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GameAvatar from '../common/GameAvatar';
import NoDataRows from './NoDataRows';

function GameGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter className='flex flex-grow w-full' />
    </GridToolbarContainer>
  );
}

export default function GameDataGrid({ games }) {
  const onFilterChange = (value) => {
    console.log(value);
  };

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const columns = [
    {
      field: 'logoMediaId',
      headerName: '',
      filterable: false,
      minWidth: 40,
      renderCell: (cellValues) => {
        const archived = cellValues?.row?.archived;
        if (archived) {
          return (
            <Tooltip title={'Game has been Deleted'}>
              <Badge variant='dot' overlap='rectangular' color={'error'}>
                <GameAvatar imageId={cellValues.value} />
              </Badge>
            </Tooltip>
          );
        } else {
          return <GameAvatar imageId={cellValues.value} />;
        }
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      type: 'string',
      minWidth: 200,
      flex: 1,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Link className='text-cyan-600' to={`../../make/games/${cellValues.row.slug}/config`}>
            {cellValues.row.title}
          </Link>
        );
      },
    },
    {
      field: 'owner',
      headerName: 'Creator',
      type: 'string',
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        const owner = params?.value;
        return owner?.name;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return new Date(params.value);
      },
      renderCell: (cellValues) => {
        const created = new Date(cellValues?.value);
        return format(created, 'yyyy/MM/dd');
      },
    },
    {
      field: 'publishedAt',
      headerName: 'Published',
      type: 'string',
      minWidth: 150,
      valueGetter: (cellValues) => {
        if (cellValues.value) {
          const published = new Date(cellValues?.value);
          return format(published, 'yyyy/MM/dd');
        }
        return 'Not yet';
      },
    },
    {
      field: '_count',
      headerName: 'Tables',
      type: 'string',
      minWidth: 50,
      valueGetter: (cellValues) => {
        const { tables } = cellValues.value;
        return tables;
      },
    },
  ];

  return (
    <Box sx={{ minHeight: 600, minWidth: 0, width: '100%' }}>
      <DataGrid
        sx={{
          [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
            outline: 'transparent',
          },
          [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
        }}
        rows={games}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slots={{ toolbar: GameGridToolbar, noRowsOverlay: () => <NoDataRows msg={'No games yet'} /> }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        onFilterModelChange={onFilterChange}
        //autoPageSize
        density='comfortable'
        disableRowSelectionOnClick
        disableColumnSelector
        onCellClick={handleCellClick}
      />
    </Box>
  );
}
