import { CustomResponseError } from '../../utils/Utils';
import { ApiClient } from './utils/ApiClient';

export const AgentDao = {
  searchPlayers: async (filter) => {
    try {
      const { data } = await ApiClient.post(`/common/players/find`, { filter });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  searchUsers: async (filter) => {
    try {
      const { data } = await ApiClient.post(`/common/users/find`, { filter });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
};
