import React from 'react';
import { useSelector } from 'react-redux';
import { GAME_STATUS, PLAY_STATUS, USER_ROLE } from '@grethics/commons';
import { playerColor } from '../../../config/constants';
import { DragIndicator } from '@mui/icons-material';

export default function StatusBar() {
  const { gameStatus, playStatus, players, activeSide, userRole, userSide } = useSelector((state) => state.play.current.table);
  const activePlayer = players[activeSide];
  const getStatusMsg = () => {
    const msgs = [];
    if (playStatus === PLAY_STATUS.WAITING) {
      msgs.push(<span key={'msg'}>Waiting for opponent to connect...</span>);
      return msgs;
    } else if (playStatus === PLAY_STATUS.READY_TO_PLAY && gameStatus === GAME_STATUS.NOT_STARTED) {
      msgs.push(
        <span key={'msg'} className='text-lime-600  text-nowrap'>
          Ready sto Start!!!
        </span>
      );
      return msgs;
    } else if (gameStatus === GAME_STATUS.STARTING_UP) {
      msgs.push(
        <div key={'msg'} className='font-bold mr-2 text-sky-500 text-nowrap'>
          Starting up.
        </div>
      );
    } else if (gameStatus === GAME_STATUS.STARTED) {
      msgs.push(
        <span key={'msg'} className='font-bold mr-2 text-sky-500 text-nowrap'>
          Playing.
        </span>
      );
    } else if (gameStatus === GAME_STATUS.COMPLETED) {
      msgs.push(
        <span key={'msg'} className='font-bold text-white'>
          Completed
        </span>
      );
    }
    if (activePlayer && [GAME_STATUS.STARTING_UP, GAME_STATUS.STARTED].includes(gameStatus)) {
      if (activePlayer.side === userSide && userRole === USER_ROLE.PLAYER) {
        msgs.push(
          <span key={'plName'} className='w-full rounded-sm text-white'>
            You play
          </span>
        );
      } else {
        msgs.push(
          <div key={'plName'} className='w-full rounded-sm text-white'>
            {activePlayer.name} plays
          </div>
        );
      }
    }
    return msgs;
  };
  let bgColor = playerColor.offline;
  if (activePlayer) {
    bgColor = playerColor.active[activePlayer.side];
  }
  return (
    <div className='flex flex-row w-full items-center' style={{ color: bgColor }}>
      {getStatusMsg()}
    </div>
  );
}
