import React, { useCallback, useEffect, useState } from 'react';
import { SystemDao } from '../../model/dao/SystemDao';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import { clearPlayerTablesAction, setPlayers, updatePlayerAction } from '../../model/store/slices/admin/AdminSlice';
import { enqueueSnackbar } from 'notistack';
import { isValueEmpty } from '@grethics/commons';

export default function useSysPlayers() {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { players } = useSelector((state) => state.admin);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        dispatch(setLoading(true));
        const players = await SystemDao.getPlayers();
        console.log('players fetched', players);
        dispatch(setPlayers({ players }));
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (isValueEmpty(players) && !fetched) {
      fetchPlayers();
    }
  }, [fetched, players, dispatch]);

  const fetchPlayer = useCallback(async (id) => {
    try {
      console.log('fetching user');
      const player = await SystemDao.getPlayer(id);
      return { ...player };
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }, []);

  const updatePlayer = useCallback(
    async (data) => {
      try {
        dispatch(setLoading(true));
        const player = await SystemDao.updatePlayer(data);
        enqueueSnackbar('Player updated...', { variant: 'success' });
        dispatch(updatePlayerAction({ player }));
        return player;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        throw new Error('');
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const clearPlayerTables = useCallback(
    async (playerId) => {
      try {
        dispatch(setLoading(true));
        await SystemDao.deletePlayerTables(playerId);
        enqueueSnackbar('Player tables deleted', { variant: 'success' });
        dispatch(clearPlayerTablesAction({ playerId }));
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  return { players, fetchPlayer, updatePlayer, clearPlayerTables };
}
