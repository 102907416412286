import { Navigate, Outlet } from 'react-router-dom';
import ProtectedRoute from '../view/ProtectedRoute';
import { USER_ROLE } from '@grethics/commons';
import ProfilePage from '../view/screens/auth/ProfilePage';
import Footer from '../view/components/common/Footer';

export const UserRoutes = [
  {
    path: 'me',
    element: (
      <ProtectedRoute allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.PLAYER, USER_ROLE.AUTHOR]}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to='profile' />,
      },
      {
        path: 'profile',
        element: <ProfilePage />,
      },
    ],
  },
];
