import { AppStore } from '../../store/AppStore';
import { loginAction, logoutAction, onAuthChecked } from '../../store/slices/AuthSlice';
import { TOKEN_KEY } from '@grethics/commons';
import * as jose from 'jose';
import { ApiClient } from './ApiClient';
import { GameObserver } from '../../../control/play/GameObserver';
import { AuthDao } from '../AuthDao';
import { enqueueSnackbar } from 'notistack';
import { sleep } from '../../../utils/Utils';

export function parseToken(token) {
  return jose.decodeJwt(token);
}

export async function checkAuthCookie() {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    try {
      const user = parseToken(token);
      ApiClient.setAuthToken(token);
      GameObserver.setAuthToken(token);
      AppStore.dispatch(loginAction(user));
      AuthDao.fetchCurrentUser()
        .then((user) => {
          AppStore.dispatch(onAuthChecked({ user }));
        })
        .catch((error) => {
          AppStore.dispatch(logoutAction());
        });
    } catch (err) {
      //enqueueSnackbar('Your authentication token has expired!', { variant: 'error' });
      AppStore.dispatch(logoutAction());
    }
  } else {
    AppStore.dispatch(onAuthChecked({}));
  }
}

export function clearAuthCookie() {
  localStorage.removeItem(TOKEN_KEY);
  ApiClient.setAuthToken(null);
  GameObserver.setAuthToken(null);
}

export function setAuthCookie(token) {
  localStorage.setItem(TOKEN_KEY, token);
  ApiClient.setAuthToken(token);
  GameObserver.setAuthToken(token);
}
