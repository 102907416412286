import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter, gridClasses } from '@mui/x-data-grid';
import { Badge, Button, IconButton } from '@mui/material';
import PlayerAvatar from '../play/PlayerAvatar';
import { AccountBox, Edit } from '@mui/icons-material';
import UserEditModal from './UserEditModal';
import NoDataRows from './NoDataRows';

function UserGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter sx={{ width: '100%' }} />
    </GridToolbarContainer>
  );
}

export default function UserDataGrid({ users }) {
  const userEditorRef = React.createRef();
  const handleClick = (event, cellValues) => {
    if (userEditorRef.current) {
      userEditorRef.current.show(cellValues.row.id);
    }
  };

  const onFilterChange = (value) => {
    console.log(value);
  };

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const columns = [
    {
      field: 'imageId',
      headerName: 'Photo',
      minWidth: 50,
      renderCell: (cellValues) => {
        return (
          <Badge variant='dot' overlap='circular' color={cellValues.row.enabled ? 'success' : 'error'}>
            <PlayerAvatar imageId={cellValues.value} />
          </Badge>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 250,
      editable: false,
    },
    {
      field: 'role',
      headerName: 'Role',
      type: 'string',
      minWidth: 50,
      editable: false,
    },
    {
      field: 'Edit',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              handleClick(event, cellValues);
            }}>
            <Edit />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box sx={{ minHeight: 600, minWidth: 0, flex: 1 }}>
      <UserEditModal ref={userEditorRef} />
      <DataGrid
        sx={{
          [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
            outline: 'transparent',
          },
          [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
        }}
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        slots={{ toolbar: UserGridToolbar, noRowsOverlay: () => <NoDataRows msg={'No users yet'} /> }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        onFilterModelChange={onFilterChange}
        autoPageSize
        density='comfortable'
        disableRowSelectionOnClick
        disableColumnSelector
        onCellClick={handleCellClick}
      />
    </Box>
  );
}
