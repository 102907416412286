import SignInPage from '../view/screens/auth/SignInPage';
import SignUpPage from '../view/screens/auth/SignUpPage';

export const AuthRoutes = [
  {
    path: 'auth/signin',
    element: <SignInPage />,
  },
  {
    path: 'auth/signup',
    element: <SignUpPage />,
  },
];