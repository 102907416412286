import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import useSysStats from '../../../hooks/admin/useSysStats';
import DashBoardCard from '../../components/admin/DashBoardCard';
import PageHeader from '../../components/common/PageHeader';

import GamesIcon from '../../../assets/img/games.png';
import LeaderBoardsIcon from '../../../assets/img/leaderboards.png';
import PlayersIcon from '../../../assets/img/players.png';
import TablesIcon from '../../../assets/img/tables.png';
import UsersIcon from '../../../assets/img/users.png';

export default function DashboardAdminPage() {
  const { stats } = useSysStats();
  const { users, players, games, tables, leaderboards } = stats;
  return (
    <div>
      <PageHeader title={'Dashboard'} />
      <div className='min-h-[800px] p-2 py-4 gap-4'>
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Link to={'../games'}>
              <DashBoardCard title='Games' count={games} icon={GamesIcon} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Link to={'../tables'}>
              <DashBoardCard title='Tables' count={tables} icon={TablesIcon} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Link to={'../users'}>
              <DashBoardCard title='Users' count={users} icon={UsersIcon} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Link to={'../players'}>
              <DashBoardCard title='Players' count={players} icon={PlayersIcon} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Link to={'../leaderboards'}>
              <DashBoardCard title='Leaderboards' count={leaderboards} icon={LeaderBoardsIcon} />
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
