import styled from '@emotion/styled';
import { Tooltip, tooltipClasses } from '@mui/material';
import React from 'react';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.70)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: 2,
  },
}));

export default LightTooltip;
