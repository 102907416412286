import { USER_ROLE } from '@grethics/commons';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';

export default function HomePage() {
  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    /* setTimeout(() => {
      throw new Error('Ooops');
    }, 4000); */
  }, []);

  if (user) {
    return <Navigate to={'./play'} replace />;
  }

  /* if (user && user.role === USER_ROLE.PLAYER) {
    return <Navigate to={'./play'} replace />;
  }

  if (user && user.role === USER_ROLE.AUTHOR) {
    return <Navigate to={'./make'} replace />;
  }

  if (user && user.role === USER_ROLE.ADMIN) {
    return <Navigate to={'./admin'} replace />;
  } */

  /* return (
    <div className='h-screen flex flex-col justify-center items-center'>
      <Link to={'/auth/signin'}>
        <button className='m-4 p-4 shadow-lg rounded-lg w-[300px] bg-blue-200 text-4xl font-bold text-blue-500'>Login</button>
      </Link>
      <Link to={'/auth/signup'}>
        <button className='m-4 p-4 shadow-lg rounded-lg w-[300px] bg-purple-200 text-4xl font-bold text-purple-500'>Register</button>
      </Link>
      <Link to={'/play'}>
        <button className='m-4 p-4 shadow-lg rounded-lg w-[300px] bg-green-200 text-4xl font-bold text-green-500'>Play Games</button>
      </Link>
    </div>
  ); */
}
