import { useEffect, useState } from 'react';
import { GameDao } from '../../model/dao/GameDao';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayListAction } from '../../model/store/slices/PlayListSlice';

const useFetchPlayListHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const playList = useSelector((state) => state.play.list);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPlayList = async () => {
      try {
        setLoading(true);
        setError(null);
        const games = await GameDao.fetchPlayableGames();
        dispatch(setPlayListAction({ games }));
      } catch (error) {
        setError(error?.message);
      } finally {
        setLoading(false);
      }
    };
    if (!playList || playList.length === 0) {
      fetchPlayList();
    }
  }, [playList, dispatch]);

  return { playList, loading, error };
};

export default useFetchPlayListHook;
