import { combineReducers, configureStore } from '@reduxjs/toolkit';
import auth from './slices/AuthSlice';
import SpecSlice from './slices/SpecSlice';
import PlaySlice from './slices/PlaySlice';
import PlayListSlice from './slices/PlayListSlice';
import GameTablesSlice from './slices/TablesSlice';
import MakeListSlice from './slices/MakeListSlice';
import GameSlice from './slices/make/GameSlice';
import GameBoardSlice from './slices/make/GameBoardSlice';
import MediaLibrarySlice from './slices/make/MediaLibrarySlice';
import GamePathSlice from './slices/make/GamePathSlice';
import GameContentsSlice from './slices/make/GameContentsSlice';
import UiSlice from './slices/UiSlice';
import AdminSlice from './slices/admin/AdminSlice';

const PlayedGameSlice = combineReducers({ spec: SpecSlice, table: PlaySlice });
const AuthoringSlice = combineReducers({ game: GameSlice, board: GameBoardSlice, path: GamePathSlice, contents: GameContentsSlice, mediaLib: MediaLibrarySlice });

const playSlice = combineReducers({ current: PlayedGameSlice, list: PlayListSlice, tables: GameTablesSlice });
const makeSlice = combineReducers({ games: MakeListSlice, current: AuthoringSlice });

export const AppStore = configureStore({
  reducer: {
    auth,
    admin: AdminSlice,
    ui: UiSlice,
    play: playSlice,
    make: makeSlice,
  },
});
