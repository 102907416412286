import React from 'react';
import { Avatar } from '@mui/material';
import useMediaHook from '../../../hooks/useMediaHook';

export default function GameAvatar({ imageId, picture, width, height }) {
  const { mediaLoading, media } = useMediaHook(imageId);
  const avatarUrl = media?.url ?? '/img/avatars/default-image.svg';
  if (picture) {
    return <Avatar variant='rounded' sx={{ width: width ?? 40, height: height ?? 40 }} src={picture} alt='Preview' className='w-[100px] h-[100px]' />;
  }
  return <Avatar variant='rounded' sx={{ width: width ?? 40, height: height ?? 40 }} src={avatarUrl} className={mediaLoading ? 'animate-pulse' : ''} />;
}
