import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  checked: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state, action) => {
      state.user = action.payload;
      state.checked = true;
    },
    updateProfileAction: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    logoutAction: (state) => {
      state.user = null;
      state.checked = true;
    },
    onAuthChecked: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      state.checked = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginAction, logoutAction, onAuthChecked, updateProfileAction } = authSlice.actions;

export default authSlice.reducer;
