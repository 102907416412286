import { isValueEmpty } from '@grethics/commons';
import { Check, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Select, Skeleton, Switch } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useUsers from '../../../hooks/admin/useSysUsers';
import DisposableDialogTitle from '../../components/common/DisposableDialogTitle';
import PlayerAvatar from '../play/PlayerAvatar';

const UserEditModal = React.forwardRef(({ onSave }, ref) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const loading = useSelector((state) => state.ui.loading);
  const { fetchUser, updateUser } = useUsers();

  const { control, handleSubmit, formState, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: { id: '', name: '', email: '', country: '', city: '', provider: '', role: '', anabled: true },
    values: { ...userInfo },
  });

  const onFormSubmit = async (data) => {
    console.log(data);
    const user = await updateUser(data);
    if (user) {
      handleClose();
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUser(userId).then((user) => setUserInfo(user));
    }
  }, [userId, fetchUser]);

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
    setUserId(null);
  };

  useImperativeHandle(ref, () => ({
    show(id) {
      setUserId(id);
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
      <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
        <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <div className='flex flex-row items-center'>
            <PlayerAvatar imageId={userInfo.imageId} />
            {`${userInfo.name}`}
          </div>
        </DisposableDialogTitle>
        <DialogContent dividers sx={{ minWidth: '100%' }}>
          {isValueEmpty(userInfo) && <Skeleton width={500} height={300} animation={'pulse'} />}
          {!isValueEmpty(userInfo) && (
            <div className='w-full p-2 gap-4 min-w-[500px]'>
              <div className='flex flex-row items-center border rounded-sm'>
                <div className='flex flex-1 p-2 font-bold'>Name</div>
                <div className='flex flex-1 p-2 border-l'>{userInfo.name}</div>
              </div>
              <div className='flex flex-row items-center border rounded-sm'>
                <div className='flex flex-1 p-2 font-bold'>Country</div>
                <div className='flex flex-1 p-2 border-l'>{userInfo.country ?? 'None'}</div>
              </div>
              <div className='flex flex-row items-center border rounded-sm'>
                <div className='flex flex-1 p-2 font-bold'>City</div>
                <div className='flex flex-1 p-2 border-l'>{userInfo.city ?? 'None'}</div>
              </div>
              <div className='flex flex-row items-center border rounded-sm'>
                <div className='flex flex-1 p-2 font-bold'>Auth Provider</div>
                <div className='flex flex-1 p-2 border-l'>{userInfo.provider ?? 'Native'}</div>
              </div>
              <div className='flex flex-row items-center border rounded-sm'>
                <div className='flex flex-1 p-2 font-bold'>Registered Since:</div>
                <div className='flex flex-1 p-2 border-l'>{format(new Date(userInfo.createdAt), 'yyy/MM/dd, HH:mm:ss')}</div>
              </div>
              <div className='flex flex-row items-center border rounded-sm'>
                <div className='flex flex-1 p-2 font-bold'>Role</div>
                <div className='flex flex-1 p-2 border-l'>
                  <Controller
                    name='role'
                    control={control}
                    rules={{ required: 'User role is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <Select fullWidth {...field}>
                        {['PLAYER', 'AUTHOR', 'ADMIN'].map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div className='flex flex-row items-center border rounded-sm'>
                <div className='flex flex-1 p-2 font-bold'>Enabled</div>
                <div className='flex flex-1 p-2 border-l'>
                  <Controller
                    name='enabled'
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      console.log(field.value);
                      return (
                        <Switch
                          {...field}
                          //defaultChecked={true}
                          checked={field.value}
                          onChange={(e) => {
                            setValue(field.name, e.target.checked, { shouldDirty: true });
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'primary.extraLight' }}>
          <Button variant='contained' color='error' autoFocus onClick={handleClose} startIcon={<Close />}>
            Cancel
          </Button>
          <LoadingButton
            loading={!!loading}
            loadingposition='start'
            variant='contained'
            color='primary'
            disabled={isValueEmpty(formState.dirtyFields)}
            onClick={handleSubmit(onFormSubmit)}
            startIcon={<Check />}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default UserEditModal;
