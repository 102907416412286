import { AppStore } from '../../model/store/AppStore';
import {
  setActivePlayerAction,
  startGamePlayAction,
  setLastRollAction,
  setRollerActiveAction,
  setGameStatusAction,
  setPlayerScoreAction,
  setGameResultAction,
  setPlayerAnswerAction,
  playerConnectedAction,
  playerDisconnectedAction,
  submitPlayerAnswerAction,
} from '../../model/store/slices/PlaySlice';
import { GameObserver } from './GameObserver';
import { getOtherSide } from '../../utils/GameUtils';
import { GAME_STATUS } from '@grethics/commons';
import { enqueueSnackbar } from 'notistack';
import { playerColor } from '../../config/constants';
import { GuiService } from '../../services/GuiService';
import { maxBy } from 'lodash';
import { ActiveGameController } from './ActiveGameController';

const avatars = { left: null, right: null };

function setAvatars(theAvatars = { left: {}, right: {} }) {
  avatars.left = theAvatars.left;
  avatars.right = theAvatars.right;
}

function opponentConnected(playerId) {
  AppStore.dispatch(playerConnectedAction({ playerId }));
  AppStore.dispatch(setRollerActiveAction(true));
}

function opponentDisconnected(playerId) {
  AppStore.dispatch(setRollerActiveAction(false));
  AppStore.dispatch(playerDisconnectedAction({ playerId }));
}

function startGameUp(side) {
  const {
    spec: {
      game: { id: gameId },
    },
    table: { tableId },
  } = AppStore.getState().play.current;
  AppStore.dispatch(setGameStatusAction({ gameId, tableId, status: GAME_STATUS.STARTING_UP }));
  AppStore.dispatch(setActivePlayerAction({ side }));
  AppStore.dispatch(setRollerActiveAction(false));
}

function startGamePlay() {
  const {
    spec: {
      game: { id: gameId },
    },
    table: { tableId },
  } = AppStore.getState().play.current;
  AppStore.dispatch(startGamePlayAction({ gameId, tableId }));
}

async function completeGame(side) {
  const {
    spec: {
      game: { id: gameId },
    },
    table: { tableId },
  } = AppStore.getState().play.current;
  AppStore.dispatch(setGameStatusAction({ gameId, tableId, status: GAME_STATUS.COMPLETED }));
  setActivePlayer('none');
  setGameResult();
}

function setGameResult() {
  const { players } = AppStore.getState().play?.current?.table;
  let winner = maxBy(Object.values(players), (player) => player.state.score);
  let msg;
  if (players.left.state.score === players.right.state.score) {
    winner = { side: 'none' };
    msg = (
      <div className='flex flex-1 flex-col items-center'>
        <img alt='' src='/img/game-draw.svg' />
        <p className='text-4xl'>It's a Tie! Congratulations to both players!</p>
      </div>
    );
    //enqueueSnackbar(`It's a Tie! Congratulations to both players!`, { variant: 'info', persist: true });
  } else {
    msg = (
      <div className='flex flex-1 flex-col items-center'>
        <img alt='' src='/img/game-win.svg' />
        <p className='text-6xl'>{`${winner.name} won!`}</p>
      </div>
    );
    //enqueueSnackbar(`${winner.name} won!`, { variant: 'info', persist: true, style: { backgroundColor: playerColor.active[winner.side] } });
  }
  AppStore.dispatch(setGameResultAction({ winner }));
  GuiService.showFeedback({ variant: 'success', title: 'Game Completed!', content: msg, duration: 10 });
}

function setActivePlayer(side) {
  AppStore.dispatch(setActivePlayerAction({ side }));
  AppStore.dispatch(setRollerActiveAction(true));
}

function getPlayerCurrentSquare(side) {
  return AppStore.getState().play?.current?.table?.players[side]?.state.curSquare ?? 0;
}

function opponentResponseSubmitted(side) {
  const playerAnswer = AppStore.getState().play?.current?.table?.lastAnswer;
  const pointsLabel = AppStore.getState().play?.current?.spec?.game?.rewarding?.title;
  const { isCorrect, points, score } = ActiveGameController.calculatePlayerReward(side);
  const curSquare = getPlayerCurrentSquare(side);
  const { content, type } = AppStore.getState().play?.current?.spec.board.path.asArray[curSquare];
  AppStore.dispatch(submitPlayerAnswerAction({ side, answerGiven: playerAnswer?.answer, isCorrect, points, score, curSquare, contentId: content?.id }));
  const onFeedbackClose = () => {
    AppStore.dispatch(setPlayerAnswerAction({}));
    GuiService.hideContent();
  };
  if (isCorrect) {
    GuiService.showFeedback({ variant: 'success', title: 'Bravo!!!', content: <p className='p-2'>{`Congratulations! ${points < 0 ? '-' : '+'} ${points} ${pointsLabel}!!!`}</p>, duration: 10 }).then(
      onFeedbackClose
    );
  } else {
    GuiService.showFeedback({ variant: 'error', title: 'Opps...', content: <p className='p-2'>{`That was not correct... ${points < 0 ? '-' : '+'} ${points} ${pointsLabel}`}</p>, duration: 10 }).then(
      onFeedbackClose
    );
  }
}

function setPlayerRoll(side, value) {
  AppStore.dispatch(setLastRollAction({ side, value }));
}

function setPlayerScore(side, score) {
  AppStore.dispatch(setPlayerScoreAction({ side, score }));
  GameObserver.setPlayerScore(side, score);
}

function clearPlayerRolls() {
  AppStore.dispatch(setLastRollAction({ side: 'left', value: null }));
  AppStore.dispatch(setLastRollAction({ side: 'right', value: null }));
}

function opponentDiceRolled(side, result) {
  AppStore.dispatch(setRollerActiveAction(false));
  AppStore.dispatch(setLastRollAction({ side, value: result }));
  const { gameStatus, players } = AppStore.getState().play?.current?.table;

  if (gameStatus === GAME_STATUS.STARTING_UP) {
    const { left, right } = players;
    if (left.state.lastRoll && right.state.lastRoll) {
      const isaTie = left.state.lastRoll === right.state.lastRoll;
      const nextTurn = isaTie ? getOtherSide(side) : left.state.lastRoll > right.state.lastRoll ? 'left' : 'right';
      if (isaTie) {
        GuiService.showFeedback({
          variant: 'warning',
          content: `It's a tie. Try Again...`,
          duration: 0,
          callback: () => {
            clearPlayerRolls();
            AppStore.dispatch(setRollerActiveAction(true));
          },
        });
      } else {
        const player = players[nextTurn];
        GuiService.showFeedback({
          variant: 'primary',
          content: `${player.name} plays first!`,
          duration: 0,
          callback: () => {
            clearPlayerRolls();
            AppStore.dispatch(setRollerActiveAction(true));
          },
        });
      }
    } else {
      setTimeout(() => AppStore.dispatch(setRollerActiveAction(true)), 1000);
    }
  } else if (gameStatus === GAME_STATUS.STARTED) {
    const { steps, deviation } = ActiveGameController.calculatePlayerMove(side, result);
    avatars[side].move({ steps, deviation });
  }
}

export const PassiveGameController = {
  setAvatars,
  opponentConnected,
  opponentDisconnected,
  startGameUp,
  startGamePlay,
  setActivePlayer,
  opponentDiceRolled,
  setPlayerRoll,
  clearPlayerRolls,
  completeGame,
  setPlayerScore,
  opponentResponseSubmitted,
};
