import React, { useEffect, useState } from 'react';
import { Box, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RegistrationForm from '../../components/auth/RegistrationForm';
import useProfile from '../../../hooks/auth/useProfile';
import TabPanel from '../../components/common/TabPanel';
import { PALLETE } from '../../../config/constants';
import UserProfileForm from '../../components/auth/UserProfileForm';
import PlayerProfileForm from '../../components/auth/PlayerProfileForm';

export default function ProfilePage() {
  const [route, setRoute] = useState('user');
  const navigate = useNavigate();

  return (
    <div className='h-full w-full mx-auto'>
      <Tabs
        value={route}
        textColor='primary'
        variant='fullWidth'
        TabIndicatorProps={{
          style: { borderWidth: 2, borderColor: PALLETE.PURPLE.light },
        }}
        aria-label='profile-tabs'>
        <Tab value='user' label='My User Profile' sx={{ textTransform: 'none', fontSize: 20 }} onClick={() => setRoute('user')} />
        <Tab value='player' label='My Player Profile' sx={{ textTransform: 'none', fontSize: 20 }} onClick={() => setRoute('player')} />
      </Tabs>
      <hr />
      <TabPanel index={'user'} value={route}>
        <UserProfileForm />
      </TabPanel>
      <TabPanel index={'player'} value={route}>
        <PlayerProfileForm />
      </TabPanel>
    </div>
  );
}
