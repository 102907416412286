import { USER_ROLE } from '@grethics/commons';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from '../view/ProtectedRoute';
import AdminHomePage from '../view/screens/admin/AdminHomePage';
import DashboardAdminPage from '../view/screens/admin/DashboardAdminPage';
import GamesAdminPage from '../view/screens/admin/GamesAdminPage';
import LeaderboardsAdminPage from '../view/screens/admin/LeaderBrdAdminPage';
import TablesAdminPage from '../view/screens/admin/TablesAdminPage';
import UsersAdminPage from '../view/screens/admin/UsersAdminPage';
import PlayersAdminPage from '../view/screens/admin/PlayersAdminPage';

export const AdminRoutes = [
  {
    path: 'admin',
    element: (
      <ProtectedRoute allowedRoles={[USER_ROLE.ADMIN]}>
        <AdminHomePage />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to='dashboard' />,
      },
      {
        path: 'dashboard',
        element: <DashboardAdminPage />,
      },
      {
        path: 'users',
        element: <UsersAdminPage />,
      },
      {
        path: 'players',
        element: <PlayersAdminPage />,
      },
      {
        path: 'games',
        element: <GamesAdminPage />,
      },
      {
        path: 'tables',
        element: <TablesAdminPage />,
      },
      {
        path: 'leaderboards',
        element: <LeaderboardsAdminPage />,
      },
    ],
  },
];
