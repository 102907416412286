import './App.css';
import HomePage from './view/screens/HomePage';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { checkAuthCookie } from './model/dao/utils/AuthHelper';
import ProtectedRoute from './view/ProtectedRoute';
import NoRouteMatch from './view/components/common/NoRouteMatch';
import { useEffect } from 'react';
import AppStoreProvider from './model/store/AppStoreProvider';
import AppThemeProvider from './view/AppThemeProvider';
import { Container } from '@mui/material';
import TopBar from './view/components/home/TopBar';
import { MakeRoutes } from './routes/MakeRoutes';
import { PlayRoutes } from './routes/PlayRoutes';
import { AuthRoutes } from './routes/AuthRoutes';
import AlertDialog from './view/components/common/AlertDialog';
import { alertDialogRef } from './services/GuiService';
import { AdminRoutes } from './routes/AdminRoutes';
import { UserRoutes } from './routes/UserRoutes';
import { RankingRoutes } from './routes/RankingRoutes';
import Footer from './view/components/common/Footer';

const Layout = (props) => {
  return (
    <div className='min-w-min h-screen flex flex-col justify-start'>
      <TopBar />
      <AlertDialog ref={alertDialogRef} />
      <div id='ng' className='flex flex-1 flex-col w-full items-center max-w-[1200px] mx-auto'>
        <div className='w-full h-full' style={{ paddingTop: 70 }}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '',
    element: <Layout />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        ),
      },
      ...AuthRoutes,
      ...UserRoutes,
      ...MakeRoutes,
      ...AdminRoutes,
      ...PlayRoutes,
      ...RankingRoutes,
      {
        path: '*',
        element: <NoRouteMatch />,
      },
    ],
  },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

function App() {
  useEffect(() => {
    checkAuthCookie();
  }, []);

  return (
    <AppStoreProvider>
      <AppThemeProvider>
        <RouterProvider router={router} />
      </AppThemeProvider>
    </AppStoreProvider>
  );
}

export default App;
