export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

export const DEFAULT_PLAYER_STATE = {
  curSquare: 0,
};
