import { Button, FormHelperText, Paper, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import useGameHook from '../../../../hooks/make/useGameHook';
import BoardEditor from '../../../components/make/BoardEditor';
import { useSelector } from 'react-redux';
import { isPathSectionsValid } from '../../../../utils/GameEditUtils';
import PageHeader from '../../../components/common/PageHeader';

export default function BoardDesignPage() {
  const { slug } = useParams();
  const { game, loading } = useGameHook(slug);

  const { board, path } = useSelector((state) => state.make?.current);
  const pathSectionError = isPathSectionsValid(path?.asArray).message;

  return (
    <div className='flex flex-col flex-1'>
      <PageHeader title={'Board'} />
      <Paper className='flex flex-col w-full mt-2 items-center'>
        <BoardEditor board={board} path={path} />
        <div>
          <FormHelperText error={!!pathSectionError}>{pathSectionError}</FormHelperText>
        </div>
      </Paper>
    </div>
  );
}
