import { Navigate } from 'react-router-dom';
import ProtectedRoute from '../view/ProtectedRoute';
import RootPlayPage from '../view/screens/play/RootPlayPage';
import JoinTableForm from '../view/screens/play/JoinTableForm';
import { PlayErrorElement } from '../view/components/common/RouteErrorElements';
import PlayListBrowser from '../view/screens/play/PlayListBrowser';
import TableBrowser from '../view/screens/play/TableBrowser';
import NewTablePage from '../view/screens/play/NewTablePage';
import GamePage from '../view/screens/play/GamePage';

export const PlayRoutes = [
  {
    path: 'play',
    element: (
      <ProtectedRoute>
        <RootPlayPage />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={'join'} />,
      },
      {
        path: 'join',
        element: <JoinTableForm />,
        errorElement: <PlayErrorElement />,
      },
      {
        path: 'games',
        element: <PlayListBrowser />,
        errorElement: <PlayErrorElement />,
      },
      {
        path: 'tables',
        element: <TableBrowser />,
        errorElement: <PlayErrorElement />,
      },
      {
        path: 'new/:slug',
        element: <NewTablePage />,
      },
    ],
  },
  {
    path: 'play/:slug',
    element: (
      <ProtectedRoute>
        <GamePage />
      </ProtectedRoute>
    ),
  },
];
