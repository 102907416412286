import { isValueEmpty } from '@grethics/commons';
import { Check, Close, EditOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useProfile from '../../../hooks/auth/useProfile';
import AvatarEditor from '../common/AvatarEditor';
import PlayerAvatar from '../play/PlayerAvatar';
import PlayerFormFields from '../common/PlayerFormFields';

export default function PlayerProfileForm() {
  const { loading } = useSelector((state) => state.ui);
  const [onAvatarEdit, setAvatarEdit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { player, upsertPlayerProfile } = useProfile();
  const {
    control,
    formState: { dirtyFields, errors },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: { name: '', country: '', city: '', ageGroup: '', picture: null },
    values: { ...player },
  });
  const country = watch('country');
  useEffect(() => {
    fetch('https://countriesnow.space/api/v0.1/countries/flag/unicode')
      .then((response) => {
        response.json().then((rsp) => {
          setCountries(rsp?.data ?? []);
        });
      })
      .catch((error) => console.log('could not get Countries'));
  }, []);

  useEffect(() => {
    setCities([]);
    if (country) {
      axios
        .post('https://countriesnow.space/api/v0.1/countries/cities', { country })
        .then((response) => {
          setCities(response?.data?.data ?? []);
        })
        .catch((error) => console.log('could not get cities'));
    }
  }, [country]);

  const onSubmit = async (data) => {
    const { name, imageId, picture, city, country, ageGroup } = data;
    const payload = { id: player?.id, name, imageId, city, country, ageGroup };

    if (data.imageId && data.imageId.startsWith('data')) {
      payload.picture = data.imageId;
      payload.imageId = null;
    }
    await upsertPlayerProfile(payload);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-[800px]'>
        {isValueEmpty(player) && <div className='text-center w-full mb-2 text-xl'>You have no Player Profile. Create one now...</div>}
        <PlayerFormFields control={control} setValue={setValue} country={country} errors={errors} />
        <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
          <div>
            {!isValueEmpty(dirtyFields) && (
              <Button
                startIcon={<Close />}
                variant='outlined'
                color='error'
                disabled={loading}
                onClick={() => {
                  reset();
                }}>
                Cancel
              </Button>
            )}
          </div>
          <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(dirtyFields) || loading}>
            {isValueEmpty(player) ? 'Create' : 'Save'}
          </Button>
        </Paper>
      </form>
    </Box>
  );
}
