import { useEffect, useState } from 'react';
import { TableDao } from '../../model/dao/TableDao';
import { useDispatch, useSelector } from 'react-redux';
import { setGameTablesAction } from '../../model/store/slices/TablesSlice';
import { isValueEmpty } from '@grethics/commons';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';

const useFetchTablesHook = () => {
  const [error, setError] = useState(null);
  const [fetched, setFetched] = useState(false);
  const tableMap = useSelector((state) => state.play?.tables?.tableMap);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserTables = async () => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const tables = await TableDao.fetchUserCreatedTables();
        setFetched(true);

        dispatch(setGameTablesAction({ tables }));
      } catch (error) {
        setFetched(true);
        setError(error?.message);
      } finally {
        dispatch(resetLoading());
      }
    };
    if (isValueEmpty(tableMap) && !fetched) {
      fetchUserTables();
    }
  }, [tableMap, fetched, dispatch]);

  return { error };
};

export default useFetchTablesHook;
