"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var __1 = require("..");
var utils_1 = require("../utils");
var validationResult = function (errors) {
    if ((0, lodash_1.isEmpty)(errors))
        return { errors: errors, isValid: true };
    return { errors: errors, isValid: false };
};
var PlayValidator = {
    validateNewPlayer: function (data) {
        var errors = [];
        if ((0, utils_1.isValueEmpty)(data)) {
            errors.push({ field: 'form', code: 'NO_DATA', message: 'No Data Provided' });
            return validationResult(errors);
        }
        var name = data.name, country = data.country, city = data.city, ageGroup = data.ageGroup;
        if ((0, utils_1.isValueEmpty)(name)) {
            errors.push(__assign({ field: 'name' }, __1.ERROR.NAME_REQUIRED));
        }
        if ((0, utils_1.isValueEmpty)(country)) {
            errors.push(__assign({ field: 'country' }, __1.ERROR.COUNTRY_REQUIRED));
        }
        if ((0, utils_1.isValueEmpty)(city)) {
            errors.push(__assign({ field: 'city' }, __1.ERROR.CITY_REQUIRED));
        }
        if ((0, utils_1.isValueEmpty)(ageGroup)) {
            errors.push(__assign({ field: 'ageGroup' }, __1.ERROR.CITY_REQUIRED));
        }
        return validationResult(errors);
    },
};
exports.default = PlayValidator;
