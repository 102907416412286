import { createSlice } from '@reduxjs/toolkit';
import { GameSlice } from './GameSlice';

const initialState = null;

export const GameBoardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    setBoardAction: (state, action) => {
      const { board } = action.payload;
      return { ...board };
    },
    updateBoardAction: (state, action) => {
      const { board } = action.payload;
      return { ...board };
    },
    clearBoardAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('currentGame/clearCurrentGameAction', () => {
      return initialState;
    });
  },
});

export const { setBoardAction, updateBoardAction, clearBoardAction } = GameBoardSlice.actions;
export default GameBoardSlice.reducer;
