"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var validator_1 = require("validator");
var lodash_1 = require("lodash");
var __1 = require("..");
var utils_1 = require("../utils");
var validationResult = function (errors) {
    if ((0, lodash_1.isEmpty)(errors))
        return { errors: errors, isValid: true };
    return { errors: errors, isValid: false };
};
var AuthValidator = {
    validateLoginInput: function (credentials) {
        var errors = [];
        if ((0, utils_1.isValueEmpty)(credentials)) {
            errors.push(__assign({ field: 'form' }, __1.ERROR.NO_CREDENTIALS));
            return validationResult(errors);
        }
        var email = credentials.email, password = credentials.password;
        if ((0, utils_1.isValueEmpty)(email)) {
            errors.push(__assign({ field: 'email' }, __1.ERROR.EMAIL_REQUIRED));
        }
        if ((0, utils_1.isValueEmpty)(password)) {
            errors.push(__assign({ field: 'password' }, __1.ERROR.PWD_REQUIRED));
        }
        return validationResult(errors);
    },
    validateRegisterInput: function (credentials) {
        var errors = [];
        if ((0, utils_1.isValueEmpty)(credentials)) {
            errors.push(__assign({ field: 'form' }, __1.ERROR.NO_CREDENTIALS));
            return validationResult(errors);
        }
        if ((0, utils_1.isValueEmpty)(credentials.email)) {
            errors.push(__assign({ field: 'email' }, __1.ERROR.EMAIL_REQUIRED));
        }
        if (!validator_1.default.isEmail(credentials.email)) {
            errors.push(__assign({ field: 'email' }, __1.ERROR.EMAIL_INVALID));
        }
        if ((0, utils_1.isValueEmpty)(credentials.password)) {
            errors.push(__assign({ field: 'password' }, __1.ERROR.PWD_REQUIRED));
        }
        if (!validator_1.default.isLength(credentials.password, { min: 6 })) {
            errors.push(__assign({ field: 'password' }, __1.ERROR.PWD_SHORT));
        }
        if (credentials.password !== credentials.password2) {
            errors.push(__assign({ field: 'password2' }, __1.ERROR.PWDS_NOT_MATCH));
        }
        if ((0, utils_1.isValueEmpty)(credentials.name)) {
            errors.push(__assign({ field: 'name' }, __1.ERROR.NAME_REQUIRED));
        }
        if (!validator_1.default.isLength(credentials.name, { min: 4 })) {
            errors.push(__assign({ field: 'name' }, __1.ERROR.NAME_SHORT));
        }
        return validationResult(errors);
    },
};
exports.default = AuthValidator;
