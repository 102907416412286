import { isValueEmpty } from '@grethics/commons';
import { Check, Close, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSysPlayers from '../../../hooks/admin/useSysPlayers';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import PlayerFormFields from '../common/PlayerFormFields';
import PlayerAvatar from '../play/PlayerAvatar';

const PlayerEditModal = React.forwardRef(({ onSave }, ref) => {
  const formRef = useRef();
  const [open, setOpen] = useState(false);
  const [playerId, setPlayerId] = useState(null);
  const [playerInfo, setPlayerInfo] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const loading = useSelector((state) => state.ui.loading);
  const { fetchPlayer, updatePlayer, clearPlayerTables } = useSysPlayers();

  useEffect(() => {
    if (playerId) {
      fetchPlayer(playerId).then((record) => {
        setPlayerInfo(record?.player);
        setUserInfo(record?.user);
      });
    } else {
      setPlayerInfo({});
      setUserInfo({});
    }
  }, [playerId, fetchPlayer]);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { id: '', name: '', country: '', city: '', userId: null, imageId: '', picture: null, ageGroup: '' },
    values: { ...playerInfo },
  });

  console.log('isDirty: ', methods.formState.isDirty);

  const onFormSubmit = async (data) => {
    if (data.imageId && data.imageId.startsWith('data')) {
      data.picture = data.imageId;
      data.imageId = null;
    }
    updatePlayer(data)
      .then(() => {
        handleClose();
      })
      .catch((err) => {});
  };

  const handleClearTables = () => {
    clearPlayerTables(playerId).then((result) => {
      setPlayerInfo({ ...playerInfo, tables: 0 });
    });
  };

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
    setPlayerId(null);
  };

  useImperativeHandle(ref, () => ({
    show(id) {
      setPlayerId(id);
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit)} autoComplete='off'>
          <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
            <div className='flex flex-row items-center just'>
              <PlayerAvatar imageId={playerInfo?.imageId} />
              {`${playerInfo?.name}`}
              <div className='flex flex-1 items-center justify-end pr-10'>{loading && <CircularProgress sx={{ alignSelf: 'flex-end' }} />}</div>
            </div>
          </DisposableDialogTitle>
          <DialogContent dividers sx={{ minWidth: '100%' }}>
            {isValueEmpty(playerInfo) && <Skeleton width={500} height={300} animation={'pulse'} />}
            {!isValueEmpty(playerInfo) && (
              <div className='w-full p-2 gap-4 min-w-[500px]'>
                {!isValueEmpty(userInfo) && (
                  <>
                    <Typography variant='h5' sx={{ mb: 3 }}>
                      Account Info
                    </Typography>
                    <div className='flex flex-row items-center border rounded-sm mb-3'>
                      <div className='flex flex-1 p-2 font-bold'>User Name</div>
                      <div className='flex flex-1 p-2 border-l'>{userInfo.name}</div>
                    </div>
                    <div className='flex flex-row items-center border rounded-sm my-2'>
                      <div className='flex flex-1 p-2 font-bold'>User Email</div>
                      <div className='flex flex-1 p-2 border-l'>{userInfo.email}</div>
                    </div>
                  </>
                )}
                <Typography variant='h5' sx={{ my: 3 }}>
                  Player Info
                </Typography>
                <PlayerFormFields control={methods.control} setValue={methods.setValue} country={methods.getValues('country')} errors={methods.formState.errors} />
                <div className='flex flex-row items-center border rounded-sm mt-2'>
                  <div className='flex flex-1 p-2 font-bold'>Tables involved</div>
                  <div className='flex flex-1 p-2 border-l'>{playerInfo.tables ?? 'None'}</div>
                  {playerInfo.tables > 0 && (
                    <IconButton onClick={handleClearTables}>
                      <Delete color='error' />
                    </IconButton>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'primary.extraLight' }}>
            <Button variant='contained' color='error' autoFocus onClick={handleClose} startIcon={<Close />}>
              Cancel
            </Button>
            <LoadingButton
              loading={!!loading}
              loadingposition='start'
              variant='contained'
              color='primary'
              type='submit'
              disabled={isValueEmpty(methods.formState.dirtyFields)}
              //onClick={methods.handleSubmit(onFormSubmit)}
              startIcon={<Check />}>
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
});

export default PlayerEditModal;
