import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGamesAction } from '../../model/store/slices/MakeListSlice';
import { GameDao } from '../../model/dao/GameDao';
import { isEmpty } from 'lodash';
import { isValueEmpty } from '@grethics/commons';

const useAuthoredGames = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const gameList = useSelector((state) => state.make.games.list);

  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setLoading(true);
        setError(null);
        const games = await GameDao.fetchAuthoredGames();
        dispatch(setGamesAction({ games }));
      } catch (error) {
        setError(error?.message);
      } finally {
        setFetched(true);
        setLoading(false);
      }
    };
    if (isValueEmpty(gameList) && !fetched) {
      fetchGames();
    }
  }, [dispatch, gameList, fetched]);

  return { loading, error, gameList };
};

export default useAuthoredGames;
