import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerOpen: true,
  loading: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
  },
});

export const { toggleDrawer, setLoading, resetLoading } = uiSlice.actions;

export default uiSlice.reducer;
