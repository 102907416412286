import { Button, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSysTables from '../../../hooks/admin/useSysTables';
import TableDataGrid from '../../components/admin/TableDataGrid';
import PageHeader from '../../components/common/PageHeader';
import { useSelector } from 'react-redux';

export default function TablesAdminPage() {
  const { tables } = useSysTables();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [showGrid, setShowGrid] = React.useState(true);

  React.useEffect(() => {
    if (drawerOpen) {
      setShowGrid(false);
      setTimeout(() => {
        setShowGrid(true);
      }, 50);
    }
  }, [drawerOpen]);

  return (
    <form onSubmit={() => {}} className='flex flex-col flex-1' autoComplete='off'>
      <PageHeader title={'Tables'} />
      <Paper className='flex flex-col min-h-[500px] w-full items-start p-2'>
        <div className='flex flex-row w-full gap-4 my-4'>{showGrid && <TableDataGrid tables={tables ?? []} />}</div>
      </Paper>
    </form>
  );
}
