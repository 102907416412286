import { PlayErrorElement } from '../view/components/common/RouteErrorElements';
import GameRankingsPage from '../view/screens/rankings/GameRankingsPage';
import RankingPage from '../view/screens/rankings/RankingPage';

export const RankingRoutes = [
  {
    path: 'rankings/:slug',
    element: <GameRankingsPage />,
    errorElement: <PlayErrorElement />,
    children: [
      {
        path: ':id',
        element: <RankingPage />,
      },
    ],
  },
];
