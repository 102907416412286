import { useState } from 'react';
import { AuthDao } from '../../model/dao/AuthDao';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../model/store/slices/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { parseToken, setAuthCookie } from '../../model/dao/utils/AuthHelper';

export default function useSignInHook() {
  const [signingIn, setSigningIn] = useState(false);
  const [signInError, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signIn = async ({ email, password }, redirectTo) => {
    try {
      setSigningIn(true);
      setError(null);
      const { token } = await AuthDao.loginUser(('' + email).trim(), ('' + password).trim());
      const user = parseToken(token);
      setAuthCookie(token);
      dispatch(loginAction(user));
      enqueueSnackbar(`Hi ${user.name ?? 'User'}`, { variant: 'success' });
      navigate(redirectTo);
    } catch (error) {
      setError(error);
      enqueueSnackbar(error?.message ?? 'Could not log you in right now.', { variant: 'error', autoHideDuration: 1000 });
    } finally {
      setSigningIn(false);
    }
  };

  const socialSignIn = async (provider, socialToken, redirectTo) => {
    try {
      setSigningIn(true);
      setError(null);
      const { token, isNewUser } = await AuthDao.loginSocialUser(provider, socialToken);
      const user = parseToken(token);
      setAuthCookie(token);
      dispatch(loginAction(user));
      if (isNewUser) {
        enqueueSnackbar(`Dear ${user.name}, welcome to Green Ethics!!!`, { variant: 'success' });
      } else {
        enqueueSnackbar(`Hi ${user.name ?? 'User'}`, { variant: 'success' });
      }
      navigate(redirectTo);
    } catch (error) {
      setError(error);
      //enqueueSnackbar(JSON.stringify(error), { variant: 'error', autoHideDuration: 1000 });
      enqueueSnackbar(error?.message ?? 'Could not log you in right now.', { variant: 'error', autoHideDuration: 1000 });
    } finally {
      setSigningIn(false);
    }
  };

  return { signingIn, signInError, signIn, socialSignIn };
}
