import { CustomResponseError } from '../../utils/Utils';
import { ApiClient } from './utils/ApiClient';
export const MediaDao = {
  fetchMediaById: async (mediaId) => {
    try {
      const { data } = await ApiClient.get(`/common/media/${mediaId}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },

  fetchMediaByGameId: async (gameId) => {
    try {
      const { data } = await ApiClient.get(`/common/media/game/${gameId}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  deleteMedia: async (mediaId) => {
    try {
      const { data } = await ApiClient.delete(`/common/media/${mediaId}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  importMedia: async (url, title, mimeType, gameId) => {
    const body = { url, title, gameId, mimeType };
    try {
      const { data } = await ApiClient.post(`/common/media/import`, body);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  uploadMedia: async (files, gameId) => {
    let payload = new FormData();

    if (gameId) {
      payload.append('gameId', gameId);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      console.log(file.name);
      payload.append('file', file);
    }

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log({ percentCompleted });
      },
    };
    try {
      const { data } = await ApiClient.post(`/common/media`, payload, config);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
};
