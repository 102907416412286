import { GAME_STATUS } from '@grethics/commons';
import { Edit } from '@mui/icons-material';
import { Badge, IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, gridClasses } from '@mui/x-data-grid';
import { format } from 'date-fns';
import * as React from 'react';
import GameStatusIcon from './GameStatusIcon';
import NoDataRows from './NoDataRows';
import { useSelector } from 'react-redux';
import TableEditorModal from '../browse/TableEditorModal';
import { createOpponentRecord } from '../../../utils/GameUtils';

function TableGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter sx={{ width: '100%' }} />
    </GridToolbarContainer>
  );
}

export default function TableDataGrid({ tables }) {
  const tblEditorRef = React.createRef();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [showGrid, setShowGrid] = React.useState(true);

  React.useEffect(() => {
    if (drawerOpen) {
      setShowGrid(false);
      setTimeout(() => {
        setShowGrid(true);
      }, 50);
    }
  }, [drawerOpen]);

  const handleClick = (event, cellValues) => {
    const table = cellValues.row;
    const { id, archived, slug, state, moderator, gameId, availableAt } = table;
    const opponents = { left: createOpponentRecord(table?.gamePlayers[0]), right: createOpponentRecord(table?.gamePlayers[1]) };
    const data = { id, slug, state, archived, moderator, gameId, opponents, availableAt };
    if (tblEditorRef.current) {
      tblEditorRef.current.show(data);
    }
  };

  const onFilterChange = (value) => {
    console.log(value);
  };

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const columns = [
    {
      field: 'config',
      headerName: 'Table',
      minWidth: 250,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        const left = params.row.gamePlayers[0]?.player?.name;
        const right = params.row.gamePlayers[1]?.player?.name;
        const moderator = params.row.moderator?.name;
        return `${left} vs. ${right}`;
      },
      renderCell: (params) => {
        const left = params.row.gamePlayers[0]?.player?.name;
        const right = params.row.gamePlayers[1]?.player?.name;
        const moderator = params.row.moderator?.name;
        const archived = params.row.archived;
        return (
          <div>
            {!archived && (
              <div>
                {left} vs. {right}
              </div>
            )}
            {archived && (
              <Badge color='error' variant='dot' anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <Tooltip title='Table has been archived'>
                  <div>
                    {left} vs. {right}
                  </div>
                </Tooltip>
              </Badge>
            )}
            {moderator && <div>Mod: {moderator}</div>}
          </div>
        );
      },
    },
    {
      field: 'game',
      headerName: 'Game',
      minWidth: 160,
      editable: false,
      valueGetter: (params) => {
        return params.row.game.title;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      minWidth: 50,
      editable: false,
      valueGetter: (params) => {
        return new Date(params.value);
      },
      renderCell: (cellValues) => {
        const created = new Date(cellValues?.value);
        return format(created, 'yyyy/MM/dd');
      },
    },
    {
      field: 'creator',
      headerName: 'Creator',
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        return params.row.creator.name;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      editable: false,
      valueGetter: (params) => {
        const { gameStatus, winner } = params.row.state;
        const tie = gameStatus === GAME_STATUS.COMPLETED && !winner;
        if (gameStatus !== GAME_STATUS.COMPLETED) {
          return gameStatus ?? GAME_STATUS.NOT_STARTED;
        }
        return gameStatus + (tie ? ' tie' : ` ${winner} won`);
      },
      renderCell: (cellValues) => {
        const state = cellValues.row.state;
        const { gameStatus } = state;
        const tie = !state.winner && gameStatus === GAME_STATUS.COMPLETED;
        return (
          <div className='flex flex-col w-full items-center'>
            <GameStatusIcon status={state.gameStatus} width={24} />
            {state.winner && <Typography variant='caption'>{state.winner} won</Typography>}
            {tie && <Typography variant='caption'>It was a tie</Typography>}
          </div>
        );
      },
    },
    {
      field: 'Edit',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              handleClick(event, cellValues);
            }}>
            <Edit />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box sx={{ minHeight: 600, minWidth: 0, flex: 1 }}>
      <TableEditorModal ref={tblEditorRef} adminMode />
      {showGrid && (
        <DataGrid
          sx={{
            [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
              outline: 'transparent',
            },
            [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
          }}
          rows={tables}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          slots={{ toolbar: TableGridToolbar, noRowsOverlay: () => <NoDataRows msg={'No tables yet'} /> }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onFilterModelChange={onFilterChange}
          autoPageSize
          density='comfortable'
          disableRowSelectionOnClick
          disableColumnSelector
          onCellClick={handleCellClick}
        />
      )}
    </Box>
  );
}
