import { Check, Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import { SketchPicker } from 'react-color';

const TopicEditor = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [topic, setTopic] = useState({});
  const [showPicker, setShowPicker] = useState(false);
  const [callback, setCallBack] = useState();
  const { control, handleSubmit, reset, setValue } = useForm({ mode: 'onBlur', defaultValues: { name: '', color: '#101010' }, values: { ...topic } });

  const onFormSubmit = async (data) => {
    console.log({ data });
    if (callback) {
      callback(data);
    }
    handleClose();
  };

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setTopic({});
    reset({ name: '', color: '#101010' });
    setCallBack(null);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(value, clbk) {
      setTopic(value);
      setOpen(true);
      setShowPicker(false);
      setCallBack(() => clbk);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off' className='flex flex-col flex-1'>
        <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
          <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
            {topic?.name ?? 'New Topic'}
          </DisposableDialogTitle>
          <DialogContent dividers sx={{ minWidth: '100%' }}>
            <div className='flex flex-col flex-1'>
              <div className='flex flex-col min-h-[300px] flex-1 w-full mt-2 p-2'>
                <Stack spacing={2}>
                  <Controller
                    name='name'
                    control={control}
                    rules={{
                      required: 'Topic name is required',
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl error={!!error}>
                          <TextField label='Topic Name' {...field} />
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name='color'
                    control={control}
                    rules={{ required: 'Topic color is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error}>
                        <div className='border border-slate-300 rounded p-3'>
                          <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>Board Topics</InputLabel>
                          <Button
                            fullWidth
                            style={{ backgroundColor: field.value, minHeight: 30 }}
                            onClick={(e) => {
                              setShowPicker(!showPicker);
                            }}></Button>
                          {showPicker && (
                            <SketchPicker
                              color={{ hex: field.value }}
                              //presetColors={['blue', 'red']}
                              onChangeComplete={(color) => {
                                console.log({ color });
                                setValue(field.name, color.hex, { shouldDirty: true, shouldValidate: true });
                              }}
                            />
                          )}
                          <FormHelperText>{error?.message}</FormHelperText>
                        </div>
                      </FormControl>
                    )}
                  />
                </Stack>
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'lightsteelblue' }}>
            <Button variant='contained' color='error' autoFocus onClick={handleClose} startIcon={<Close />}>
              Cancel
            </Button>
            <Button loadingposition='start' variant='contained' color='primary' onClick={handleSubmit(onFormSubmit)} startIcon={<Check />}>
              {topic?.id ? 'Save' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
});

export default TopicEditor;
