import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { TableDao } from '../../model/dao/TableDao';
import { useDispatch } from 'react-redux';
import { addGameTableAction } from '../../model/store/slices/TablesSlice';

export function useCreateTableHook() {
  const [creating, setCreating] = useState(false);
  const [createError, setError] = useState(null);
  const [instance, setInstance] = useState(null);
  const dispatch = useDispatch();

  const createNewTable = async (gameId, opponents, moderator, availableAt) => {
    try {
      setCreating(true);
      setError(null);
      const payload = { gameId: parseInt(gameId), opponents, availableAt };
      if (moderator) {
        payload.moderatorId = parseInt(moderator.id);
      }
      const table = await TableDao.createNewTable(payload);
      setInstance(table);
      dispatch(addGameTableAction({ table, gameId }));
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(error?.message ?? 'Error!!!', { variant: 'error' });
    } finally {
      setCreating(false);
    }
  };

  return { instance, creating, createError, createNewTable };
}
