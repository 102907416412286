"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValueEmpty = void 0;
var lodash_1 = require("lodash");
var isValueEmpty = function (value) {
    if ((0, lodash_1.isNil)(value)) {
        return true;
    }
    if ((0, lodash_1.isNumber)(value)) {
        return false;
    }
    return (0, lodash_1.isEmpty)(value);
};
exports.isValueEmpty = isValueEmpty;
