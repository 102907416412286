import { Menu, MenuOpenOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PALLETE } from '../../../config/constants';
import { toggleDrawer } from '../../../model/store/slices/UiSlice';

export default function PageHeader({ title }) {
  const dispatch = useDispatch();
  const { drawerOpen, loading } = useSelector((state) => state.ui);

  const toggleMenu = () => {
    dispatch(toggleDrawer());
  };

  return (
    <div className='flex flex-row items-center w-full rounded-sm p-2 text-lg' style={{ backgroundColor: PALLETE.EMERLAND.extraLight }}>
      <IconButton disableRipple aria-label='open drawer' onClick={toggleMenu} sx={{ bgcolor: 'white', borderRadius: 1, border: 1, borderColor: 'lightgray', mr: 1, p: 0 }}>
        {!drawerOpen ? <Menu /> : <MenuOpenOutlined />}
      </IconButton>
      <Typography variant='h5' sx={{ flex: 1 }}>
        {title}
      </Typography>
      {loading && <CircularProgress />}
    </div>
  );
}
