import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//const vMinMargin = 60 + 122 + 100; //header + footer + playerPads on top
const vMinMargin = 100; //header + footer + playerPads on top

export default function useGameMetrics(outer, canvas, gridSize, dontScroll = false) {
  const [canvasSize, setCanvaSize] = useState(500);
  const { drawerOpen } = useSelector((state) => state.ui);
  useEffect(() => {
    function setSquareSize() {
      const gamePage = document.getElementById('gamePage');
      if (gamePage) {
        gamePage.style.height = `${window.innerHeight - 70}px`;
        if (outer) outer.style.height = `${window.innerHeight - 70}px`;
      }
      //console.log({ BoardSize: canvasSize, windowH: window.innerHeight, gamePageH: gamePage.offsetHeight });
      if (outer && canvas) {
        const outerH = outer.offsetHeight;
        const outerW = outer.offsetWidth;
        let minDimension = outerW;
        if (dontScroll) {
          minDimension = Math.min(outerH, outerW);
          //console.log(`minDimension is  ${outerH > outerW ? 'Width' : 'Height'}: ${minDimension}`);
          let consumedH = minDimension + vMinMargin;
          const remainingH = outerH - consumedH;
          if (remainingH <= 0) {
            minDimension = minDimension + remainingH;
          }

          /* if (outerW - outerH >= 500) {
            minDimension = minDimension + 100;
          } */
          if (minDimension < 400) {
            minDimension = 400;
          }
        }
        setCanvaSize(minDimension);
        canvas.style.width = `${minDimension}px`;
        canvas.style.height = `${minDimension}px`;
      }
    }
    setSquareSize();
    window.addEventListener('resize', setSquareSize);
    return () => {
      window.removeEventListener('resize', setSquareSize);
    };
  }, [outer, canvas, drawerOpen, dontScroll]);

  const BoardSize = canvasSize;
  const CellSize = Math.floor((BoardSize - BoardSize * 0.001) / gridSize ?? 10);
  const CellPadding = Math.floor(CellSize * 0.015);
  const SquareSize = CellSize - CellPadding * 2;
  const AvatarSize = CellSize - 15;
  const hMargin = (outer?.offsetWidth ?? 500) - BoardSize;

  /* console.log({
    BoardSize,
    CellSize,
    CellPadding,
    SquareSize,
    AvatarSize,
    hMargin,
  }); */
  return { BoardSize, CellSize, CellPadding, SquareSize, AvatarSize, hMargin };
}
