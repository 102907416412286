import React, { useEffect } from "react";
import SignInForm from "../../components/auth/SignInForm";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "../../../config/config";

export default function SignInPage() {
  const location = useLocation();
  const origin = location?.state?.from?.pathname ?? "/";
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  useEffect(() => {
    if (user) {
      //console.log('signinPage navigating to origin...');
      navigate(origin);
    }
  }, [user, navigate, origin]);
  //console.log({ GOOGLE_CLIENT_ID });
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className='h-full max-w-md mx-auto'>
        <SignInForm redirectTo={origin} />
        <Grid container>
          <Grid item xs>
            <Link href='#'>Forgot password?</Link>
          </Grid>
          <Grid item>
            <Link href='./signup'>{"Don't have an account? Sign Up"}</Link>
          </Grid>
        </Grid>
      </div>
    </GoogleOAuthProvider>
  );
}
