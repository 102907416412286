import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function CountrySelector({ value, onValueChange }) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch('https://countriesnow.space/api/v0.1/countries/flag/unicode')
      .then((response) => {
        response.json().then((rsp) => {
          setCountries(rsp?.data ?? []);
        });
      })
      .catch((error) => console.log('could not get Countries'));
  }, []);
  return (
    <FormControl fullWidth sx={{ my: 2 }}>
      <Autocomplete
        id='country-selector'
        fullWidth
        multiple
        options={countries}
        value={
          Array.isArray(value)
            ? value.map((country) => {
                return { name: country };
              })
            : value
        }
        autoHighlight
        disableClearable
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => {
          return option.name === value.name;
          //return false; // because selected options are filtered out
        }}
        getOptionLabel={(country) => country?.name}
        renderOption={(props, country) => (
          <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {country.unicodeFlag} {country.name} ({country.iso2})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Countries'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(event, newCountry) => {
          console.log(newCountry);
          onValueChange(newCountry.map((c) => c.name));
        }}
      />
    </FormControl>
  );
}
