import { createSlice } from '@reduxjs/toolkit';
import { getPlayStatus } from '../../../utils/GameUtils';
import { GAME_STATUS, PLAY_STATUS } from '@grethics/commons';

const initialState = {
  tableId: null,
  slug: null,
  players: { left: null, right: null },
  userSide: null,
  userRole: null,
  gameStatus: GAME_STATUS.NOT_STARTED,
  visitedSquares: [],
  playersOnBoard: false,
  playStatus: PLAY_STATUS.WAITING,
  activeSide: 'none',
  soundBgOn: false,
  soundFxOn: false,
  rollerActive: false,
  winner: undefined,
};

export const playSlice = createSlice({
  name: 'play',
  initialState,
  reducers: {
    setUserSideAction: (state, action) => {
      const { userSide } = action.payload;
      state.userSide = userSide;
    },
    setUserRoleAction: (state, action) => {
      const { userRole } = action.payload;
      state.userRole = userRole;
    },
    playerConnectedAction: (state, action) => {
      const { playerId } = action.payload;
      const player = Object.values(state.players).find((p) => p?.id === playerId);
      if (player) {
        player.connected = true;
        state.playersOnBoard = state.players.left?.connected && state.players.right?.connected;
        state.playStatus = getPlayStatus(state);
      }
    },
    playerDisconnectedAction: (state, action) => {
      const { playerId } = action.payload;
      const player = Object.values(state.players).find((p) => p?.id === playerId);
      if (player) {
        player.connected = false;
        state.playersOnBoard = false;
      }
      state.playStatus = getPlayStatus(state);
    },
    setActivePlayerAction: (state, action) => {
      const { side } = action.payload;
      state.activeSide = side;
      if (side === 'none') {
        state.players.left.played = false;
        state.players.right.played = false;
      } else {
        state.players.left.played = false;
        state.players.right.played = false;
      }
    },
    startGameUpAction: (state, action) => {
      let side = action.payload.side;
      if (!side || side === 'none') {
        side = state.userSide;
      }
      state.activeSide = side;
      state.gameStatus = GAME_STATUS.STARTING_UP;
      state.playStatus = getPlayStatus(state);
    },
    setLastRollAction: (state, action) => {
      const { side, value } = action.payload;
      state.players[side].state.lastRoll = value;
      state.players[side].played = true;
    },
    setPlayerAnswerAction: (state, action) => {
      const { side, answer, isCorrect } = action.payload;
      if (['left', 'right'].includes(side)) {
        state.lastAnswer = { answer, isCorrect, side };
      } else {
        state.lastAnswer = {};
      }
    },
    submitPlayerAnswerAction: (state, action) => {
      const { side, answerGiven, isCorrect, points, score, curSquare, contentId } = action.payload;
      const visit = {
        when: new Date().toUTCString(),
        squareNum: curSquare,
        answerGiven,
        isCorrect,
        pointsGot: points,
        newScore: score,
        contentId,
      };
      const squareVisits = state.players[side]?.state?.squareVisits ?? [];
      squareVisits.push(visit);
      visit.side = side;
      state.visitedSquares.push(visit);
      state.players[side].state.squareVisits = squareVisits;
    },
    startGamePlayAction: (state, action) => {
      state.gameStatus = GAME_STATUS.STARTED;
      state.players.left.state.lastRoll = null;
      state.players.right.state.lastRoll = null;
      state.playStatus = getPlayStatus(state);
    },
    setCurrentSquareAction: (state, action) => {
      const { side, idx } = action.payload;
      state.players[side].state.curSquare = idx;
    },
    setPlayerScoreAction: (state, action) => {
      const { side, score } = action.payload;
      state.players[side].state.score = score;
    },
    setGameResultAction: (state, action) => {
      const { winner } = action.payload;
      state.winner = winner.side;
    },
    setRollerActiveAction: (state, action) => {
      state.rollerActive = action.payload;
    },
    setGameStatusAction: (state, action) => {
      const { status } = action.payload;
      state.gameStatus = status;
      state.playStatus = getPlayStatus(state);
    },

    setSoundBgAction: (state, action) => {
      const { soundBgOn } = action.payload;
      state.soundBgOn = soundBgOn;
    },
    setSoundFxAction: (state, action) => {
      const { soundFxOn } = action.payload;
      state.soundFxOn = soundFxOn;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('spec/setGameAction', (state, action) => {
        const { tableId, tableSlug, players, gameState, userSide, userRole } = action.payload;
        state = { ...state, ...gameState };
        state.tableId = tableId;
        state.slug = tableSlug;
        state.userRole = userRole;
        state.userSide = userSide;
        state.players = players;
        const leftVisits = (players?.left?.state?.squareVisits ?? []).map((v) => {
          return { ...v, side: 'left' };
        });
        const rightVisits = (players?.right?.state?.squareVisits ?? []).map((v) => {
          return { ...v, side: 'right' };
        });
        state.visitedSquares = [].concat(...leftVisits, ...rightVisits);
        state.playersOnBoard = players.left.connected && players.right.connected;
        state.playStatus = getPlayStatus(state);

        return state;
      })
      .addCase('spec/leaveGameAction', () => initialState);
  },
});

export const {
  setCurrentSquareAction,
  startGamePlayAction,
  setLastRollAction,
  startGameUpAction,
  setUserRoleAction,
  setUserSideAction,
  playerConnectedAction,
  playerDisconnectedAction,
  setActivePlayerAction,
  setRollerActiveAction,
  setGameStatusAction,
  setPlayerScoreAction,
  setGameResultAction,
  setPlayerAnswerAction,
  submitPlayerAnswerAction,
  setSoundBgAction,
  setSoundFxAction,
} = playSlice.actions;
export default playSlice.reducer;
