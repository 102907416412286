import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MediaDao } from '../../model/dao/MediaDao';
import { addMediaObjects, clearMediaLib, removeMediaObject, setMediaLib } from '../../model/store/slices/make/MediaLibrarySlice';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import { isValueEmpty } from '@grethics/commons';

const useMediaLibraryHook = (gameId) => {
  const [error, setError] = useState(null);
  const media = useSelector((state) => state.make.current?.mediaLib);
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchMedia = async () => {
      console.log('Fetching media...');
      try {
        dispatch(setLoading(true));
        const medias = await MediaDao.fetchMediaByGameId(gameId);
        dispatch(setMediaLib({ media: medias }));
      } catch (err) {
        setError(err?.message ?? 'Could not get Media Library');
      } finally {
        dispatch(resetLoading());
        setFetched(true);
      }
    };
    if (gameId && !media && !fetched) {
      fetchMedia();
    }
  }, [gameId, media, fetched, dispatch]);

  const uploadMedia = async (files, gameId) => {
    try {
      dispatch(setLoading(true));
      setError(null);
      const newMedia = await MediaDao.uploadMedia(files, gameId);
      //enqueueSnackbar('Media uploaded...', { variant: 'success', autoHideDuration: 1000 });
      dispatch(addMediaObjects({ media: newMedia }));
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong', { variant: 'error', autoHideDuration: 5000 });
      setError(error?.message);
    } finally {
      dispatch(resetLoading());
    }
  };

  const importMedia = async (url, title, mimeType, gameId) => {
    try {
      dispatch(setLoading(true));
      setError(null);
      const newMedia = await MediaDao.importMedia(url, title, mimeType, gameId);
      enqueueSnackbar('Media imported...', { variant: 'success', autoHideDuration: 1000 });
      dispatch(addMediaObjects({ media: newMedia }));
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong', { variant: 'error', autoHideDuration: 5000 });
      setError(error?.message);
    } finally {
      dispatch(resetLoading());
    }
  };

  const deleteMedia = async (id) => {
    try {
      dispatch(setLoading(true));
      setError(null);
      await MediaDao.deleteMedia(id);
      dispatch(removeMediaObject({ mediaId: id }));
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong', { variant: 'error', autoHideDuration: 5000 });
      setError(error?.message);
    } finally {
      dispatch(resetLoading());
    }
  };

  const clearLibrary = () => {
    dispatch(clearMediaLib({}));
  };
  return { media, error, uploadMedia, importMedia, deleteMedia, clearLibrary };
};

export default useMediaLibraryHook;
