import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ContentForm from '../../../components/make/ContentForm';
import { ContentType, isValueEmpty, QuestionType } from '@grethics/commons';
import { Check, Close, Delete } from '@mui/icons-material';
import useGameHook from '../../../../hooks/make/useGameHook';
import { GuiService } from '../../../../services/GuiService';
import AnswerForm from '../../../components/make/AnswerForm';
import { OptionCodes } from '../../../../utils/Utils';
import { FormPrompt } from '../../../components/make/FormPrompt';
import PageHeader from '../../../components/common/PageHeader';

const dummyContent = { type: ContentType.QUIZ, sysTitle: '', title: '', body: { text: '', mediaId: null, qType: '', aType: '', correctAnswer: null, options: [] } };

export default function QuizDesignPage() {
  const { slug, contentId } = useParams();
  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState('question');
  const { game, loading, contents } = useSelector((state) => state.make.current);
  const { createGameContent, updateGameContent, deleteGameContent } = useGameHook(slug);
  const content = contents[contentId] ?? {};
  let origQType = content?.body?.qType;

  const methods = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
    defaultValues: { ...dummyContent },
    values: { ...content },
  });

  useEffect(() => {
    return () => {
      methods.reset({ ...dummyContent }, { keepDefaultValues: false, keepDirty: false, keepDirtyValues: false });
    };
  }, [contentId, methods]);

  const onFormSubmit = async (data) => {
    data.type = ContentType.QUIZ;
    if (isValueEmpty(data.body.text) && isValueEmpty(data.body.mediaId)) {
      methods.setError('form', { type: 'custom', message: 'A Fact should at least have text or media' });
      return;
    }
    let correctAnswer = data.body.correctAnswer;
    /* if ([QuestionType.MCMS, QuestionType.MCSS].includes(data?.body?.qType)) {
      data?.body?.options?.forEach((option, idx) => {
        option.code = OptionCodes[idx];
      });
      correctAnswer = [];
      data.body.options.forEach((option, idx) => {
        if (option.isCorrect) {
          correctAnswer.push(option.code);
        }
      });
      console.log(correctAnswer);
    } */
    data.body.correctAnswer = correctAnswer;
    if (content?.id) {
      await updateGameContent(slug, contentId, data);
    } else {
      createGameContent(slug, data)
        .then((content) => navigate(`./../${content.id}`))
        .catch((err) => {
          console.log('failed...');
        });
    }
  };

  const deleteContent = async () => {
    GuiService.showAlert({
      title: 'Confirmation needed',
      message: (
        <p className='text-center'>
          Are you sure you want to delete this Item?
          <br /> (action cannot be undone)
        </p>
      ),
      actions: [
        {
          title: 'Cancel',
          color: 'primary',
        },
        {
          title: 'Delete',
          color: 'error',
          callback: async () => {
            const deleted = await deleteGameContent(slug, contentId);
            if (deleted) {
              navigate(`/make/games/${slug}/design/board`);
            }
          },
        },
      ],
    });
  };
  let qType = methods.watch('body.qType');
  if (!qType) {
    qType = origQType;
  }
  const options = methods.watch('body.options');
  //console.log(options);
  return (
    <div className='flex flex-col flex-1'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit)} autoComplete='off' className='flex flex-col flex-1'>
          <PageHeader title={isValueEmpty(content?.title) ? 'New Quiz' : content.title} />
          <FormPrompt hasUnsavedChanges={methods.formState.isDirty && !methods.formState.isSubmitSuccessful} />
          <Paper className='flex flex-col flex-1 w-full items-start mt-2'>
            <div className='flex flex-col flex-grow min-h-[500px] w-full p-2 gap-4'>
              {/* <div>{JSON.stringify(methods.formState.dirtyFields)}</div> */}
              {/* Title */}
              <Controller
                name='title'
                control={methods.control}
                rules={{
                  required: 'A Quiz title is required ',
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl fullWidth error={!!error}>
                      <TextField label='Title' {...field} />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
              {/* System Title */}
              <Controller
                name='sysTitle'
                control={methods.control}
                rules={{
                  required: 'System Title is required',
                  validate: async (title, id) => {
                    if (id) {
                      return true;
                    }
                    //const slug = slugify(title, { trim: true, lower: true });
                    //const free = await checkGameSlug(slug);
                    //return free || `${title} is reserved...`;
                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl fullWidth error={!!error}>
                      <TextField label='System Title' {...field} />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
              {/* Prompt */}
              <Tabs orientation='horizontal' variant='fullWidth' value={openTab} className='border-b-2'>
                <Tab sx={{ textTransform: 'none' }} onClick={() => setOpenTab('question')} label={<span className='text-center w-full p-2 text-xl'>Question</span>} value='question' />
                <Tab sx={{ textTransform: 'none' }} onClick={() => setOpenTab('answer')} label={<span className='text-center w-full p-2 text-xl'>Answer</span>} value='answer' />
              </Tabs>
              {openTab === 'question' && <ContentForm gameId={game?.id} />}
              {openTab === 'answer' && <AnswerForm qType={qType} />}
              {/* <Box sx={{ maxHeight: openTab === 'question' ? 0 : undefined }}>
                <ContentForm gameId={game?.id} />
              </Box>
              <Box sx={{ visibility: openTab === 'answer' ? 'visible' : 'hidden' }}>
                <AnswerForm qType={qType} />
              </Box> */}
              {methods.formState.errors.form && <FormHelperText error>{methods.formState.errors.form.message}</FormHelperText>}
            </div>
          </Paper>
          <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
            {content?.id && (
              <Button startIcon={<Delete />} variant='outlined' color='error' onClick={deleteContent}>
                Delete
              </Button>
            )}
            <div className='flex flex-row-reverse flex-1 p-2 items-center justify-between'>
              <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(methods.formState.dirtyFields) || loading}>
                {content?.id ? 'Save' : 'Create'}
              </Button>
              {!isValueEmpty(methods.formState.dirtyFields) && (
                <Button
                  startIcon={<Close />}
                  variant='outlined'
                  color='neutral'
                  disabled={loading}
                  onClick={() => {
                    methods.reset();
                  }}>
                  Cancel
                </Button>
              )}
            </div>
          </Paper>
        </form>
      </FormProvider>
    </div>
  );
}
