import React, { useCallback, useEffect, useState } from 'react';
import { SystemDao } from '../../model/dao/SystemDao';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import {
  addLeaderboardAction,
  deleteLeaderboardAction,
  setLeaderboards,
  setTables,
  setUsers,
  updateLeaderboardAction,
  updateTableAction,
  updateUserAction,
} from '../../model/store/slices/admin/AdminSlice';
import { enqueueSnackbar } from 'notistack';
import { isValueEmpty } from '@grethics/commons';
import { CustomError, CustomResponseError } from '../../utils/Utils';

export default function useSysLeaderBrds(refresh = true) {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(!refresh);
  const { leaderboards } = useSelector((state) => state.admin);

  useEffect(() => {
    const fetchLeaderboards = async () => {
      try {
        dispatch(setLoading(true));
        const leaderboards = await SystemDao.getLeaderboards();
        console.log('leaderboards fetched', leaderboards);
        dispatch(setLeaderboards({ leaderboards }));
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (!fetched) {
      fetchLeaderboards();
    }
  }, [fetched, dispatch]);

  const createLeaderBrd = useCallback(
    async (data) => {
      try {
        dispatch(setLoading(true));
        const leaderboard = await SystemDao.createLeaderboard(data);
        dispatch(addLeaderboardAction({ leaderboard }));
        return { ...leaderboard };
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        throw new Error(error?.message);
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const updateLeaderBrd = useCallback(
    async (data) => {
      try {
        dispatch(setLoading(true));
        const leaderboard = await SystemDao.updateLeaderboard(data?.id, data);
        enqueueSnackbar('Leaderboard updated...', { variant: 'success' });
        dispatch(updateLeaderboardAction({ leaderboard: data }));
        return leaderboard;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        throw new Error(error?.message);
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const deleteLeaderBrd = useCallback(
    async (id) => {
      try {
        dispatch(setLoading(true));
        await SystemDao.deleteLeaderboard(id);
        dispatch(deleteLeaderboardAction({ id }));
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        throw new Error(error?.message);
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  return { leaderboards, createLeaderBrd, updateLeaderBrd, deleteLeaderBrd };
}
