import React, { useState } from 'react';
import { Avatar, Box, Button, TextField, Grid, Typography, Link, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Divider } from '@mui/material';
import useSignInHook from '../../../hooks/auth/useSignInHook';
import { enqueueSnackbar } from 'notistack';
import { GoogleLogin } from '@react-oauth/google';

export default function SignInForm({ redirectTo }) {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const { signInError, signingIn, signIn, socialSignIn } = useSignInHook();

  const handleChangeInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    signIn(credentials, redirectTo);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Sign in
      </Typography>
      <Box sx={{ mt: 8, mb: 3 }} className='w-full flex justify-center items-center'>
        <GoogleLogin
          ux_mode='popup'
          size='large'
          width='300'
          type='standard'
          text='continue_with'
          logo_alignment='center'
          onSuccess={(response) => {
            const socialToken = { access_token: response.credential };
            socialSignIn('google', socialToken, redirectTo);
          }}
          onError={() => {
            enqueueSnackbar('Goolge login failed.', { variant: 'error', autoHideDuration: 4000 });
          }}
        />
      </Box>
      <Divider textAlign='center' sx={{ width: '100%' }}>
        {' '}
        Or{' '}
      </Divider>
      <Box component={'form'} onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField margin='normal' required fullWidth id='email' label='Email' name='email' autoFocus onChange={handleChangeInput} />
        <TextField margin='normal' required fullWidth name='password' label='Password' type='password' id='password' onChange={handleChangeInput} autoComplete='current-password' />
        <Button
          type='submit'
          fullWidth
          variant='outlined'
          disabled={signingIn || credentials.email.length < 6 || credentials.password.length < 6}
          className={signingIn ? 'animate-pulse' : ''}
          sx={{
            mt: 2,
            mb: 2,
            py: 2,
            width: '100%',
            textTransform: 'capitalize',
            fontSize: 20,
            fontWeight: 'light',
          }}>
          Login with Credentials
        </Button>
        {signInError && (
          <Grid item xs>
            <Alert variant='outlined' severity='error' className='rounded my-3 py-2 mx-auto'>
              {signInError?.message}
            </Alert>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
