import { GAME_STATUS, PLAY_STATUS } from '@grethics/commons';
import { Button, Grid, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactDice from 'react-dice-complete';
import { useDispatch, useSelector } from 'react-redux';
import useSound from 'use-sound';
import { playerColor } from '../../../config/constants';
import { ActiveGameController } from '../../../control/play/ActiveGameController';
import { setRollerActiveAction } from '../../../model/store/slices/PlaySlice';
import LastRollValue from './LastRollValue';
import PlayerPad from './PlayerPad';

export default function DiceRoller({ soundFxUrl }) {
  const [firstRoll, setFirstRoll] = useState(true);
  const [play, { stop }] = useSound(soundFxUrl);
  //const [play, { stop }] = useSound('http://localhost:5000/data/media/sounds/dice.mp3');
  const moderator = useSelector((state) => state.play.current.spec?.moderatorId);
  const { gameStatus, playStatus, rollerActive, activeSide, userSide, players, soundFxOn } = useSelector((state) => state.play.current.table);
  const { lastRoll } = players[activeSide]?.state?.lastRoll ?? 1;
  const dispatch = useDispatch();
  const myTurn = moderator ? activeSide !== 'none' : activeSide === userSide;
  const reactDice = useRef(null);

  useEffect(() => {
    setFirstRoll(true);
  }, []);

  const faceColor = myTurn ? playerColor.active[activeSide] : 'gray';
  //const dotColor = side === 'none' || rollerActive ? faceColor : 'white';
  const dotColor = 'white';
  const startUpGame = () => {
    ActiveGameController.startGameUp(userSide);
  };

  const rollDone = (totalValue, values) => {
    if (!firstRoll) {
      ActiveGameController.diceRolled(activeSide, totalValue);
    } else {
      setFirstRoll(false);
    }
    if (soundFxOn) {
      stop();
    }
  };

  const myRollDone = (totalValue) => {
    ActiveGameController.diceRolled(activeSide, totalValue);
    if (soundFxOn) {
      stop();
    }
  };

  const rollAll = () => {
    if (myTurn && rollerActive) {
      dispatch(setRollerActiveAction(false));
      if (soundFxOn) {
        play();
      }
      reactDice.current?.rollAll();
    }
  };

  const renderReactDice = () => {
    if (playStatus === PLAY_STATUS.WAITING) {
      return null;
    }
    if (gameStatus === GAME_STATUS.STARTING_UP) {
      return (
        <div onClick={rollAll}>
          <ReactDice disableIndividual numDice={1} defaultRoll={1} ref={reactDice} rollDone={rollDone} dieCornerRadius={5} faceColor={faceColor} dotColor={dotColor} dieSize={60} />
        </div>
      );
    } else if (gameStatus === GAME_STATUS.STARTED) {
      return (
        <div onClick={rollAll}>
          <ReactDice disableIndividual numDice={1} defaultRoll={2} ref={reactDice} rollDone={rollDone} dieCornerRadius={5} faceColor={faceColor} dotColor={dotColor} dieSize={60} />
          {/* <Grid container sx={{ width: 70, height: 80, backgroundColor: 'secondary' }}>
            <Grid item xs={6}>
              <IconButton
                disabled={!myTurn}
                sx={{ p: 0, m: 0, fontSize: 14, border: 1, minWidth: 20, bgcolor: faceColor }}
                onClick={(event) => {
                  myRollDone(1);
                }}>
                1
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                disabled={!myTurn}
                sx={{ p: 0, m: 0, fontSize: 14, border: 1, minWidth: 20, bgcolor: faceColor }}
                onClick={(event) => {
                  myRollDone(2);
                }}>
                2
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                disabled={!myTurn}
                sx={{ p: 0, m: 0, fontSize: 14, border: 1, minWidth: 20, bgcolor: faceColor }}
                onClick={(event) => {
                  myRollDone(3);
                }}>
                3
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                disabled={!myTurn}
                sx={{ p: 0, m: 0, fontSize: 14, border: 1, minWidth: 20, bgcolor: faceColor }}
                onClick={(event) => {
                  myRollDone(4);
                }}>
                4
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                disabled={!myTurn}
                sx={{ p: 0, m: 0, fontSize: 14, border: 1, minWidth: 20, bgcolor: faceColor }}
                onClick={(event) => {
                  myRollDone(5);
                }}>
                5
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                disabled={!myTurn}
                sx={{ p: 0, m: 0, fontSize: 14, border: 1, minWidth: 20, bgcolor: faceColor }}
                onClick={(event) => {
                  myRollDone(6);
                }}>
                6
              </IconButton>
            </Grid>
          </Grid> */}
        </div>
      );
    }
    return null;
  };

  return (
    <div className='bg-white w-full flex flex-col justify-start items-center border mx-auto shadow-md rounded-md'>
      {gameStatus === GAME_STATUS.NOT_STARTED && playStatus === PLAY_STATUS.READY_TO_PLAY && (
        <Button variant='contained' sx={{ margin: 2, bgcolor: 'purple' }} onClick={startUpGame}>
          Let's Start
        </Button>
      )}
      <div className='flex flex-row justify-center w-full'>
        <div className='flex flex-1 items-center justify-center'>{playStatus === PLAY_STATUS.IN_PLAY && <LastRollValue side={'left'} />}</div>
        {gameStatus === GAME_STATUS.COMPLETED && (
          <div className='flex flex-1 items-center justify-center'>
            <PlayerPad acronyms={true} position={'left'} extraStyle={{ backgroundColor: playerColor.inactive.left, borderRadius: 5 }} />
          </div>
        )}
        <div className='cursor-pointer'>{renderReactDice()}</div>
        <div className='flex flex-1 items-center justify-center'>{playStatus === PLAY_STATUS.IN_PLAY && <LastRollValue side={'right'} />}</div>
        {gameStatus === GAME_STATUS.COMPLETED && (
          <div className='flex items-center justify-center' style={{ backgroundColor: playerColor.inactive.left, borderRadius: 5 }}>
            <PlayerPad acronyms={true} position={'right'} extraStyle={{ backgroundColor: playerColor.inactive.left, borderRadius: 5 }} />
          </div>
        )}
      </div>
    </div>
  );
}
