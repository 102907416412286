"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validators = exports.TOKEN_KEY = void 0;
var AuthValidator_1 = require("./validation/AuthValidator");
var validator_1 = require("validator");
var PlayValidator_1 = require("./validation/PlayValidator");
__exportStar(require("./constants/errors"), exports);
exports.TOKEN_KEY = 'jwtToken';
exports.Validators = {
    auth: AuthValidator_1.default,
    play: PlayValidator_1.default,
    core: __assign({}, validator_1.default),
};
__exportStar(require("./constants/constants"), exports);
__exportStar(require("./types"), exports);
__exportStar(require("./utils"), exports);
