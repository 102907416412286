"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERROR = void 0;
exports.ERROR = {
    ACCESS_DENIED: { code: 'ACCESS_DENIED', message: 'Not Authorized to perform this action' },
    NO_CREDENTIALS: { code: 'NO_CREDENTIALS', message: 'No credentials provided' },
    INVALID_TOKEN: { code: 'INVALID_AUTH_TOKEN', message: 'Ivalid auth token provided' },
    WRONG_CREDENTIALS: { code: 'WRONG_CREDENTIALS', message: 'Wrong credentials provided' },
    EMAIL_NOT_FOUND: { code: 'EMAIL_NOT_FOUND', message: 'Email not found' },
    PWD_INVALID: { code: 'PASSWORD_INVALID', message: 'Password is not valid' },
    EMAIL_REQUIRED: { code: 'EMAIL_REQUIRED', message: 'Email field is required' },
    NAME_REQUIRED: { code: 'NAME_REQUIRED', message: 'Name field is required' },
    NAME_SHORT: { code: 'NAME_SHORT', message: 'Name is short' },
    PWD_REQUIRED: { code: 'PASSWORD_REQUIRED', message: 'Password field is required' },
    PWD_SHORT: { code: 'PASSWORD_SHORT', message: 'Password is too short' },
    PWDS_NOT_MATCH: { code: 'PWDS_NOT_MATCH', message: 'Passwords do not match' },
    EMAIL_INVALID: { code: 'EMAIL_INVALID', message: 'Email is not valid' },
    EMAIL_TAKEN: { code: 'EMAIL_ALREADY_TAKEN', message: 'Email already used' },
    SOCIAL_AUTH_ERROR: { code: 'SOCIAL_AUTH_ERROR', message: 'Social user could not be authendicated' },
    REGISTRATION_ERROR: { code: 'AUTH_REGISTRATION_ERROR', message: 'Restration could not be completed' },
    COUNTRY_REQUIRED: { code: 'COUNTRY_REQUIRED', message: 'Country field is required' },
    CITY_REQUIRED: { code: 'CITY_REQUIRED', message: 'City field is required' },
    INVALID_GAME_CODE: { code: 'INVALID_GAME_CODE', message: 'Invalid game code' },
    DISABLED_USER_ACCOUNT: { code: 'DISABLED_USER_ACCOUNT', message: 'This account has been disabled. Sorry.' },
    GAME_NOT_FOUND: { code: 'GAME_NOT_FOUND', message: 'Game not found' },
    ITEM_NOT_FOUND: { code: 'ITEM_NOT_FOUND', message: 'Item not found' },
    INVALID_PLAYER_CODE: { code: 'INVALID_PLAYER_CODE', message: 'Invalid player code' },
    PLAYER_ALREADY_JOINED: { code: 'PLAYER_ALREADY_JOINED', message: 'Player already joined' },
    GENERAL_FAILURE: { code: 'GENERAL_FAILURE', message: 'Operation failed' },
    MEDIA_NOT_FOUND: { code: 'MEDIA_NOT_FOUND', message: 'Media Object Not Found' },
    GAME_HAS_TABLES: { code: 'FKEY_VIOLATION', message: 'Table cannot be deleted. Tables of this game exist' },
};
