import { createSlice } from '@reduxjs/toolkit';
import { GameSlice } from './GameSlice';

const initialState = null;

export const MediaLibrarySlice = createSlice({
  name: 'mediaLibrary',
  initialState,
  reducers: {
    setMediaLib: (state, action) => {
      const { media } = action.payload;
      return [...media];
    },
    addMediaObjects: (state, action) => {
      const { media } = action.payload;
      if (media) {
        if (Array.isArray(media)) {
          return [...state, ...media];
        } else {
          return [...state, media];
        }
      }
    },
    removeMediaObject: (state, action) => {
      const { mediaId } = action.payload;
      if (mediaId && Array.isArray(state)) {
        return state.filter((m) => m.id !== mediaId);
      }
    },
    clearMediaLib: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('currentGame/clearCurrentGameAction', () => {
      return initialState;
    });
  },
});

export const { setMediaLib, addMediaObjects, removeMediaObject, clearMediaLib } = MediaLibrarySlice.actions;
export default MediaLibrarySlice.reducer;
