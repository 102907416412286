import { CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { clearCurrentGameAction } from '../../../model/store/slices/make/GameSlice';
import { getActiveRoute } from '../../../utils/Utils';

import { Archive, Delete, Settings, Visibility, VisibilityOff } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import useGameHook from '../../../hooks/make/useGameHook';
import { clearMediaLib } from '../../../model/store/slices/make/MediaLibrarySlice';
import { GuiService } from '../../../services/GuiService';

export default function MakeGameRootPage() {
  const { slug } = useParams();
  const location = useLocation();
  const [route, setRoute] = useState('design');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      console.log('cleaning current game state...');
      dispatch(clearMediaLib());
      dispatch(clearCurrentGameAction());
    };
  }, [dispatch]);

  useEffect(() => {
    const newRoute = getActiveRoute(location?.pathname, ['design', 'config']) ?? 'design';
    setRoute(newRoute);
  }, [location.pathname]);

  const { game, deleteGame, updateGame, archiveGame, loading, error } = useGameHook(slug);
  //const { loading } = useSelector((state) => state.make.current);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error', autoHideDuration: 3000 });
      navigate('/make/');
    }
  }, [error, navigate]);

  const handlePubUnpub = () => {
    GuiService.showAlert({
      title: 'Confirmation needed',
      message: <p className='text-center'>{`Are you sure you want to ${game?.publishedAt ? 'unpublish' : 'publish'} this game?`}</p>,
      actions: [
        {
          title: 'Cancel',
          color: 'primary',
        },
        {
          title: 'OK',
          color: 'success',
          callback: async () => {
            updateGame(slug, { ...game, publishedAt: game.publishedAt ? null : new Date() });
          },
        },
      ],
    });
    handleClose();
  };

  const handleDelete = () => {
    GuiService.showAlert({
      title: 'Confirmation needed',
      message: (
        <p className='text-center'>
          Are you sure you want to delete this game?
          <br /> (action cannot be undone)
        </p>
      ),
      actions: [
        {
          title: 'Cancel',
          color: 'primary',
        },
        {
          title: 'Yes',
          color: 'error',
          callback: async () => {
            console.log('Game is gone...!');
            const deleted = await deleteGame(slug);
            if (deleted) {
              navigate('/make/games');
            }
          },
        },
      ],
    });
    handleClose();
  };
  const handleArchive = () => {
    GuiService.showAlert({
      title: 'Confirmation needed',
      message: <p className='text-center'>Are you sure you want to archive this game?</p>,
      actions: [
        {
          title: 'Cancel',
          color: 'primary',
        },
        {
          title: 'Yes',
          color: 'error',
          callback: async () => {
            console.log('Game is gone...!');
            const deleted = await archiveGame(slug);
            if (deleted) {
              navigate('/make/games');
            }
          },
        },
      ],
    });
    handleClose();
  };

  return (
    <div className='w-full'>
      <div className='flex flex-1 w-full flex-row'>
        <Typography variant='h4' className='w-full border-b flex justify-between items-center'>
          {game?.title ?? slug}
          {loading && <CircularProgress thickness={4} size={30} color='primary' sx={{ minHeight: 10, m: 1 }} />}
        </Typography>
      </div>
      <div className='flex flex-row justify-between'>
        <Tabs value={route}>
          <Tab component={Link} sx={{ textTransform: 'none' }} to='design' label={<span className='text-left w-full p-2 text-reg'>Design</span>} value={'design'} />
          <Tab component={Link} sx={{ textTransform: 'none' }} to='config' label={<span className='text-left w-full p-2 text-reg'>Configure</span>} value='config' />
        </Tabs>
        <div className='flex flex-row items-center'>
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}>
            <Settings />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                pt: 1,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={handlePubUnpub}>
              <ListItemIcon>{game?.publishedAt ? <VisibilityOff color='warning' /> : <Visibility color='success' />}</ListItemIcon>
              {`${game?.publishedAt ? 'Unpublish' : 'Publish'} Game`}
            </MenuItem>
            <MenuItem onClick={handleArchive}>
              <ListItemIcon>
                <Archive color='' />
              </ListItemIcon>
              Archive Game
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <Delete color='error' />
              </ListItemIcon>
              Delete Game
            </MenuItem>
          </Menu>
        </div>
      </div>
      {game && <Outlet />}
    </div>
  );
}
