import { createSlice } from '@reduxjs/toolkit';
import { arrayToMap } from '../../../../utils/GameEditUtils';
import { isValueEmpty } from '@grethics/commons';

const initialState = {};

export const GameContentsSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    setContentsAction: (state, action) => {
      const { contents } = action.payload;
      if (Array.isArray(contents)) {
        return { ...arrayToMap(contents, 'id') };
      }
    },
    setContentAction: (state, action) => {
      const { content } = action.payload;
      if (!isValueEmpty(content)) {
        state[content.id] = content;
      }
    },
    deleteContentAction: (state, action) => {
      const { contentId } = action.payload;
      delete state[contentId];
    },
    clearContentsAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('currentGame/clearCurrentGameAction', () => {
      return initialState;
    });
  },
});

export const { setContentAction, setContentsAction, deleteContentAction, clearContentsAction } = GameContentsSlice.actions;
export default GameContentsSlice.reducer;
