import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, IconButton, Pagination, Skeleton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import useMediaHook from '../../../hooks/useMediaHook';
import { isEmpty } from 'lodash';
import TableItem from './TableItem';
import { useDispatch, useSelector } from 'react-redux';
import { Add, ExpandMore } from '@mui/icons-material';
import { onNavToTablePage } from '../../../model/store/slices/TablesSlice';
import { useNavigate } from 'react-router-dom';

export default function TableGroup({ game }) {
  const tables = useSelector((state) => state.play?.tables?.tableMap[game?.id] ?? []);
  const { media, mediaLoading, error } = useMediaHook(game?.logoMediaId);
  const [imageLoaded, setImageLoaded] = useState(false);
  const authUser = useSelector((state) => state.auth.user);
  const pgSize = useSelector((state) => state.play.tables.paging.size);
  const dispatch = useDispatch();
  const tablePages = Math.ceil(tables.length / pgSize);
  const curPage = useSelector((state) => state.play.tables.paging[game?.id]);
  const pgLastIdx = curPage * pgSize;
  const pgStartIdx = pgLastIdx - pgSize;
  const visibleTables = tables.slice(pgStartIdx, pgLastIdx);
  const navigate = useNavigate();

  const handlePegination = (event, newValue) => {
    dispatch(onNavToTablePage({ gameId: game.id, page: newValue }));
  };

  return (
    <div className='mb-3'>
      <Accordion className='w-full'>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
          <Stack direction={'row'} spacing={{ xs: 0, md: 2 }} sx={{ width: '100%', p: 1, color: 'GrayText' }}>
            {!imageLoaded && <Skeleton variant='rectangular' sx={{ top: 0, minHeight: 60, minWidth: 60, m: 0 }} animation={mediaLoading ? 'pulse' : false} />}
            {!isEmpty(media) && <img onLoad={() => setImageLoaded(true)} src={media.url} alt='Game Logo' className='w-[60px] h-[60px] border-gray-200 border p-1 rounded shadow-md' />}
            <div className='flex flex-1 flex-col'>
              <Typography variant='h5'>{game.title}</Typography>
              <Typography className='body1'>{game.publishedAt ? '' : '(Working Version)'}</Typography>
            </div>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {visibleTables.map((tbl) => {
            return <TableItem key={tbl.id} table={tbl} authUser={authUser} />;
          })}
        </AccordionDetails>
        <AccordionActions sx={{ justifyContent: 'end', paddingRight: 4 }}>
          {tablePages > 1 && (
            <div className='flex flex-col w-full items-center'>
              <Pagination onChange={handlePegination} count={tablePages} variant='outlined' color='standard' page={curPage} defaultPage={1} />
            </div>
          )}

          <IconButton
            color='primary'
            sx={{ border: 1 }}
            title='Create New Table'
            onClick={() => {
              navigate('/play/new/' + game.slug, { state: { gameId: game.id, gameTitle: game.title } });
            }}>
            <Add />
          </IconButton>
        </AccordionActions>
      </Accordion>
    </div>
  );
}
