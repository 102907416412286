import { Box, Card, CardContent, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import useMediaHook from '../../../hooks/useMediaHook';
import { isValueEmpty } from '@grethics/commons';
import { format, formatRelative } from 'date-fns';
import { ArchiveOutlined, ContentCopy, Delete, Edit, Login } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { GAME_STATUS } from '@grethics/commons';
import { canUserJoinTable, createOpponentRecord } from '../../../utils/GameUtils';
import TableEditorModal from './TableEditorModal';
import { useUpdateTableHook } from '../../../hooks/play/useUpdateTableHook';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { BASE_URL } from '../../../config/config';
import { TableItemFallback } from '../common/ErrorFallbacks';
import { ErrorBoundary } from 'react-error-boundary';
import { GuiService } from '../../../services/GuiService';

const getStatusInfo = (status) => {
  let label;
  switch (status) {
    case GAME_STATUS.STARTED:
      label = 'Started';
      break;
    case GAME_STATUS.COMPLETED:
      label = 'Completed';
      break;
    case GAME_STATUS.STARTING_UP:
      label = 'Starting up';
      break;
    default:
      label = 'Not started yet';
      break;
  }
  return label;
};
const getModerationInfo = (moderator) => {
  if (!moderator) {
    return null;
  }
  return <span>(moderated by {moderator.name})</span>;
};

export default function TableItem({ authUser, table }) {
  const tblEditorRef = useRef();
  const { game, gamePlayers, state, moderator } = table;

  const tableOpponents = { left: createOpponentRecord(table?.gamePlayers[0]), right: createOpponentRecord(table?.gamePlayers[1]) };

  const canUserEditTable = (isValueEmpty(state) || [GAME_STATUS.NOT_STARTED].includes(state.gameStatus)) && table.creatorId === authUser.id;
  const canUserArchiveTable = [GAME_STATUS.COMPLETED].includes(state?.gameStatus) && table.creatorId === authUser.id;
  const canUserDeleteTable = (isValueEmpty(state) || ![GAME_STATUS.STARTING_UP].includes(state.gameStatus)) && table.creatorId === authUser.id;

  const navigate = useNavigate();
  const { updating, deleteTable, archiveTable } = useUpdateTableHook();

  const handleEdit = () => {
    const data = { slug: table.slug, moderator: table.moderator, gameId: table.gameId, opponents: tableOpponents, availableAt: table.availableAt, state: table.state };
    if (tblEditorRef.current) {
      tblEditorRef.current.show(data);
    }
  };
  const handleDelete = () => {
    GuiService.showAlert({
      title: 'Confirmation needed',
      message: (
        <p className='text-center'>
          Are you sure you want to delete this table?
          <br /> (action cannot be undone)
        </p>
      ),
      actions: [
        {
          title: 'Cancel',
          color: 'primary',
        },
        {
          title: 'Delete',
          color: 'error',
          callback: () => {
            console.log('Table is gone...!');
            deleteTable(table.slug, table.gameId);
          },
        },
      ],
    });
  };

  const handleArchive = () => {
    archiveTable(table.slug, table.gameId);
  };

  return (
    <Box sx={{ padding: 1, mb: 2, border: 0.5, borderColor: 'lightgray', borderRadius: 1 }}>
      <ErrorBoundary fallback={<TableItemFallback />}>
        <TableEditorModal ref={tblEditorRef} />
        <div className='flex flex-1 flex-row items-center'>
          <span className='font-bold pr-1'>{gamePlayers[0]?.player?.name}</span>
          vs.
          <span className='font-bold px-1'>{gamePlayers[1]?.player?.name}</span>
          {getModerationInfo(moderator)}
        </div>
        <div>
          <Typography variant='body1'>
            Created: <span>{format(new Date(table.createdAt), 'yyyy/MM/dd HH:mm')}</span>, Joinable from <span>{formatRelative(new Date(table.availableAt), new Date())}</span>,
            <span className='font-bold'> {getStatusInfo(state?.gameStatus)}</span>
          </Typography>
          <div className='flex flex-row items-center text-reg text-gray-500'>
            Copy Code {/* <span className=''>{table?.slug}</span> */}
            <IconButton
              color='primary'
              onClick={() => {
                navigator.clipboard.writeText(table?.slug);
                enqueueSnackbar({ variant: 'info', message: `Table code copied..`, preventDuplicate: true });
              }}>
              <ContentCopy sx={{ fontSize: 18 }} />
            </IconButton>
            Copy Link {/* <span className=''>{`${BASE_URL}/play/${table?.slug}`}</span> */}
            <IconButton
              color='primary'
              onClick={() => {
                navigator.clipboard.writeText(`${BASE_URL}/play/${table?.slug}`);
                enqueueSnackbar({ variant: 'info', message: `Table code copied..`, preventDuplicate: true });
              }}>
              <ContentCopy sx={{ fontSize: 18 }} />
            </IconButton>
          </div>
        </div>
        <Stack direction={'row-reverse'} spacing={1} sx={{ minHeight: 50 }} className='border-t border-gray-200 px-2 pt-2'>
          {canUserJoinTable(authUser, table.availableAt, tableOpponents, table.moderator, state?.gameStatus) && (
            <IconButton
              color='success'
              title='Join'
              sx={{ border: 1, borderColor: 'success' }}
              onClick={() => {
                navigate('/play/' + table.slug, { state: { gameId: table.gameId, gameTitle: game.title } });
              }}>
              <Login />
            </IconButton>
          )}
          {!updating && canUserEditTable && (
            <IconButton title='Edit' color='primary' sx={{ border: 1, borderColor: 'primary' }} onClick={handleEdit}>
              <Edit />
            </IconButton>
          )}
          {!updating && canUserArchiveTable && (
            <IconButton title='Archive' color='warning' sx={{ border: 1 }} onClick={handleArchive}>
              <ArchiveOutlined />
            </IconButton>
          )}
          {!updating && canUserDeleteTable && (
            <IconButton title='Delete' color='error' sx={{ border: 1 }} onClick={handleDelete}>
              <Delete />
            </IconButton>
          )}
          {updating && <LoadingButton loading={!!updating} color='error' onClick={handleDelete}></LoadingButton>}
        </Stack>
      </ErrorBoundary>
    </Box>
  );
}
