import React from 'react';

export default function MediaError({ width = 300, height = 300, stroke = 'gray' }) {
  return (
    <div style={{ width: 300, height: 300, color: 'red' }}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
          <path d='M8.976 21C4.05476 21 3 19.9452 3 15.024' stroke={stroke} strokeWidth='0.8399999999999999' strokeLinecap='round'></path>{' '}
          <path d='M21 15.024C21 19.9452 19.9452 21 15.024 21' stroke={stroke} strokeWidth='0.8399999999999999' strokeLinecap='round'></path>{' '}
          <path d='M15.024 3C19.9452 3 21 4.05476 21 8.976' stroke={stroke} strokeWidth='0.8399999999999999' strokeLinecap='round'></path>{' '}
          <path d='M8 16C8.91221 14.7856 10.3645 14 12.0004 14C13.6362 14 15.0885 14.7856 16.0007 16' stroke={stroke} strokeWidth='0.8399999999999999' strokeLinecap='round'></path>{' '}
          <path d='M9 10.0112V10' stroke={stroke} strokeWidth='0.8399999999999999' strokeLinecap='round'></path>{' '}
          <path d='M15 10.0112V10' stroke={stroke} strokeWidth='0.8399999999999999' strokeLinecap='round'></path>{' '}
          <path d='M3 8.976C3 4.05476 4.05476 3 8.976 3' stroke={stroke} strokeWidth='0.8399999999999999' strokeLinecap='round'></path>{' '}
        </g>
      </svg>
    </div>
  );
}
