import { Button, FormControl, FormHelperText, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ContentForm from '../../../components/make/ContentForm';
import { ContentType, isValueEmpty } from '@grethics/commons';
import { Check, Close, Delete } from '@mui/icons-material';
import useGameHook from '../../../../hooks/make/useGameHook';
import { GuiService } from '../../../../services/GuiService';
import { FormPrompt } from '../../../components/make/FormPrompt';
import PageHeader from '../../../components/common/PageHeader';

const dummyContent = { type: ContentType.ICON, sysTitle: '', title: '', 'body.text': '', 'body.mediaId': null };

export default function IconDesignPage() {
  const { slug, contentId } = useParams();
  const navigate = useNavigate();
  const { game, loading, contents } = useSelector((state) => state.make.current);
  const { createGameContent, updateGameContent, deleteGameContent } = useGameHook(slug);
  const content = contents[contentId] ?? dummyContent;

  const methods = useForm({
    mode: 'onBlur',
    shouldUnregister: true,
    defaultValues: { ...dummyContent },
    values: { ...content },
  });

  useEffect(() => {
    return () => {
      console.log('resetting form...');
      methods.reset(null, { keepDefaultValues: false, keepDirty: false });
    };
  }, [contentId, methods]);

  const onFormSubmit = async (data) => {
    data.type = ContentType.ICON;
    if (isValueEmpty(data.body.text) && isValueEmpty(data.body.mediaId)) {
      methods.setError('form', { type: 'custom', message: 'An Icon should at least have text or media' });
      return;
    }
    if (content.id) {
      await updateGameContent(slug, contentId, data);
    } else {
      createGameContent(slug, data)
        .then((content) => navigate(`./../${content.id}`))
        .catch((err) => {
          console.log('failed...');
        });
    }
  };

  const deleteContent = async () => {
    GuiService.showAlert({
      title: 'Confirmation needed',
      message: (
        <p className='text-center'>
          Are you sure you want to delete this Item?
          <br /> (action cannot be undone)
        </p>
      ),
      actions: [
        {
          title: 'Cancel',
          color: 'primary',
        },
        {
          title: 'Delete',
          color: 'error',
          callback: async () => {
            const deleted = await deleteGameContent(slug, contentId);
            if (deleted) {
              navigate(`/make/games/${slug}/design/board`);
            }
          },
        },
      ],
    });
  };
  console.log('isDirty: ', methods.formState.isDirty);
  return (
    <div className='flex flex-col flex-1'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit)} autoComplete='off' className='flex flex-col flex-1'>
          <PageHeader title={isValueEmpty(content?.title) ? 'New Icon' : content.title} />
          <FormPrompt hasUnsavedChanges={methods.formState.isDirty && !methods.formState.isSubmitSuccessful} />
          <Paper className='flex flex-col flex-1 w-full items-start mt-2'>
            <div className='flex flex-col flex-grow min-h-[500px] w-full p-2 gap-4'>
              <Controller
                name='title'
                control={methods.control}
                rules={{
                  required: 'An Icon title is required ',
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl fullWidth error={!!error}>
                      <TextField label='Title' {...field} />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
              <Controller
                name='sysTitle'
                control={methods.control}
                rules={{
                  required: 'System Title is required',
                  validate: async (title, id) => {
                    if (id) {
                      return true;
                    }
                    //const slug = slugify(title, { trim: true, lower: true });
                    //const free = await checkGameSlug(slug);
                    //return free || `${title} is reserved...`;
                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl fullWidth error={!!error}>
                      <TextField label='System Title' {...field} />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
              <ContentForm gameId={game?.id} container={'body'} />
              {methods.formState.errors.form && <FormHelperText error>{methods.formState.errors.form.message}</FormHelperText>}
            </div>
          </Paper>
          <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
            {content?.id && (
              <Button startIcon={<Delete />} variant='outlined' color='error' onClick={deleteContent}>
                Delete
              </Button>
            )}
            <div className='flex flex-row-reverse flex-1 p-2 items-center justify-between'>
              <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(methods.formState.dirtyFields) || loading}>
                {content?.id ? 'Save' : 'Create'}
              </Button>
              {!isValueEmpty(methods.formState.dirtyFields) && (
                <Button
                  startIcon={<Close />}
                  variant='outlined'
                  color='neutral'
                  disabled={loading}
                  onClick={() => {
                    methods.reset();
                  }}>
                  Cancel
                </Button>
              )}
            </div>
          </Paper>
        </form>
      </FormProvider>
    </div>
  );
}
