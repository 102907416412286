import axios from 'axios';
import { API_URL } from '../../../config/config';

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const setAuthToken = (token) => {
  if (token) {
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete apiClient.defaults.headers.common['Authorization'];
  }
};

export const setHeader = (key, value) => {
  if (key && value) {
    apiClient.defaults.headers.common[key] = value;
  }
};

export const ApiClient = {
  ...apiClient,
  setAuthToken,
  setHeader,
};
