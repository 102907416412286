import { Check, Close } from '@mui/icons-material';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import { SketchPicker } from 'react-color';
import { PlayerSearchMode, UserSearchMode, Validators } from '@grethics/commons';
import { AgentDao } from '../../../model/dao/AgentDao';
import PlayerAvatar from '../play/PlayerAvatar';

const AuthorFinder = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [excluded, setExcluded] = useState([]);
  const [callback, setCallBack] = useState();
  const [agents, setAgents] = useState([]);

  const onFormSubmit = async () => {
    if (callback && selectedPlayer) {
      callback(selectedPlayer);
      handleClose();
    }
  };

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setSelectedPlayer(null);
    setExcluded([]);
    setCallBack(null);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(clbk, excluded = []) {
      setOpen(true);
      setCallBack(() => clbk);
      setExcluded(excluded);
    },
    hide() {
      handleClose();
    },
  }));

  const findAgents = (searchTerm) => {
    if (!searchTerm || searchTerm.length < 3) {
      return;
    }

    const filter = { mode: UserSearchMode.AUTHOR };
    if (Validators.core.isEmail(searchTerm)) {
      filter.email = searchTerm;
    } else {
      filter.name = searchTerm;
    }
    AgentDao.searchUsers(filter)
      .then((results) => {
        setAgents(
          results.filter((record) => {
            return !excluded.find((coAuthor) => coAuthor.userId === record.id);
          })
        );
      })
      .catch((err) => {
        setAgents([]);
      });
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      findAgents(value);
    } else {
      setAgents([]);
    }
  };

  return (
    <>
      <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
        <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
          Find Co-Author
        </DisposableDialogTitle>
        <DialogContent dividers sx={{ minWidth: '500px' }}>
          <div className='flex  flex-1 flex-col min-h-[200px] min-w-max w-full mt-2 p-2'>
            <Stack direction={'column'} spacing={1}>
              <div className='flex flex-1'>
                <Autocomplete
                  fullWidth
                  value={selectedPlayer}
                  id='free-solo-2-demo'
                  options={agents}
                  filterOptions={(options) => {
                    return options;
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} className='border-b last:border-b-0 border-gray-100 my-1'>
                        <div className={`flex p-1 items-center flex-row`}>
                          <PlayerAvatar imageId={option.imageId} width={32} height={32} />
                          <div className='flex flex-1 ml-1'>{option.name}</div>
                        </div>
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => option?.name ?? ''}
                  onInputChange={onInputChange}
                  onChange={(event, agent) => {
                    setSelectedPlayer(agent);
                  }}
                  renderInput={(params) => <TextField {...params} label='Find by Name or Email' />}
                />
              </div>
            </Stack>
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'lightsteelblue' }}>
          <Button variant='contained' color='error' autoFocus onClick={handleClose} startIcon={<Close />}>
            Cancel
          </Button>
          <Button loadingposition='start' variant='contained' color='primary' onClick={onFormSubmit} startIcon={<Check />}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default AuthorFinder;
