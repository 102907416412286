import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const GameSlice = createSlice({
  name: 'currentGame',
  initialState,
  reducers: {
    setCurrentGameAction: (state, action) => {
      const { game } = action.payload;
      if (game) {
        return { ...game };
      }
    },
    updateCurrentGameAction: (state, action) => {
      const { game } = action.payload;
      if (game) {
        return { ...game };
      }
    },
    updateGameSoundsAction: (state, action) => {
      const { sounds } = action.payload;
      Object.keys(sounds).forEach((key) => {
        state[key] = sounds[key];
      });
    },
    updateCurrentGameAuthorsAction: (state, action) => {
      const { coAuthors } = action.payload;
      state.coAuthors = coAuthors;
    },
    clearCurrentGameAction: () => {
      return initialState;
    },
  },
});

export const { setCurrentGameAction, updateCurrentGameAction, updateGameSoundsAction, updateCurrentGameAuthorsAction, clearCurrentGameAction } = GameSlice.actions;
export default GameSlice.reducer;
