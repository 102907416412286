import { GAME_STATUS } from '@grethics/commons';
import { SvgIcon } from '@mui/material';
import React from 'react';
import Completed from '../../../assets/img/completed.svg';
import Started from '../../../assets/img/started.svg';
import NotStarted from '../../../assets/img/not-started.svg';

export default function GameStatusIcon({ status = GAME_STATUS.NOT_STARTED, width }) {
  let src;
  switch (status) {
    case GAME_STATUS.COMPLETED:
      src = Completed;
      break;
    case GAME_STATUS.STARTED:
      src = Started;
      break;
    default:
      src = NotStarted;
  }

  return <img src={src} alt={status} width={width ?? 32} title={status} />;
}
