import React from 'react';

class ErrorBoundary extends React.Component {
  constructor({ props }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error in ErrorBoundary', error);
    console.log('ErrorInfo in ErrorBoundary', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export const GeneralFailureFallback = ({ message }) => {
  <h1 className='text-center p-2 text-xl'>{message} 💔</h1>;
};

export const GameItemErrorFallback = (props) => {
  return (
    <div>
      <h1>Could not display this game 😩</h1>
    </div>
  );
};

export const TableItemFallback = ({ action, actionHandler }) => {
  return (
    <div>
      <h1>Could not display this table 😩</h1>
      {actionHandler && <button onClick={actionHandler}>{action}</button>}
    </div>
  );
};
