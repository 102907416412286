import PropTypes from 'prop-types';

import { Avatar, Paper, Stack, Typography } from '@mui/material';
import CountUp from 'react-countup';
import humanNumber from 'human-number';

const DashBoardCard = ({ color, icon, title, count, percentage, isLoss, extra }) => (
  <Paper sx={{ p: 2.25, borderColor: 'primary.light', borderWidth: 1 }}>
    <Stack spacing={2}>
      <div className='flex flex-col w-full items-center'>{icon && <Avatar sx={{ width: 56, height: 56 }} variant='square' src={icon} />}</div>
      <div className='flex flex-col w-full items-center justify-evenly'>
        <Typography variant='h6' color='textprimary' sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <CountUp
          formattingFn={(n) => humanNumber(n)}
          className='text-2xl text-emerald-600 text-center'
          start={0}
          end={count}
          duration={2}
          useEasing={true}
          useGrouping={true}
          separator=' '
          decimals={0}
        />
      </div>
      {extra && (
        <Typography variant='caption' color='textprimary' sx={{ textAlign: 'center' }}>
          <Typography component='span' variant='caption' sx={{ color: `${color || 'primary'}.main` }}>
            {extra}
          </Typography>{' '}
          last week
        </Typography>
      )}
    </Stack>
  </Paper>
);

DashBoardCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

DashBoardCard.defaultProps = {
  color: 'primary',
};

export default DashBoardCard;
