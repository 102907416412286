import { AGE_GROUP, isValueEmpty } from '@grethics/commons';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAgeGroupInGameAgeRange } from '../../../utils/GameUtils';
import AgeFilter from '../../components/browse/AgeFilter';
import LangFilter from '../../components/browse/LangFilter';
import PlayListItem from '../../components/browse/PlayListItem';

export default function PlayListBrowser() {
  const [allLangs, setAllLangs] = useState([]);
  const [checkedLangs, setCheckedLangs] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);
  //const { loading } = useFetchPlayListHook();
  const playList = useSelector((state) => state.play?.list);

  useEffect(() => {
    if (!isEmpty(playList)) {
      const gLangs = playList.map((game) => game.lang);
      setAllLangs([...new Set(gLangs)]);
    }
  }, [playList]);

  return (
    <Grid container spacing={1}>
      {playList && playList.length > 0 && (
        <Grid item container xs={12} md={3} justifyContent={'start'} alignSelf={'flex-start'} marginTop={2}>
          <Typography mb={1} variant='h6'>
            Filter Games
          </Typography>
          <LangFilter allLangs={allLangs} onLangChange={(value) => setCheckedLangs(value)} />
          <AgeFilter allAgeGroups={Object.keys(AGE_GROUP)} onRangeChange={(value) => setCheckedGroups(value)} />
        </Grid>
      )}
      <Grid item container xs={12} md={9} justifyContent={'flex-start'} marginTop={2}>
        {/* {loading && (
          <Grid item>
            <Stack spacing={2} direction={'column'} sx={{}}>
              <Skeleton variant='rectangular' sx={{ minHeight: 150 }} />
              <Skeleton variant='rectangular' sx={{ minHeight: 150 }} />
              <Skeleton variant='rectangular' sx={{ minHeight: 150 }} />
            </Stack>
          </Grid>
        )} */}
        {playList && playList.length === 0 && (
          <Grid container spacing={1}>
            <Grid item md={12} lg={12}>
              <div className='text-2xl text-center w-full'>No games available at the moment</div>
              <div className='text-2xl text-center w-full'>Sorry!</div>
              <div className='text-2xl text-center w-full'>💔</div>
            </Grid>
          </Grid>
        )}
        {/* <Stack spacing={2} direction={'column'} sx={{}}>  </Stack> */}
        {playList && playList.length > 0 && (
          <Grid container spacing={1}>
            {playList.map((game) => {
              const inLang = isEmpty(checkedLangs) || checkedLangs.includes(game.lang);
              /* const rangeFilter = checkedGroups.reduce((range, group) => {
                return calculageAgeRange(range, group, true);
              }, {});
              const inRange = isValueEmpty(checkedGroups) || (game.ageRange.minAge <= rangeFilter.minAge && game.ageRange.maxAge >= rangeFilter.maxAge); */
              const inRange = isValueEmpty(checkedGroups) || checkedGroups.some((group) => isAgeGroupInGameAgeRange(game.ageRange, group));
              if (inLang && inRange) {
                return <PlayListItem key={game.id} game={game}></PlayListItem>;
              } else {
                return null;
              }
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
