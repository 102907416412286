import { isValueEmpty } from '@grethics/commons';
import { Check, Close } from '@mui/icons-material';
import { Button, Paper, Typography } from '@mui/material';
import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import { useSelector } from 'react-redux';
import useSysLeaderBrds from '../../../hooks/admin/useSysLeaderBrds';
import LeaderBrdDataGrid from '../../components/admin/leaderboards/LeaderBrdDataGrid';

export default function LeaderbrdAdminPage() {
  const { leaderboards } = useSysLeaderBrds();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [showGrid, setShowGrid] = React.useState(true);
  React.useEffect(() => {
    if (drawerOpen) {
      setShowGrid(false);
      setTimeout(() => {
        setShowGrid(true);
      }, 50);
    }
  }, [drawerOpen]);
  return (
    <form onSubmit={() => {}} className='flex flex-col flex-1' autoComplete='off'>
      <PageHeader title={'Leaderboards'} />
      <Paper className='flex flex-col min-h-[500px] w-full items-start p-2'>
        <div className='flex flex-row w-full gap-4 my-4'>{showGrid && <LeaderBrdDataGrid leaderboards={leaderboards ?? []} />}</div>
      </Paper>
    </form>
  );
}
