import { Paper } from '@mui/material';
import React from 'react';
import useSysUsers from '../../../hooks/admin/useSysUsers';
import UserDataGrid from '../../components/admin/UserDataGrid';
import PageHeader from '../../components/common/PageHeader';
import { useSelector } from 'react-redux';

export default function UsersAdminPage() {
  const { users } = useSysUsers();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [showGrid, setShowGrid] = React.useState(true);

  React.useEffect(() => {
    if (drawerOpen) {
      setShowGrid(false);
      setTimeout(() => {
        setShowGrid(true);
      }, 50);
    }
  }, [drawerOpen]);
  return (
    <form onSubmit={() => {}} className='flex flex-col flex-1' autoComplete='off'>
      <PageHeader title={'Users'} />
      <Paper className='flex flex-col min-h-[500px] w-full items-start p-2'>
        <div className='flex flex-row w-full gap-4 my-4'>{showGrid && <UserDataGrid users={users ?? []} />}</div>
      </Paper>
    </form>
  );
}
