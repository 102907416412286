import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';

import { ContentType } from '@grethics/commons';
import { Add, ArrowBack, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PALLETE } from '../../../../config/constants';
import useGameHook from '../../../../hooks/make/useGameHook';
import { getActiveRoute } from '../../../../utils/Utils';

export default function GameDesignPage() {
  const { slug, contentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { drawerOpen } = useSelector((state) => state.ui);
  const [route, setRoute] = useState('board');
  const { contents, fetchGamePath, fetchGameContents } = useGameHook(slug);

  useEffect(() => {
    if (slug) {
      fetchGamePath(slug);
    }
  }, [fetchGamePath, slug]);

  useEffect(() => {
    if (slug) {
      fetchGameContents(slug);
    }
  }, [fetchGameContents, slug]);

  useEffect(() => {
    const newRoute = getActiveRoute(location?.pathname, ['board', 'facts', 'quizzes', 'stories', 'dyks']) ?? 'board';
    setRoute(newRoute);
  }, [location.pathname]);

  const [factsOpen, setFactsOpen] = useState(false);
  const [quizzesOpen, setQuizzesOpen] = useState(false);
  const [storiesOpen, setStoriesOpen] = useState(false);
  const [iconsOpen, setIconsOpen] = useState(false);
  const [didYouKnowOpen, setDidYouKnowOpen] = useState(false);

  return (
    <div className='flex flex-col flex-1 w-full'>
      <Paper className='flex flex-col w-full min-w-min items-start mt-2'>
        <div className={`flex flex-row w-full mt-2`}>
          <Collapse orientation='horizontal' in={drawerOpen} className='h-full'>
            <div className='flex flex-col min-w-[300px] mr-1 p-1'>
              <div className='w-full rounded-sm p-2 text-lg' style={{ backgroundColor: PALLETE.EMERLAND.extraLight }}>
                <Typography variant='h5'>Game Elements</Typography>
              </div>
              <Paper className='flex flex-1 flex-col h-full mt-2'>
                <Stack direction={'column'}>
                  {route !== 'board' && (
                    <ListItemButton sx={{ pl: 2, borderBottom: 1, borderColor: 'primary' }} component={Link} to={'./'}>
                      <ListItemIcon>
                        <ArrowBack />
                      </ListItemIcon>
                      <ListItemText primary=' Game Board ' primaryTypographyProps={{ fontWeight: 'bold' }} />
                    </ListItemButton>
                  )}
                  <div id='facts' className='flex flex-col'>
                    <ListItemButton
                      onClick={() => {
                        setFactsOpen(!factsOpen);
                      }}>
                      <ListItemText primary='Facts' primaryTypographyProps={{ fontWeight: 'bold' }} />
                      {factsOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={factsOpen} timeout='auto' unmountOnExit>
                      <List
                        component='div'
                        sx={{
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 500,
                          '& ul': { padding: 0 },
                        }}
                        disablePadding>
                        <ListItemButton
                          divider
                          sx={{ alignItems: 'center' }}
                          onClick={() => {
                            navigate('./facts/new');
                          }}>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            <Add color='success' />
                          </ListItemIcon>
                          <ListItemText primary='Create New' primaryTypographyProps={{ color: 'green' }} />
                        </ListItemButton>
                        {Object.values(contents)
                          .filter((cont) => cont.type === ContentType.FACT)
                          .map((cont) => (
                            <ListItemButton key={cont.id} sx={{ pl: 4 }} component={Link} to={'./facts/' + cont.id}>
                              <ListItemText primary={cont.sysTitle ?? `${cont.type} - ${cont.id}`} primaryTypographyProps={{ borderBottom: cont.id === contentId ? '1px solid purple' : null }} />
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </div>
                  <div id='stories' className='flex flex-col'>
                    <ListItemButton
                      onClick={() => {
                        setStoriesOpen(!storiesOpen);
                      }}>
                      <ListItemText primary='Stories' primaryTypographyProps={{ fontWeight: 'bold' }} />
                      {storiesOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={storiesOpen} timeout='auto' unmountOnExit>
                      <List
                        component='div'
                        sx={{
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 500,
                          '& ul': { padding: 0 },
                        }}
                        disablePadding>
                        <ListItemButton
                          divider
                          sx={{ alignItems: 'center' }}
                          onClick={() => {
                            navigate('./stories/new');
                          }}>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            <Add color='success' />
                          </ListItemIcon>
                          <ListItemText primary='Create New' primaryTypographyProps={{ color: 'green' }} />
                        </ListItemButton>
                        {Object.values(contents)
                          .filter((cont) => cont.type === ContentType.STORY)
                          .map((cont) => (
                            <ListItemButton key={cont.id} sx={{ pl: 4 }} component={Link} to={'./stories/' + cont.id}>
                              <ListItemText primary={cont.sysTitle ?? `${cont.type} - ${cont.id}`} primaryTypographyProps={{ borderBottom: cont.id === contentId ? '1px solid purple' : null }} />
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </div>
                  <div id='icons' className='flex flex-col'>
                    <ListItemButton
                      onClick={() => {
                        setIconsOpen(!iconsOpen);
                      }}>
                      <ListItemText primary='Icons' primaryTypographyProps={{ fontWeight: 'bold' }} />
                      {iconsOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={iconsOpen} timeout='auto' unmountOnExit>
                      <List
                        component='div'
                        sx={{
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 500,
                          '& ul': { padding: 0 },
                        }}
                        disablePadding>
                        <ListItemButton
                          divider
                          sx={{ alignItems: 'center' }}
                          onClick={() => {
                            navigate('./icons/new');
                          }}>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            <Add color='success' />
                          </ListItemIcon>
                          <ListItemText primary='Create New' primaryTypographyProps={{ color: 'green' }} />
                        </ListItemButton>
                        {Object.values(contents)
                          .filter((cont) => cont.type === ContentType.ICON)
                          .map((cont) => (
                            <ListItemButton key={cont.id} sx={{ pl: 4 }} component={Link} to={'./icons/' + cont.id}>
                              <ListItemText primary={cont.sysTitle ?? `${cont.type} - ${cont.id}`} primaryTypographyProps={{ borderBottom: cont.id === contentId ? '1px solid purple' : null }} />
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </div>
                  <div id='didyouknow' className='flex flex-col'>
                    <ListItemButton
                      onClick={() => {
                        setDidYouKnowOpen(!didYouKnowOpen);
                      }}>
                      <ListItemText primary='Did you Knows' primaryTypographyProps={{ fontWeight: 'bold' }} />
                      {didYouKnowOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={didYouKnowOpen} timeout='auto' unmountOnExit>
                      <List
                        component='div'
                        sx={{
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 500,
                          '& ul': { padding: 0 },
                        }}
                        disablePadding>
                        <ListItemButton
                          divider
                          sx={{ alignItems: 'center' }}
                          onClick={() => {
                            navigate('./dyks/new');
                          }}>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            <Add color='success' />
                          </ListItemIcon>
                          <ListItemText primary='Create New' primaryTypographyProps={{ color: 'green' }} />
                        </ListItemButton>
                        {Object.values(contents)
                          .filter((cont) => cont.type === ContentType.STORY)
                          .map((cont) => (
                            <ListItemButton key={cont.id} sx={{ pl: 4 }} component={Link} to={'./dyks/' + cont.id}>
                              <ListItemText primary={cont.sysTitle ?? `${cont.type} - ${cont.id}`} primaryTypographyProps={{ borderBottom: cont.id === contentId ? '1px solid purple' : null }} />
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </div>
                  <div id='quizzes' className='flex flex-col'>
                    <ListItemButton
                      onClick={() => {
                        setQuizzesOpen(!quizzesOpen);
                      }}>
                      <ListItemText primary='Quizzes' primaryTypographyProps={{ fontWeight: 'bold' }} />
                      {quizzesOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={quizzesOpen} timeout='auto' unmountOnExit>
                      <List
                        component='div'
                        sx={{
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 500,
                          '& ul': { padding: 0 },
                        }}
                        disablePadding>
                        <ListItemButton
                          divider
                          sx={{ alignItems: 'center' }}
                          onClick={() => {
                            navigate('./quizzes/new');
                          }}>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            <Add color='success' />
                          </ListItemIcon>
                          <ListItemText primary='New Quiz' primaryTypographyProps={{ color: 'green' }} />
                        </ListItemButton>
                        {Object.values(contents)
                          .filter((cont) => cont.type === ContentType.QUIZ)
                          .map((cont) => (
                            <ListItemButton key={cont.id} sx={{ pl: 4 }} component={Link} to={'./quizzes/' + cont.id}>
                              <ListItemText primary={cont.sysTitle ?? `${cont.type} - ${cont.id}`} primaryTypographyProps={{ borderBottom: cont.id === contentId ? '1px solid purple' : null }} />
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </div>
                </Stack>
              </Paper>
            </div>
          </Collapse>
          <div className='flex flex-col flex-1 p-1 ml-1'>{<Outlet />}</div>
        </div>
      </Paper>
    </div>
  );
}
