export const playerColor = {
  //active: { left: 'limegreen', right: 'crimson', none: 'gray' },
  active: { left: '#4dc949', right: '#e5190b', none: 'gray' },
  inactive: { left: '#beedfa', right: '#beedfa', none: 'gray' },
  offline: 'lightgray',
};

export const PALLETE = {
  PURPLE: { extraLight: '#faedfc', light: '#ebbaf4', main: '#800080', dark: '#660066' },
  GRAY: { light: '#606060', main: '#363636', dark: '#1c1c1c' },
  INFO: { light: '#42a5f5', main: '#1976d2', dark: '#1565c0' },
  ERROR: { light: '#ef5350', main: '#d32f2f', dark: '#aa2e25' },
  WARNING: { light: '##ff9800', main: '#ed6c02', dark: '#e65100' },
  CLOUDY: { light: '#E7EAE3', main: '#E1E2E0', dark: '#aaaaaa' },
  SUCCESS: { light: '#c4ffe5', main: '#4dc949', dark: '#0e690a' },
  EMERLAND: { extraLight: '#d5f0ea', light: '#99d4c7', main: '#009473', dark: '#01604b' },
};
