import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function NoRouteMatch() {
  const [elapsedTime, setElapsedTime] = useState(5);
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (elapsedTime > 1) {
        setElapsedTime(elapsedTime - 1);
      } else {
        navigate('/');
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [elapsedTime, navigate, setElapsedTime]);

  return (
    <div className='h-screen w-screen flex flex-col justify-center'>
      <div className='mx-auto text-4xl'>Oops..</div>
      <div className='mx-auto text-2xl'>Nothing to see here...</div>
      <Link to={'/'} className='mx-auto mt-6 text-2xl bg-green-200 py-2 px-5 rounded'>
        Go home now
      </Link>
      <div className='mx-auto text-md'>(or in {elapsedTime} seconds)</div>
    </div>
  );
}
