import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { IconButton, Avatar as MuiAvatar } from '@mui/material';
import * as notistack from 'notistack';
import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';

export default function AvatarEditor({ onChange, onCancel, showPreview, maxSize = 4000000 }) {
  const [preview, setPreview] = useState(null);
  const [done, setDone] = useState(false);

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > maxSize) {
      notistack.enqueueSnackbar('File is too big!', { variant: 'warning', autoHideDuration: null });
      elem.target.value = '';
    }
  };

  return (
    <div className='flex flex-col w-full items-center p-4'>
      {showPreview && preview && <MuiAvatar sx={{ width: 72, height: 72 }} src={preview} alt='Preview' className='w-[100px] h-[100px]' />}
      {!done && <Avatar className='mx-auto' height={200} width={200} onCrop={onCrop} onClose={onClose} onBeforeFileLoad={onBeforeFileLoad} backgroundColor='darkgray' />}
      {!done && preview && (
        <IconButton
          sx={{ textTransform: 'capitalize', top: -200, color: 'white', left: 80 }}
          onClick={() => {
            setDone(true);
            onChange(preview);
          }}>
          <CheckCircleOutline />
        </IconButton>
      )}
      {!done && !preview && (
        <IconButton
          sx={{ textTransform: 'capitalize', top: -200, color: 'black', left: 80 }}
          onClick={() => {
            setDone(true);
            onChange(null);
            if (onCancel) {
              onCancel();
            }
          }}>
          <CancelOutlined />
        </IconButton>
      )}
    </div>
  );
}
