import { Button, Container } from '@mui/material';
import { useRouteError } from 'react-router-dom';

export const PlayErrorElement = ({ action, actionHandler }) => {
  let error = useRouteError();
  return (
    <Container maxWidth={'md'}>
      <h1 className='text-center p-2 text-2xl'>Something went wrong...</h1>
      <h1 className='text-center p-2 text-lg'>Playing games in unavailable at the moment. Sorry!! 💔</h1>
      <div className='flex flex-1 items-center flex-col w-full'>
        {error?.message && <pre className='mx-auto py-8'>ℹ️ {error?.message}</pre>}
        {actionHandler && (
          <div className='flex flex-1 items-center min-w-[200px]'>
            <Button fullWidth variant='outlined' onClick={actionHandler}>
              {action}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export const MakeGamesErrorElement = ({ action, actionHandler }) => {
  let error = useRouteError();
  return (
    <Container maxWidth={'md'}>
      <h1 className='text-center p-2 text-2xl'>Something went wrong...</h1>
      <h1 className='text-center p-2 text-lg'>Games Authoring module crashed. Sorry!! 💔</h1>
      <div className='flex flex-1 items-center flex-col w-full'>
        {error?.message && <pre className='mx-auto py-8'>ℹ️ {error?.message}</pre>}
        {actionHandler && (
          <div className='flex flex-1 items-center min-w-[200px]'>
            <Button fullWidth variant='outlined' onClick={actionHandler}>
              {action}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export const RootErrorElement = ({ action, actionHandler }) => {
  let error = useRouteError();
  return (
    <Container maxWidth={'md'}>
      <h1 className='text-center p-2 text-xl'>Something has gone terribly wrong. Sorry!! 💔</h1>
      <div className='flex flex-1 items-center flex-col w-full'>
        <pre className='mx-auto py-8'>{error?.message || JSON.stringify(error)}</pre>
        <div className='flex flex-1 items-center min-w-[200px]'>
          {actionHandler && (
            <Button fullWidth variant='outlined' onClick={actionHandler}>
              {action}
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};
