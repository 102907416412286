import { createSlice } from '@reduxjs/toolkit';
import { GameSlice } from './GameSlice';

const initialState = null;

export const GamePathSlice = createSlice({
  name: 'path',
  initialState,
  reducers: {
    setPathAction: (state, action) => {
      const { path } = action.payload;
      return { ...path };
    },
    updatePathAction: (state, action) => {
      const { path } = action.payload;
      return { ...path };
    },
    clearPathAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('currentGame/clearCurrentGameAction', () => {
      return initialState;
    });
  },
});

export const { setPathAction, updatePathAction, clearPathAction } = GamePathSlice.actions;
export default GamePathSlice.reducer;
