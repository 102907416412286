import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Zoom } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { GuiService } from '../../../services/GuiService';
import { GameObserver } from '../../../control/play/GameObserver';
import { ActiveGameController } from '../../../control/play/ActiveGameController';

function DisposableDialogTitle(props) {
  const { children, onClose, type, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1, bgcolor: `${type ?? 'primary'}.main`, color: 'white' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={{ enter: 2000, exit: 10 }} direction='down' ref={ref} {...props} />;
});

const FeedbackModal = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(null);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [duration, setDuration] = useState(0);
  const [onClose, setOnClose] = useState(null);

  const handleClose = useCallback(
    (event, reason) => {
      if (reason !== 'backdropClick') {
        if (ActiveGameController.isPrimaryActor()) {
          GameObserver.hideFeedback();
        }
        setTitle(null);
        setContent(null);
        setDuration(0);
        setOpen(false);
        if (onClose) {
          onClose();
        }
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (open && duration > 0) {
      const timeout = setTimeout(handleClose, duration * 1000);
      return () => clearTimeout(timeout);
    }
  }, [open, handleClose, duration]);

  useImperativeHandle(ref, () => ({
    show({ variant, title, content, duration, callback }) {
      setType(variant ?? 'info');
      setTitle(title);
      setContent(content);
      setDuration(duration);
      if (callback) {
        setOnClose(() => callback);
      }
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));
  //console.log({ duration });
  return (
    <div>
      <Dialog disableEscapeKeyDown TransitionComponent={Transition} maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
        <DisposableDialogTitle type={type} id='customized-dialog-title'>
          {title ?? 'Heads Up!'}
        </DisposableDialogTitle>
        <DialogContent sx={{ minWidth: 400, minHeight: 100, p: 0, m: 0 }} className='flex flex-col items-center justify-center'>
          {content}
        </DialogContent>
        {ActiveGameController.isPrimaryActor() && (
          <DialogActions sx={{ justifyContent: 'end' }}>
            <Button variant='text' autoFocus onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
});

export default FeedbackModal;
