import { SOUND_FX_TYPE } from '@grethics/commons';
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { PALLETE } from '../../../config/constants';
import { getSoundFxUrl } from '../../../utils/Utils';
import DiceRoller from './DiceRoller';
import StatusBar from './StatusBar';
import { ControlCamera, DragIndicator } from '@mui/icons-material';

export default function DiceRollerWidget({ boardSize }) {
  const { diceFx } = useSelector((state) => state.play.current.spec.game);

  const position = { x: 0, y: -(boardSize / 2) };

  return (
    <Draggable handle='.dragControl' defaultPosition={position}>
      <Paper sx={{ borderRadius: 2, backgroundColor: PALLETE.GRAY.light, gap: 1 }} elevation={5} className='p-2 border shadow-md'>
        <div className='flex dragControl flex-row cursor-move'>
          <ControlCamera className='mr-1 mb-1' sx={{ color: 'white' }} />
          <StatusBar />
        </div>
        <DiceRoller soundFxUrl={getSoundFxUrl(diceFx, SOUND_FX_TYPE.DICE)} />
      </Paper>
    </Draggable>
  );
}
