import React from 'react';
import { Avatar } from '@mui/material';
import useMediaHook from '../../../hooks/useMediaHook';

export default function PlayerAvatar({ imageId, picture, width, height }) {
  const { loading: mediaLoading, media } = useMediaHook(imageId);
  const avatarUrl = media?.url ?? '/img/avatars/default-profile.svg';
  if (picture) {
    return <Avatar sx={{ width: width ?? { lg: 40 }, height: height ?? { lg: 40 } }} src={picture} alt='Preview' className='w-[100px] h-[100px]' />;
  }
  return <Avatar sx={{ width: width ?? { lg: 40 }, height: height ?? { lg: 40 } }} src={avatarUrl} className={mediaLoading ? 'animate-pulse mx-2' : 'mx-2'} />;
}
