import { Container, Grid, Skeleton } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useFetchTablesHook from '../../../hooks/play/useFetchTablesHook';
import TableGroup from '../../components/browse/TableGroup';

export default function TableBrowser() {
  const { loading, error } = useFetchTablesHook();
  const gameTables = useSelector((state) => state.play?.tables?.tableMap);

  return (
    <div className='flex flex-col flex-1 w-full p-2 min-h-[500px]'>
      {loading && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' sx={{ minHeight: 50, width: '100%' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' sx={{ minHeight: 50, width: '100%' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' sx={{ minHeight: 50, width: '100%' }} />
          </Grid>
        </Grid>
      )}
      {gameTables && Object.keys(gameTables).length === 0 && (
        <Container sx={{ minWidth: '100%', py: 3 }}>
          <div className='text-2xl text-center'>You have not created a table yet</div>
          <div className='text-2xl text-center'>💔</div>
        </Container>
      )}
      {gameTables &&
        Object.keys(gameTables)
          .sort((a, b) => 1)
          .map((gameId) => {
            if (gameTables[gameId] && gameTables[gameId].length > 0) {
              const { game } = gameTables[gameId][0];
              return <TableGroup game={game} key={game.id} />;
            } else {
              return null;
            }
          })}
    </div>
  );
}
