import { Box, Paper, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getActiveRoute } from '../../../utils/Utils';
import useGameRankings from '../../../hooks/play/useGameRankings';

export default function GameRankingsPage() {
  const { slug, id } = useParams();
  const location = useLocation();
  const [route, setRoute] = useState();
  const [routes, setRoutes] = useState([]);
  const [routeLabels, setRouteLabels] = useState([]);
  const { rankings } = useGameRankings(slug, id);
  const navigate = useNavigate();

  useEffect(() => {
    setRoutes((rankings ?? []).map((l) => `${l.id}`));
    setRouteLabels((rankings ?? []).map((l) => l.title));
    if (Array.isArray(rankings) && rankings.length > 0) {
      navigate(`${rankings[0].id}`);
    }
  }, [rankings, navigate]);

  useEffect(() => {
    const newRoute = getActiveRoute(location?.pathname, routes);
    setRoute(newRoute);
  }, [location.pathname, routes]);

  return (
    <Paper className='flex flex-1 flex-col items-center p-2 w-full'>
      <Box sx={{ width: '100%', borderBottom: 1 }}>
        {routes.length > 0 && route && (
          <Tabs
            value={route}
            textColor='primary'
            TabIndicatorProps={{
              style: { borderWidth: 2, borderColor: 'lightgray', visibility: 'hidden' },
            }}
            aria-label='play-games-tabs'>
            {routes.map((r, idx) => {
              const label = routeLabels[idx];
              return <Tab key={r} value={`${r}`} component={Link} to={`${r}`} label={`${label}`} sx={{ textTransform: 'none', fontSize: 20 }} />;
            })}
          </Tabs>
        )}
      </Box>
      <Outlet />
    </Paper>
  );
}
