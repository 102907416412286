import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import useFetchPlayListHook from '../../../hooks/play/useFetchPlayListHook';
import { getActiveRoute } from '../../../utils/Utils';

export default function RootPlayPage() {
  const location = useLocation();
  const [route, setRoute] = useState('join');
  useFetchPlayListHook();
  useEffect(() => {
    const newRoute = getActiveRoute(location?.pathname, ['games', 'tables', 'join']) ?? 'join';
    setRoute(newRoute);
  }, [location.pathname]);

  return (
    <>
      <Typography variant='h4' className='w-full'>
        Play Games
      </Typography>
      <Paper className='flex flex-1 flex-col items-center p-2 w-full'>
        <Box sx={{ width: '100%', borderBottom: 1 }}>
          <Tabs
            value={route}
            textColor='primary'
            TabIndicatorProps={{
              style: { borderWidth: 2, borderColor: 'lightgray', visibility: 'hidden' },
            }}
            aria-label='play-games-tabs'>
            <Tab value='games' component={Link} to='games' label='Games' sx={{ textTransform: 'none', fontSize: 20 }} />
            <Tab value='tables' component={Link} to='tables' label='Tables' sx={{ textTransform: 'none', fontSize: 20 }} />
            <Tab value='join' component={Link} to='./' label='Join Table' sx={{ textTransform: 'none', fontSize: 20 }} />
          </Tabs>
        </Box>
        <Outlet />
      </Paper>
    </>
  );
}
