/* eslint-disable no-undef */
import { GAME_STATUS, PLAY_STATUS, SOUND_FX_TYPE } from '@grethics/commons';
import { MusicNote, MusicOff, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { animated } from '@react-spring/web';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import { BASE_URL, MEDIA_URL } from '../../../config/config';
import { playerColor } from '../../../config/constants';
import { ActiveGameController } from '../../../control/play/ActiveGameController';
import { PassiveGameController } from '../../../control/play/PassiveGameController';
import useGameMetrics from '../../../hooks/make/useGameMetrics';
import usePlayerAvatarHook from '../../../hooks/play/useAvatarHook';
import useScript from '../../../hooks/useScript.js';
import { setSoundBgAction, setSoundFxAction } from '../../../model/store/slices/PlaySlice';
import { hasSquareBeenVisited } from '../../../utils/GameUtils';
import { getMediaUrl, getSoundFxUrl } from '../../../utils/Utils.js';
import DiceRollerWidget from './DiceRollerWidget.jsx';
import PlayerPad from './PlayerPad.js';

export default function Board() {
  const { loaded: ldrLineLoaded } = useScript(BASE_URL + '/js/leader-line.min.js');
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bgFx, walkFx, diceFx } = useSelector((state) => state.play.current.spec.game);
  const board = useSelector((state) => state.play.current.spec?.board);
  const path = board?.path;
  const outer = document.getElementById('outer');
  const canvas = document.getElementById('canvas');
  const { BoardSize, CellSize, CellPadding, SquareSize, AvatarSize, hMargin } = useGameMetrics(outer, canvas, board?.sise ?? 10, true);
  const { playStatus, gameStatus } = useSelector((state) => state.play.current.table);
  const leftAvatar = usePlayerAvatarHook('left', path, CellSize, getMediaUrl(walkFx));
  const rightAvatar = usePlayerAvatarHook('right', path, CellSize, getMediaUrl(walkFx));
  const avatars = { left: leftAvatar, right: rightAvatar };
  const { soundBgOn, soundFxOn, visitedSquares } = useSelector((state) => state.play?.current?.table);
  const [doRestart, setDoRestart] = useState(false);
  const [bgFxplay, { stop: bgFxStop }] = useSound(getSoundFxUrl(bgFx, SOUND_FX_TYPE.BG), {
    volume: 0.2,
    interrupt: true,
    onend: () => {
      setDoRestart(true);
    },
  });

  useEffect(() => {
    if (doRestart) {
      bgFxplay();
      setDoRestart(false);
    }
  }, [doRestart, bgFxplay]);

  useEffect(() => {
    ActiveGameController.setAvatars({ left: leftAvatar, right: rightAvatar });
    PassiveGameController.setAvatars({ left: leftAvatar, right: rightAvatar });
  }, [leftAvatar, rightAvatar]);

  useEffect(() => {
    if (playStatus === PLAY_STATUS.IN_PLAY && soundBgOn) {
      bgFxplay();
    }
    return () => {
      bgFxStop();
    };
  }, [bgFxplay, playStatus, soundBgOn, bgFxStop]);

  useEffect(() => {
    if (path && ldrLineLoaded) {
      const lines = [];
      path.asArray
        .filter((sq) => sq.transition)
        .forEach((sq) => {
          const start = document.getElementById(`sq${sq.order}`);
          const end = document.getElementById(`sq${sq.transition.to}`);
          const color = sq.transition.color ? sq.transition.color : sq.transition.type === 'fwd' ? 'lime' : 'red';
          if (start && end) {
            lines.push(
              new LeaderLine(start, end, {
                size: 8,
                color,
                startSocket: sq.transition.takeoff ?? 'auto',
                endSocket: sq.transition.landing ?? 'auto',
                startPlug: 'disc',
                dash: sq.transition.line === 'dash' ? { animation: !!sq.transition.animated } : false,
              })
            );
          }
        });
      return () => lines.forEach((line) => line.remove());
    }
    // playStatus and gameStatus where put in dependency list in purpose (board is moving a bit when status is changing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldrLineLoaded, playStatus, gameStatus]);

  if (isEmpty(board)) {
    return <></>;
  }

  const doTest = () => {
    //navigate('../play/tables/');
    //ActiveGameController.completeGame('left');
    /* const msg = (
      <div className='flex flex-1 flex-col items-center p-2'>
        <PlayerAvatar width={100} height={100} imageId={'clneqj86600003c39i5iifn35'} />
        <img alt='' src={podium} />
        <p className='text-4xl text-green-500'>{`Nektarios won!`}</p>
        <p className='text-xl text-orange-600'>Congratulations!!!!</p>
      </div>
    );

    GuiService.showFeedback({ variant: 'success', title: 'Game Completed!', content: msg, duration: 0 }); */
  };

  const toogleSoundBg = () => {
    dispatch(setSoundBgAction({ soundBgOn: !soundBgOn }));
  };

  const toogleSoundFx = () => {
    dispatch(setSoundFxAction({ soundFxOn: !soundFxOn }));
  };

  let boardBgUrl = undefined;
  if (board?.bgMedia) {
    boardBgUrl = `${MEDIA_URL}/${board.bgMedia.gameId}/${board.bgMedia.id}.${board.bgMedia.extension}`;
  }
  const renderSquare = (square) => {
    const squareStyle = {};
    if (!square) {
      return null;
    }
    let bgColor = board.sections[square?.sectionId]?.color;
    if (square.type === 'START') {
      bgColor = 'lime';
    }
    if (square.type === 'END') {
      bgColor = 'red';
    }
    squareStyle.background = bgColor;
    squareStyle.opacity = 0.75;
    //squareStyle.borderWidth = square.obligatory ? 5 : 0;

    squareStyle.borderColor = square.obligatory ? 'coral' : 'white';
    squareStyle.borderWidth = square.obligatory ? 5 : 0;
    const orderStyle = { position: 'absolute', top: 0, right: 2, color: 'black', width: 25, textAlign: 'center', background: 'white', borderRadius: 15 };
    const visitorSide = hasSquareBeenVisited(visitedSquares, square.order);
    if (visitorSide) {
      orderStyle.background = playerColor.active?.[visitorSide];
      orderStyle.borderRadius = 15;
    }
    const { bgMedia } = square;
    let mediaUrl = undefined;
    if (bgMedia) {
      mediaUrl = `${MEDIA_URL}/${bgMedia.gameId}/${bgMedia.id}.${bgMedia.extension}`;
    }
    return (
      <div id={`sq${square.order}`} style={{ position: 'relative', width: SquareSize, height: SquareSize, ...squareStyle }} className={`flex justify-center items-center`}>
        {!['START', 'END'].includes(square.type) && <div style={orderStyle}>{square.order}</div>}
        {square.bgMedia && (
          <div className=''>
            <img src={mediaUrl} alt='' />
          </div>
        )}
      </div>
    );
  };

  const renderCell = (index) => {
    const row = Math.floor(index / board.size);
    const col = index - row * board.size;
    const square = path.asMap[`${row}_${col}`];
    return (
      <div key={index} style={{ borderWidth: square ? 1 : 0, width: CellSize, height: CellSize, padding: CellPadding / 4 }} className={'bg-transparent flex items-center justify-center'}>
        {renderSquare(square)}
      </div>
    );
  };
  //const outerDirection = hMargin > 250 ? 'row' : 'column';
  const outerDirection = 'column';
  return (
    <>
      <div
        id='outer'
        style={{
          display: 'flex',
          flexDirection: outerDirection,
          justifyContent: outerDirection === 'column' ? 'start' : 'center',
          alignItems: outerDirection === 'column' ? 'center' : 'self-start',
          height: '100vh',
          width: '100%',
          maxWidth: '100vw',
        }}>
        {(true || outerDirection === 'column') && (
          <div className='flex flex-none flex-row rounded-t-lg' style={{ width: BoardSize }}>
            <PlayerPad position={'left'} />
            <PlayerPad position={'right'} />
          </div>
        )}
        <div className={`flex flex-col flex-1 items-center h-full justify-${outerDirection === 'column' ? 'start' : 'start'}`}>
          <div className='flex w-full items-center justify-center'>
            <div id='canvas' style={{ backgroundImage: `url(${boardBgUrl})` }} className='flex flex-wrap items-center justify-center shadow-md bg-cover relative'>
              {Array.from(Array(board.size * board.size)).map((_, index) => {
                return renderCell(index);
              })}
              {avatars.left.connected && (
                <animated.img
                  src='/img/green-goose.svg'
                  onClick={() => {}}
                  alt='Cass A'
                  title='Class A'
                  style={{
                    ...avatars.left.styles,
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    width: AvatarSize,
                    height: AvatarSize,
                  }}
                />
              )}
              {avatars.right.connected && (
                <animated.img
                  src='/img/red-goose.svg'
                  onClick={() => {}}
                  alt='Cass A'
                  title='Class A'
                  style={{
                    ...avatars.right.styles,
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 9,
                    left: 15,
                    width: AvatarSize,
                    height: AvatarSize,
                  }}
                />
              )}
              <Fab size='small' aria-label='soundBg' sx={{ position: 'absolute', top: 16, left: 16 }} onClick={toogleSoundBg}>
                {!soundBgOn && <MusicOff />}
                {soundBgOn && <MusicNote />}
              </Fab>
              <Fab size='small' aria-label='soundFx' sx={{ position: 'absolute', top: 16, right: 16 }} onClick={toogleSoundFx}>
                {!soundFxOn && <VolumeOff />}
                {soundFxOn && <VolumeUp />}
              </Fab>
              {/* <Fab size='small' aria-label='test' sx={{ position: 'absolute', top: 16, left: 450 }} onClick={doTest}>
                <Check />
              </Fab> */}
            </div>
          </div>
          <DiceRollerWidget boardSize={BoardSize} />
          {/* false && (
            <Draggable axis='y'>
              <div className='flex flex-col absolute bottom-0' style={{ width: BoardSize, cursor: 'move' }}>
                <StatusBar />
                {[PLAY_STATUS.IN_PLAY, PLAY_STATUS.READY_TO_PLAY].includes(playStatus) && outerDirection === 'column' && <DiceRoller soundFxUrl={getSoundFxUrl(diceFx, SOUND_FX_TYPE.DICE)} />}
                {GAME_STATUS.COMPLETED === gameStatus && (
                  <div className='w-full flex flex-col justify-start items-center border mx-auto bg-lime-100 shadow-md rounded-b-xl'>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        navigate('../play/tables/');
                      }}
                      color='primary'
                      sx={{ m: 2 }}>
                      Back to Tables
                    </Button>
                  </div>
                )}
              </div>
            </Draggable>
          ) */}
        </div>
      </div>
    </>
  );
}
