// FormPrompt.js

import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { GuiService } from '../../../services/GuiService';

export const FormPrompt = ({ hasUnsavedChanges }) => {
  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  let block = useBlocker(hasUnsavedChanges);

  useEffect(() => {
    if (block.state === 'blocked') {
      GuiService.showAlert({
        message: 'There are unsaved changes that will be lost if you leave!',
        actions: [
          { title: 'Leave', color: 'error', callback: () => block.proceed() },
          { title: 'Stay', color: 'success', callback: () => block.reset() },
        ],
      });
    }
  }, [block]);
};
