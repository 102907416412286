import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { TableDao } from '../../model/dao/TableDao';
import { useDispatch } from 'react-redux';
import { deleteGameTableAction, updateGameTableAction } from '../../model/store/slices/TablesSlice';
import { addDays } from 'date-fns';

export function useUpdateTableHook() {
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const [updateError, setError] = useState(null);
  const [updated, setUpdated] = useState(null);

  const updateTable = async (slug, gameId, opponents, moderator, availableAt) => {
    try {
      setUpdating(true);
      setUpdated(false);
      setError(null);
      const payload = { slug, gameId, opponents, availableAt };
      if (moderator) {
        payload.moderatorId = moderator.id;
      }
      const table = await TableDao.updateTable(slug, payload);
      setUpdated(table);
      dispatch(updateGameTableAction({ table, gameId }));
      enqueueSnackbar('Table updated...', { variant: 'success' });
    } catch (error) {
      setUpdated(null);
      setError(error?.message);
      enqueueSnackbar(error?.message ?? 'Error!!!', { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  const deleteTable = async (slug, gameId) => {
    try {
      setUpdating(true);
      setError(null);
      await TableDao.deleteTable(slug);
      dispatch(deleteGameTableAction({ slug, gameId }));
      enqueueSnackbar('Table deleted...', { variant: 'success' });
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(error?.message ?? 'Error!!!', { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };
  const archiveTable = async (slug, gameId) => {
    try {
      setUpdating(true);
      setError(null);
      await TableDao.archiveTable(slug);
      dispatch(deleteGameTableAction({ slug, gameId }));
      enqueueSnackbar('Table archived...', { variant: 'success' });
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(error?.message ?? 'Error!!!', { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  return { updated, updating, updateError, updateTable, archiveTable, deleteTable };
}
