import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { TableDao } from '../../model/dao/TableDao';
import { isValueEmpty } from '@grethics/commons';

export default function useJoinTableHook() {
  const [joining, setJoining] = useState(false);
  const [joinError, setError] = useState(null);
  const navigate = useNavigate();

  const joinTable = async (slug, playerCode) => {
    try {
      setJoining(true);
      setError(null);
      const permission = await TableDao.joinTable(slug, playerCode);
      if (permission?.joinable) {
        navigate('/play/' + slug);
      } else {
        enqueueSnackbar(permission.reason, { variant: 'error' });
      }
    } catch (error) {
      setError(error?.message);
      enqueueSnackbar(isValueEmpty(error?.message) ? 'Something went wrong...' : error.message, { variant: 'error' });
    } finally {
      setJoining(false);
    }
  };

  return { joining, joinError, joinTable };
}
