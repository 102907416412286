import { useEffect, useState } from 'react';
import { MediaDao } from '../model/dao/MediaDao';
import { MEDIA_URL } from '../config/config';
import { getMediaUrl } from '../utils/Utils';
import { resetLoading } from '../model/store/slices/UiSlice';

export default function useMediaHook(mediaId) {
  const [mediaLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [media, setMedia] = useState({});

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await MediaDao.fetchMediaById(mediaId);
        setMedia({ ...data, url: getMediaUrl(data) });
      } catch (error) {
        setError(error?.message);
      } finally {
        setLoading(false);
      }
    };
    if (mediaId) {
      fetchMedia();
    } else {
      setMedia({});
    }
  }, [mediaId]);

  return { media, mediaLoading, error };
}
