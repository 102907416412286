import { Autocomplete, Box, Button, Checkbox, Container, FormControlLabel, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AgentDao } from '../../../../model/dao/AgentDao';
import { useSelector } from 'react-redux';
import { PlayerSearchMode, Validators } from '@grethics/commons';
import { DateRangeOutlined, DeleteForever, EditAttributes, EditCalendar, EditCalendarOutlined } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PlayerInfo from '../common/PlayerInfo';
import { isBefore } from 'date-fns';

export default function TableSettingsForm({ opponents, moderator, availableAt, onModeratorChange, onAvailabilityChange }) {
  const authUser = useSelector((state) => state.auth.user);
  const registeredPlayers = Object.values(opponents).filter((op) => op?.user);
  const moderatorNeeded = registeredPlayers.length !== 2;

  useEffect(() => {
    let defaultModerator = null;
    if (!moderator && opponents.left && opponents.right && moderatorNeeded) {
      const currentUserPlayer = Object.values(opponents).find((op) => op?.user?.id === authUser.id);
      if (currentUserPlayer) {
        defaultModerator = currentUserPlayer.user;
      } else {
        const registeredPlayers = Object.values(opponents).filter((op) => op?.user);
        if (registeredPlayers.length === 1) {
          defaultModerator = registeredPlayers[0].user;
        } else {
          defaultModerator = authUser;
        }
      }
    }
    if (!moderator && onModeratorChange) {
      onModeratorChange(defaultModerator);
    }
  }, [moderator, onModeratorChange, opponents, authUser, moderatorNeeded]);

  const [players, setPlayers] = React.useState([]);

  const moderatedByPlayer =
    Object.values(opponents).filter((o) => {
      return moderator && o.user && o.user.id === moderator.id;
    }).length > 0;

  const findModerators = (searchTerm) => {
    if (!searchTerm || searchTerm.length < 3) {
      return;
    }
    try {
      const filter = {};
      if (Validators.core.isEmail(searchTerm)) {
        filter.email = searchTerm;
      } else {
        filter.name = searchTerm;
        filter.mode = PlayerSearchMode.REGISTERED;
      }
      AgentDao.searchPlayers(filter).then((results) => {
        setPlayers(results.filter((p) => p.user));
      });
    } catch (err) {
      console.log('operation aborded...');
    }
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      findModerators(value);
    } else {
      setPlayers([]);
    }
  };

  const setModerator = (user) => {
    onModeratorChange(user);
  };

  return (
    <Container sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
      {(opponents?.left || opponents?.right) && (
        <Box sx={{ py: { xs: 1, sm: 2 } }}>
          <Typography variant='h5'>Game Players</Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            {Object.entries(opponents).map((entry) => {
              const side = entry[0];
              const record = entry[1];
              if (!record) {
                return (
                  <Paper key={side} sx={{ py: { xs: 1, sm: 2, width: '100%' }, px: 2 }}>
                    <div className='w-full flex flex-col items-center'>
                      <PlayerInfo record={null} />
                    </div>
                  </Paper>
                );
              }
              const { player, user } = record;

              return (
                <Paper key={side} sx={{ py: { xs: 1, sm: 2, width: '100%' }, px: 2 }}>
                  <div className='w-full flex flex-col items-center'>
                    <PlayerInfo authUser={authUser} record={record} withAvatar avatarH={64} avatarW={64} direction='col' />
                    {user && (
                      <div className='my-2 w-full border-t'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={registeredPlayers.length === 1}
                              checked={moderator?.id === user.id}
                              onChange={(e) => {
                                if (moderator?.id === user.id) {
                                  e.preventDefault();
                                  return;
                                }
                                setModerator(e.target.checked ? user : null);
                              }}
                              name='moderator'
                            />
                          }
                          label='Moderator'
                        />
                      </div>
                    )}
                  </div>
                </Paper>
              );
            })}
          </Stack>
        </Box>
      )}
      {/* Search for NonPlayer Moderator */}
      <Box>
        {moderatorNeeded && !moderator && (
          <div className='my-3'>
            <Typography variant={'h5'}>Game Moderator</Typography>
            <Autocomplete
              value={moderator}
              id='free-solo-2-demo'
              freeSolo
              options={players}
              filterOptions={(options) => {
                return options;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} className='border-b last:border-b-0 border-gray-100 my-1'>
                    <PlayerInfo authUser={authUser} record={option} withAvatar avatarW={32} avatarH={32} />
                  </li>
                );
              }}
              getOptionLabel={(option) => option?.name ?? ''}
              onInputChange={onInputChange}
              onChange={(event, newValue) => {
                setModerator(newValue.user);
              }}
              renderInput={(params) => <TextField {...params} label='Name or Email' />}
            />
          </div>
        )}
      </Box>
      {/* Show the NonPlayer Moderator */}
      {moderator && !moderatedByPlayer && (
        <Box sx={{ py: { xs: 1, sm: 2 } }}>
          <Typography variant={'h5'}>Game Moderator</Typography>
          <Paper sx={{ py: { xs: 1, sm: 2 }, px: 2 }}>
            <div className='w-full flex flex-row items-center justify-between'>
              <div className='flex flex-1'>
                <PlayerInfo authUser={authUser} record={{ name: moderator?.name, user: moderator }} withAvatar avatarW={64} avatarH={64} direction='column' />
              </div>
              <IconButton
                aria-label='delete'
                color='error'
                onClick={() => {
                  setModerator(null);
                }}>
                <DeleteForever />
              </IconButton>
            </div>
          </Paper>
        </Box>
      )}
      <Box sx={{ py: { xs: 1, sm: 2 } }}>
        <Typography variant='h5'>Availablity</Typography>
        <Paper sx={{ p: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className='flex flex-row flex-1 items-center'>
              <div className='flex flex-row items-center justify-between'>
                <div className='mr-1'>{`Available from ${!availableAt ? 'now!' : ''}`}</div>
                {!availableAt && (
                  <IconButton className='float-right' onClick={() => onAvailabilityChange(new Date())}>
                    <EditCalendarOutlined />
                  </IconButton>
                )}
              </div>
              {availableAt && <DateTimePicker label={'Date'} value={availableAt} onChange={(newValue) => onAvailabilityChange(newValue)} />}
            </div>
          </LocalizationProvider>
        </Paper>
      </Box>
    </Container>
  );
}
