import { CustomResponseError } from '../../utils/Utils';
import { ApiClient } from './utils/ApiClient';

export const SystemDao = {
  getStats: async () => {
    try {
      const { data } = await ApiClient.get(`/admin/stats`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  getUsers: async () => {
    try {
      const { data } = await ApiClient.get(`/admin/users`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  getUser: async (id) => {
    try {
      const { data } = await ApiClient.get(`/admin/users/${id}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  updateUser: async (user) => {
    try {
      const { data } = await ApiClient.patch(`/admin/users/${user?.id}`, { data: { user } });
      return data;
    } catch (error) {
      console.log(JSON.stringify(error));
      throw CustomResponseError(error);
    }
  },
  getGames: async () => {
    try {
      const { data } = await ApiClient.get(`/admin/games`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  getTables: async () => {
    try {
      const { data } = await ApiClient.get(`/admin/tables`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  getPlayers: async () => {
    try {
      const { data } = await ApiClient.get(`/admin/players`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  getPlayer: async (id) => {
    try {
      const { data } = await ApiClient.get(`/admin/players/${id}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  updatePlayer: async (player) => {
    try {
      const { data } = await ApiClient.patch(`/common/players/${player?.id}`, { player });
      return data;
    } catch (error) {
      console.log(JSON.stringify(error));
      throw CustomResponseError(error);
    }
  },
  deletePlayerTables: async (playerId) => {
    try {
      const { data } = await ApiClient.delete(`/admin/players/${playerId}/tables`);
      return data;
    } catch (error) {
      console.log(JSON.stringify(error));
      throw CustomResponseError(error);
    }
  },
  getLeaderboards: async () => {
    try {
      const { data } = await ApiClient.get(`/admin/leaderboards`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  updateLeaderboard: async (id, leaderboard) => {
    try {
      const { data } = await ApiClient.patch(`/admin/leaderboards/${id}`, { leaderboard });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  createLeaderboard: async (leaderboard) => {
    try {
      const { data } = await ApiClient.post(`/admin/leaderboards`, { leaderboard });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  deleteLeaderboard: async (id) => {
    try {
      await ApiClient.delete(`/admin/leaderboards/${id}`);
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
};
