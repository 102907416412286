import React, { useEffect, useLayoutEffect, useState } from 'react';

function getCurrentWidthScale() {
  const smBreak = window.matchMedia('(max-width: 640px)');
  const mdBreak = window.matchMedia('(max-width: 800px)');
  const lgBreak = window.matchMedia('(max-width: 1024px)');
  if (smBreak.matches) {
    return 'sm';
  } else if (mdBreak.matches) {
    return 'md';
  } else if (lgBreak.matches) {
    return 'lg';
  }
  return 'xl';
}

export default function useScreenSize() {
  const [range, setRange] = useState(getCurrentWidthScale());
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const xsBreak = window.matchMedia('(max-width: 500px)');
    const smBreak = window.matchMedia('(max-width: 640px)');
    const mdBreak = window.matchMedia('(max-width: 800px)');
    const lgBreak = window.matchMedia('(max-width: 1024px)');

    xsBreak.addEventListener('change', (e) => {
      setRange(e.matches ? 'xs' : 'sm');
    });
    smBreak.addEventListener('change', (e) => {
      setRange(e.matches ? 'sm' : 'md');
    });
    mdBreak.addEventListener('change', (e) => {
      setRange(e.matches ? 'md' : 'lg');
    });
    lgBreak.addEventListener('change', (e) => {
      setRange(e.matches ? 'lg' : 'xl');
    });
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { range, size };
}
