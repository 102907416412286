import { isValueEmpty } from '@grethics/commons';
import { Check, Close, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, IconButton, InputLabel, Skeleton, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSysPlayers from '../../../../hooks/admin/useSysPlayers';
import DisposableDialogTitle from '../../common/DisposableDialogTitle';
import PlayerFormFields from '../../common/PlayerFormFields';
import PlayerAvatar from '../../play/PlayerAvatar';
import useSysLeaderBrds from '../../../../hooks/admin/useSysLeaderBrds';
import CitySelector from './CitySelector';
import CountrySelector from './CountrySelector';
import GameSelector from './GameSelector';
import { setLeaderboards } from '../../../../model/store/slices/admin/AdminSlice';
import { GuiService } from '../../../../services/GuiService';

const LeaderBrdEditModal = React.forwardRef(({ onSave }, ref) => {
  const [open, setOpen] = useState(false);
  const [ldrbrdId, setLdrbrdId] = useState(null);
  const [ldrbrdInfo, setLdrbrdInfo] = useState(null);
  const loading = useSelector((state) => state.ui.loading);
  const { updateLeaderBrd, createLeaderBrd, deleteLeaderBrd } = useSysLeaderBrds(false);

  const {
    formState: { isDirty, dirtyFields, errors },
    setValue,
    control,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: { id: '', title: '', domain: { region: { countries: [], cities: [] }, games: [] }, maxEntries: 0, timeFrames: false },
    values: isValueEmpty(ldrbrdInfo) ? undefined : { ...ldrbrdInfo },
  });

  const onFormSubmit = async (data) => {
    data.domain.gameIds = [];
    data.domain.games.forEach((game) => {
      data.domain.gameIds.push(game.id);
    });
    if (isValueEmpty(data?.id)) {
      createLeaderBrd(data)
        .then(() => {
          handleClose();
        })
        .catch((err) => {});
    } else {
      updateLeaderBrd(data)
        .then(() => {
          handleClose();
        })
        .catch((err) => {});
    }
  };

  const domain = watch('domain');

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
    setLdrbrdInfo({});
    reset();
  };
  const handleDelete = async () => {
    GuiService.showAlert({
      title: 'Confirmation needed',
      message: (
        <p className='text-center'>
          Are you sure you want to delete this Leaderboard?
          <br /> (action cannot be undone)
        </p>
      ),
      actions: [
        {
          title: 'Cancel',
          color: 'primary',
        },
        {
          title: 'Yes',
          color: 'error',
          callback: async () => {
            deleteLeaderBrd(ldrbrdInfo.id)
              .then((result) => {
                handleClose();
              })
              .catch((err) => {});
          },
        },
      ],
    });
  };

  useImperativeHandle(ref, () => ({
    show(info) {
      setLdrbrdInfo(info);
      setOpen(true);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <Dialog fullWidth disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
      <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
        <DisposableDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <div className='flex flex-row items-center just'>
            {`${ldrbrdInfo?.title ?? 'New Leaderboard'}`}
            <div className='flex flex-1 items-center justify-end pr-10'>{loading && <CircularProgress sx={{ alignSelf: 'flex-end' }} />}</div>
          </div>
        </DisposableDialogTitle>
        <DialogContent dividers sx={{ minWidth: '100%' }}>
          {loading && <Skeleton width={500} height={300} animation={'pulse'} />}
          <div className='w-full p-2 gap-4 min-w-[500px]'>
            <Controller
              name='title'
              defaultValue={''}
              control={control}
              rules={{ required: 'Leaderboard Title is required' }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
                    <TextField label='Leaderboard Title' {...field} fullWidth type={'text'} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <div className='relative flex flex-1 flex-col border rounded-md px-4 border-gray-300'>
              <Typography variant='caption' sx={{ color: 'gray', px: 1, backgroundColor: 'white', position: 'absolute', left: 1, top: -10 }}>
                Leaderboard Domain
              </Typography>
              <Controller
                name='domain.region.countries'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return <CountrySelector value={field.value} onValueChange={(value) => setValue(field.name, value, { shouldDirty: true })} />;
                }}
              />
              <Controller
                name='domain.region.cities'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <CitySelector
                      value={field.value}
                      country={domain?.region?.countries}
                      onValueChange={(value) => {
                        setValue(field.name, value, { shouldDirty: true });
                      }}
                    />
                  );
                }}
              />
              <Controller
                name='domain.games'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return <GameSelector value={field.value} onValueChange={(value) => setValue(field.name, value, { shouldDirty: true })} />;
                }}
              />
            </div>
            <div className='flex flex-1 flex-row gap-4'>
              <Controller
                name='maxEntries'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth sx={{ my: 2 }}>
                      <TextField
                        inputProps={{ min: 0 }}
                        label='Number of Players to Include (0 = all)'
                        {...field}
                        fullWidth
                        type={'number'}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setValue(field.name, Number.parseInt(e.target.value), { shouldDirty: true, shouldValidate: true });
                          }
                        }}
                      />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
              <Controller
                name='timeFrames'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl
                      error={!!error}
                      fullWidth
                      sx={{ border: 1, borderRadius: 1, borderColor: 'lightgray', display: 'flex', flexDirection: 'col', alignItems: 'center', justifyContent: 'center', my: 2 }}>
                      <Typography variant='caption' sx={{ position: 'absolute', left: 10, top: -10, backgroundColor: 'white', color: 'gray' }}>
                        Time Filters
                      </Typography>
                      <Switch size='medium' {...field} />
                    </FormControl>
                  );
                }}
              />
            </div>
            <div className='flex flex-1 flex-col gap-4 items-center'>
              <Button variant='outlined' color='error' onClick={handleDelete}>
                Delete Leaderboard
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', bgcolor: 'primary.extraLight' }}>
          <Button variant='contained' color='error' autoFocus onClick={handleClose} startIcon={<Close />}>
            Cancel
          </Button>
          <LoadingButton loading={!!loading} loadingposition='start' variant='contained' color='primary' type='submit' disabled={isValueEmpty(dirtyFields)} startIcon={<Check />}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default LeaderBrdEditModal;
