import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stats: {},
  users: [],
  games: [],
  tables: [],
  leaderboards: [],
  rankings: {},
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setStats: (state, action) => {
      const { stats } = action.payload;
      state.stats = stats;
    },
    setUsers: (state, action) => {
      const { users } = action.payload;
      state.users = users;
    },
    setGames: (state, action) => {
      const { games } = action.payload;
      state.games = games;
    },
    setPlayers: (state, action) => {
      const { players } = action.payload;
      state.players = players;
    },
    setTables: (state, action) => {
      const { tables } = action.payload;
      state.tables = tables;
    },
    setLeaderboards: (state, action) => {
      const { leaderboards } = action.payload;
      state.leaderboards = leaderboards;
    },
    updateUserAction: (state, action) => {
      const { user } = action.payload;
      const idx = state.users.findIndex((usr) => usr.id === user.id);
      if (idx > -1) {
        state.users[idx] = { ...user };
      }
    },
    updatePlayerAction: (state, action) => {
      const { player } = action.payload;
      const pRecord = state.players.find((r) => r.id === player.id);
      if (pRecord) {
        pRecord.name = player.name;
        pRecord.player = player;
      }
    },
    updateTableAction: (state, action) => {
      const { table } = action.payload;
      const idx = state.tables.findIndex((tbl) => tbl.id === table.id);
      if (idx > -1) {
        state.tables[idx] = { ...table };
      }
    },
    addLeaderboardAction: (state, action) => {
      const { leaderboard } = action.payload;
      state.leaderboards.push({ ...leaderboard });
    },
    updateLeaderboardAction: (state, action) => {
      const { leaderboard } = action.payload;
      const idx = state.leaderboards.findIndex((ldrbrd) => ldrbrd.id === leaderboard.id);
      if (idx > -1) {
        state.leaderboards[idx] = { ...leaderboard };
      }
    },
    deleteLeaderboardAction: (state, action) => {
      const { id } = action.payload;
      state.leaderboards = state.leaderboards.filter((lb) => lb.id !== id);
    },
    clearPlayerTablesAction: (state, action) => {
      const { playerId } = action.payload;
      const pRecord = state.players.find((p) => p.id === playerId);
      pRecord.player.tables = 0;

      state.tables = state.tables.filter((t) => {
        return t.gamePlayers[0]?.playerId !== playerId && t.gamePlayers[1]?.playerId !== playerId;
      });
    },
    setGameRankings: (state, action) => {
      const { slug, rankings } = action.payload;
      if (slug) {
        state.rankings[slug] = rankings;
      }
    },
    logoutAction: (state) => {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('auth/logoutAction', (state, action) => {
        return { ...initialState };
      })
      .addCase('tables/updateGameTableAction', (state, action) => {
        const { table } = action.payload;
        const idx = state.tables.findIndex((tbl) => {
          console.log(`comparing ${tbl.slug} with ${table.slug}`);
          return tbl.slug === table.slug;
        });
        console.log('found: ' + idx >= 0);
        if (idx > -1) {
          state.tables[idx] = { ...table };
        }
      })
      .addCase('tables/deleteGameTableAction', (state, action) => {
        const { slug } = action.payload;
        state.tables = state.tables.filter((tbl) => {
          return tbl.slug !== slug;
        });
      });
  },
});

export const {
  setStats,
  setUsers,
  setGames,
  setPlayers,
  setTables,
  setLeaderboards,
  updateUserAction,
  updateTableAction,
  updatePlayerAction,
  addLeaderboardAction,
  updateLeaderboardAction,
  deleteLeaderboardAction,
  clearPlayerTablesAction,
  setGameRankings,
} = adminSlice.actions;

export default adminSlice.reducer;
