import React, { ReactNode, RefObject } from 'react';

export interface FeedbackConfig {
  variant?: 'info' | 'warning' | 'success' | 'error' | 'primary';
  title?: string;
  content: ReactNode | string;
  duration?: number;
  callback?: () => any;
}

interface AlertAction {
  title?: string;
  color?: string;
  callback?: () => any;
}

export interface AlertConfig {
  title?: string;
  message?: ReactNode | string;
  actions?: AlertAction[] | [];
}

export const contentModalRef: RefObject<{
  show: (player: any) => void;
  hide: () => void;
}> = React.createRef();

export const feedbackModalRef: RefObject<{
  hide: () => void;
  show: (feedback: FeedbackConfig) => void;
}> = React.createRef();

export const alertDialogRef: RefObject<{
  hide: () => void;
  show: (alert: AlertConfig) => void;
}> = React.createRef();

export const GuiService = {
  showContent: (player: any) => {
    if (contentModalRef.current) {
      contentModalRef.current.show(player);
    } else {
      console.log('gui: ContentModal is not mounted');
    }
  },
  hideContent: () => {
    if (contentModalRef.current) {
      contentModalRef.current.hide();
    } else {
      console.log('gui: FeedbackModal is not mounted');
    }
  },
  showFeedback: ({ variant, title, content, duration, callback }: FeedbackConfig): Promise<Boolean> => {
    return new Promise((resolve, reject) => {
      const clbk = () => {
        if (callback) {
          console.log('@feedbackHide: calling callback...');
          callback();
        }
        console.log('@feedbackHide: resolving...');
        resolve(true);
      };
      if (feedbackModalRef.current) {
        feedbackModalRef.current.show({ variant, title, content, duration, callback: clbk });
      } else {
        console.log('gui: FeedbackModal is not mounted');
        resolve(false);
      }
    });
  },
  hideFeedback: () => {
    if (feedbackModalRef.current) {
      feedbackModalRef.current.hide();
    } else {
      console.log('gui: FeedbackModal is not mounted');
    }
  },
  showAlert({ title, message, actions }: AlertConfig): any {
    if (alertDialogRef.current) {
      alertDialogRef.current.show({ title, message, actions: actions ?? [] });
    }
  },
};
