import React, { useState } from 'react';
import { AuthDao } from '../../model/dao/AuthDao';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../model/store/slices/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { parseToken, setAuthCookie } from '../../model/dao/utils/AuthHelper';
import { enqueueSnackbar } from 'notistack';

export default function useSignUpHook() {
  const [signingUp, setSigningUp] = useState(false);
  const [signUpError, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signUp = async (userData, redirectTo) => {
    try {
      setSigningUp(true);
      setError(null);
      const { token } = await AuthDao.registerUser(userData); //TODO fixit
      const user = parseToken(token);
      setAuthCookie(token);
      dispatch(loginAction(user));
      enqueueSnackbar('You have been registered succesfully.', { variant: 'success' });
      navigate(redirectTo ?? '/');
    } catch (error) {
      setError(error);
      console.log({ error });
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setSigningUp(false);
    }
  };

  return { signingUp, signUpError, signUp };
}
