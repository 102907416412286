import { GAME_STATUS, PLAY_STATUS } from '@grethics/commons';
import { Backdrop, Box, LinearProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ActiveGameController } from '../../../control/play/ActiveGameController';
import { contentModalRef, feedbackModalRef } from '../../../services/GuiService';
import Board from '../../components/play/Board';
import ContentModal from '../../components/play/ContentModal';
import FeedbackModal from '../../components/play/FeedbackModal';
import { TableExitPrompt } from '../../components/play/TableExitPrompt';

export default function GamePage() {
  const { slug } = useParams();
  const [loadingGame, setLoadingGame] = useState(false);
  const [loadingGameError, setLoadingGameError] = useState(null);

  const { playStatus, gameStatus } = useSelector((state) => state.play.current.table);

  /*Prevent window unload!!!*/
  useEffect(() => {
    const listener = (event) => {
      //console.log('before Unload!!!!!');
      event.preventDefault();
      return (event.returnValue = '');
    };
    //window.addEventListener('beforeunload', listener);
    //window.addEventListener('unload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
      window.removeEventListener('unload', listener);
    };
  }, []);

  /* Fetch Game Data*/
  useEffect(() => {
    const doFetch = async () => {
      try {
        setLoadingGameError(null);
        setLoadingGame(true);
        console.log('Requesting Full Game Instance for ' + slug);
        await ActiveGameController.enterGame({ slug });
        console.log('Full Game Instance for ' + slug + ' Received');
      } catch (err) {
        setLoadingGameError(err.message);
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoadingGame(false);
      }
    };
    try {
      if (slug) {
        doFetch();
      }
    } catch (err) {}
    return () => {
      ActiveGameController.exitGame();
    };
  }, [slug]);

  const board = useSelector((state) => state.play.current?.spec?.board);

  return (
    <div id='gamePage' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ContentModal ref={contentModalRef} />
      <FeedbackModal ref={feedbackModalRef} />
      <TableExitPrompt gameStatus={gameStatus} />
      {loadingGame && (
        <div className='mb-3 flex flex-col align-center justify-top pt-2 max-w-[800px] mx-auto'>
          <Box sx={{ width: '100%' }}>
            <div className='text-xl'>Loading Game</div>
            <LinearProgress />
          </Box>
        </div>
      )}
      {loadingGameError && (
        <div className='mb-3 flex flex-col align-center justify-top pt-10 max-w-[200px] mx-auto'>
          <Link to={'/play'} className='p-3 bg-purple-400 text-white rounded-sm shadow-sm text-center font-bold'>
            Join another game
          </Link>
        </div>
      )}
      {board && <Board />}
      <Backdrop
        style={{ position: 'absolute' }}
        className='rounded-t-md'
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={gameStatus === GAME_STATUS.STARTED && playStatus === PLAY_STATUS.WAITING}>
        <div className='text-6xl'>Opponent disconnected...</div>
      </Backdrop>
    </div>
  );
}
