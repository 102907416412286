import { USER_ROLE, isValueEmpty } from '@grethics/commons';
import { AccountBoxOutlined, Close, DesignServices, ExitToAppOutlined, PlayCircle, SettingsSuggest } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PALLETE } from '../../../config/constants';
import { clearAuthCookie } from '../../../model/dao/utils/AuthHelper';
import { logoutAction } from '../../../model/store/slices/AuthSlice';
import useScreenSize from '../../../utils/useScreenSize';
import PlayerAvatar from '../play/PlayerAvatar';
import TopBreadcrumb from '../common/TopBreadcrumb';
import { truncate } from 'lodash';

function TopBar() {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { range, size } = useScreenSize(true);
  const [route, setRoute] = React.useState('play');
  const user = useSelector((state) => state.auth.user);

  const authoredGame = useSelector((state) => state.make?.current?.game);
  const playedGame = useSelector((state) => state.play?.current?.spec?.game);
  const currentGame = playedGame ?? authoredGame;
  const joinedTableSlug = useSelector((state) => state.play?.current?.table?.slug);

  const currentSlug = joinedTableSlug ?? currentGame?.slug;

  const pages = React.useMemo(() => {
    const options = [{ label: 'Play', value: 'play' }];
    if (!user) {
      return [{ label: 'Auth', value: 'auth/signin' }];
    }
    if (!isValueEmpty(authoredGame)) {
      options.unshift({ label: authoredGame.title, value: authoredGame.slug });
    }
    if ([USER_ROLE.ADMIN, USER_ROLE.AUTHOR].includes(user?.role)) {
      options.unshift({ label: 'Make', value: 'make' });
    }
    if ([USER_ROLE.ADMIN].includes(user?.role)) {
      options.unshift({ label: 'Admin', value: 'admin' });
    }
    if (!isValueEmpty(playedGame)) {
      options.push({ label: playedGame.title, value: playedGame.slug, table: joinedTableSlug });
    }
    return options;
  }, [playedGame, joinedTableSlug, authoredGame, user]);

  React.useEffect(() => {
    const path = location?.pathname;
    if (path) {
      const newRoute = path.substring(1).split('/')[0];
      // console.log('route:', newRoute);
      setRoute(newRoute);
    }
  }, [location.pathname, pages]);

  const handleOpenNavMenu = (event) => {
    setMenuOpen(!menuOpen);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    handleCloseUserMenu();
    clearAuthCookie();
    dispatch(logoutAction());
    enqueueSnackbar('You have been logged out', {
      variant: 'info',
      autoHideDuration: 2000,
    });
  };

  return (
    <AppBar position='fixed' sx={{ mb: 1, backgroundColor: 'primary.main' }}>
      <Container maxWidth={'100%'}>
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex' }}>
            <IconButton size='large' aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleOpenNavMenu} color='inherit'>
              <MenuIcon />
            </IconButton>
          </Box>
          <SwipeableDrawer anchor={'left'} open={menuOpen} onClose={handleOpenNavMenu} onOpen={handleOpenNavMenu}>
            <Box sx={{ width: 300, height: '100%', backgroundColor: 'primary.dark', color: PALLETE.CLOUDY.dark }} role='presentation'>
              <div className='flex flex-1 flex-row py-2 items-start justify-between bg-white'>
                <img src='/img/logo.jpg' width={200} style={{ borderRadius: 2, margin: 'auto' }} alt='Green Ethics' className='mr-1' />
                <IconButton sx={{ color: 'black' }} onClick={handleOpenNavMenu}>
                  <Close />
                </IconButton>
              </div>
              <List>
                {pages.map((page, index) => {
                  const itemColor = [route, joinedTableSlug, authoredGame?.slug].includes(page.value) ? PALLETE.CLOUDY.light : PALLETE.CLOUDY.dark;
                  return (
                    <ListItem key={page.value} disablePadding>
                      <ListItemButton
                        sx={{ backgroundColor: 'primary' }}
                        onClick={() => {
                          if (joinedTableSlug === page.value) {
                            return;
                          }
                          navigate(page.value);
                          handleOpenNavMenu();
                        }}>
                        <ListItemIcon>
                          {page.value === 'admin' && <SettingsSuggest fontSize='large' sx={{ color: itemColor }} />}
                          {page.value === 'make' && <DesignServices fontSize='large' sx={{ color: itemColor }} />}
                          {page.value === 'play' && <PlayCircle fontSize='large' sx={{ color: itemColor }} />}
                        </ListItemIcon>
                        <ListItemText primary={page.label} primaryTypographyProps={{ fontSize: 20, color: itemColor }} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </SwipeableDrawer>
          <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'center' }}>
            {false && currentGame && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant='h5'>{truncate(currentGame.title, { length: 18 })}</Typography>
              </Box>
            )}
            <TopBreadcrumb routes={pages} route={route} currentGame={currentGame} currentSlug={currentSlug} />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {user && (
              <>
                <Tooltip title='Account'>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <PlayerAvatar imageId={user.imageId} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={() => handleCloseUserMenu()}>
                  <Typography variant='h6' sx={{ color: 'primary.main', textAlign: 'center', px: 2 }}>
                    {user?.name}
                  </Typography>
                  <MenuItem
                    sx={{ width: 200 }}
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate('./me/profile');
                    }}>
                    <ListItemIcon>
                      <AccountBoxOutlined fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppOutlined fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;
