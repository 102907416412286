import { Collapse, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { PALLETE } from '../../../../config/constants';
import { getActiveRoute } from '../../../../utils/Utils';

export default function GameConfigPage() {  
  const { drawerOpen } = useSelector((state) => state.ui);
  const location = useLocation();
  const [route, setRoute] = useState('basics');

  useEffect(() => {
    const newRoute = getActiveRoute(location?.pathname, ['basics', 'board', 'sounds', 'access']) ?? 'basics';
    setRoute(newRoute);
  }, [location.pathname]);

  return (
    <div className='flex flex-col flex-1 w-full'>
      <Paper className='flex flex-col w-full items-start mt-2'>
        <div className='flex flex-row w-full mt-2'>
          <Collapse orientation='horizontal' in={drawerOpen} className='h-full'>
            <div className='flex flex-col w-[250px] mr-1 p-1 justify-between '>
              <div className='w-full rounded-sm p-2 text-lg' style={{ backgroundColor: PALLETE.EMERLAND.extraLight }}>
                <Typography variant='h5'>Game Elements</Typography>
              </div>
              <Paper className='flex flex-1 flex-col justify-between h-full mt-2'>
                <Tabs orientation='vertical' variant='fullWidth' value={route}>
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='basics' label={<span className='text-left w-full p-2 text-reg'>Game Basics</span>} value='basics' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='board' label={<span className='text-left w-full p-2 text-reg'>Game Board</span>} value='board' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='sounds' label={<span className='text-left w-full p-2 text-reg'>Game Sounds</span>} value='sounds' />
                  <Tab component={Link} sx={{ textTransform: 'none' }} to='access' label={<span className='text-left w-full p-2 text-reg'>Game Access</span>} value='access' />
                </Tabs>
              </Paper>
            </div>
          </Collapse>
          <div className='flex flex-col flex-1 p-1 ml-1'>
            <Outlet />
          </div>
        </div>
      </Paper>
    </div>
  );
}
