import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GameDao } from '../../model/dao/GameDao';
import { clearCurrentGameAction, setCurrentGameAction, updateCurrentGameAuthorsAction, updateGameSoundsAction } from '../../model/store/slices/make/GameSlice';
import { addGameAction, updateGameAction, deleteGameAction } from '../../model/store/slices/MakeListSlice';
import { enqueueSnackbar } from 'notistack';
import { setBoardAction, updateBoardAction } from '../../model/store/slices/make/GameBoardSlice';
import { setPathAction } from '../../model/store/slices/make/GamePathSlice';
import { parseGoosePath } from '../../utils/GameUtils';
import { deleteContentAction, setContentAction, setContentsAction } from '../../model/store/slices/make/GameContentsSlice';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';

const useGameHook = (slug) => {
  const [error, setError] = useState(null);
  const [fetched, setFetched] = useState(false);
  const { game, board, path, contents } = useSelector((state) => state.make.current);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGame = async () => {
      try {
        dispatch(setLoading(true));
        setError(null);
        if (slug) {
          const data = await GameDao.fetchAuthoredGame(slug);
          const { board, ...game } = data;
          dispatch(setCurrentGameAction({ game }));
          dispatch(setBoardAction({ board }));
        } else {
          dispatch(setCurrentGameAction({ game: {} }));
          dispatch(setBoardAction({ board: {} }));
        }
      } catch (error) {
        setError(error?.message);
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (slug && !game && !fetched) {
      fetchGame();
    }
  }, [dispatch, game, slug, fetched]);

  const createGame = useCallback(
    async (formData) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const data = await GameDao.createNewGame(formData);
        enqueueSnackbar('Game created...', { variant: 'success' });
        const { board, ...game } = data;
        dispatch(setCurrentGameAction({ game }));
        dispatch(setBoardAction({ board }));
        dispatch(addGameAction({ game }));
        return game;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const updateGame = useCallback(async (slug, data) => {
    try {
      dispatch(setLoading(true));
      setError(null);
      const game = await GameDao.updateGame(slug, data);
      enqueueSnackbar('Game updated...', { variant: 'success' });
      dispatch(setCurrentGameAction({ game }));
      dispatch(updateGameAction({ game }));
      return game;
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } finally {
      dispatch(resetLoading());
    }
  }, []);

  const deleteGame = useCallback(
    async (slug) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        await GameDao.deleteGame(slug);
        enqueueSnackbar('Game deleted...', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        dispatch(clearCurrentGameAction());
        dispatch(deleteGameAction({ slug }));
        return true;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return false;
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );
  const archiveGame = useCallback(
    async (slug) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        await GameDao.archiveGame(slug);
        enqueueSnackbar('Game archived...', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        dispatch(clearCurrentGameAction());
        dispatch(deleteGameAction({ slug }));
        return true;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return false;
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const fetchGamePath = useCallback(
    async (slug) => {
      if (!path) {
        try {
          dispatch(setLoading(true));
          setError(null);
          console.log('fetching path...');
          const boardPath = await GameDao.fetchGamePath(slug);
          const path = parseGoosePath(boardPath);
          dispatch(setPathAction({ path }));
        } catch (error) {
          enqueueSnackbar(error?.message ?? 'Something went wrong', {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } finally {
          setFetched(true);
          dispatch(resetLoading());
        }
      }
    },
    [dispatch]
  );

  const fetchGameContents = useCallback(
    async (slug) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        console.log('fetching contents...');
        const contents = await GameDao.fetchGameContents(slug);
        dispatch(setContentsAction({ contents }));
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const createGameContent = useCallback(
    async (slug, data) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const content = await GameDao.createNewGameContent(slug, data);
        enqueueSnackbar('created...', { variant: 'success' });
        dispatch(setContentAction({ content }));
        return content;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
        });
        throw error;
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const updateGamePath = useCallback(
    async (slug, data) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const boardPath = await GameDao.updateGamePath(slug, data);
        enqueueSnackbar('updated...', { variant: 'success' });
        const path = parseGoosePath(boardPath);
        dispatch(setPathAction({ path }));
        return game;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        throw new Error(error.message);
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const checkGameSlug = useCallback(
    async (slug) => {
      try {
        const { available } = await GameDao.checkSlugAvailability(slug);
        return available;
      } catch (error) {
        return false;
      } finally {
      }
    },
    [dispatch]
  );

  const updateGameBoard = useCallback(
    async (slug, data) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const board = await GameDao.updateGameBoard(slug, data);
        enqueueSnackbar('updated...', { variant: 'success' });
        dispatch(updateBoardAction({ board }));
        return board;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const updateGameAuthors = useCallback(
    async (slug, data = []) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const coAuthors = await GameDao.updateGameAuthors(
          slug,
          data.map((ca) => {
            const { gameId, userId } = ca;
            return { gameId, userId };
          })
        );
        enqueueSnackbar('updated...', { variant: 'success' });
        dispatch(updateCurrentGameAuthorsAction({ coAuthors }));
        return coAuthors;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const updateGameContent = useCallback(
    async (slug, contentId, data) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const content = await GameDao.updateGameContent(slug, contentId, data);
        enqueueSnackbar('updated...', { variant: 'success' });
        dispatch(setContentAction({ content }));
        return game;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        throw new Error(error.message);
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const updateGameSounds = useCallback(
    async (slug, sounds) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        const result = await GameDao.updateGameSounds(slug, sounds);
        enqueueSnackbar('updated...', { variant: 'success' });
        dispatch(updateGameSoundsAction({ sounds }));
        return result;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        throw new Error(error.message);
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  const deleteGameContent = useCallback(
    async (slug, contentId) => {
      try {
        dispatch(setLoading(true));
        setError(null);
        await GameDao.deleteGameContent(slug, contentId);
        enqueueSnackbar('deleted...', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        dispatch(deleteContentAction({ contentId }));
        return true;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return false;
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  return {
    game,
    board,
    path,
    contents,
    error,
    checkGameSlug,
    fetchGamePath,
    fetchGameContents,
    archiveGame,
    createGame,
    createGameContent,
    deleteGame,
    updateGame,
    updateGameBoard,
    updateGameSounds,
    updateGameAuthors,
    updateGamePath,
    updateGameContent,
    deleteGameContent,
  };
};

export default useGameHook;
