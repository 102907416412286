import { Check, Circle, Close, Delete, DeleteOutline, Edit } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import '../../../../editor.css';
import GameAvatar from '../../../components/common/GameAvatar';
import MediaLibraryModal from '../../../components/make/MediaLibraryModal';

import { isValueEmpty } from '@grethics/commons';
import useGameHook from '../../../../hooks/make/useGameHook';
import TopicEditor from '../../../components/make/TopicEditor';
import { FormPrompt } from '../../../components/make/FormPrompt';
import PageHeader from '../../../components/common/PageHeader';

export default function BoardConfigPage() {
  const { slug } = useParams();
  const mediaLibRef = useRef();
  const topicEditorRef = useRef();
  const { game, board, loading, updateGameBoard } = useGameHook(slug);
  //const { loading } = useSelector((state) => state.make.current ?? {});

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({ mode: 'all', defaultValues: { size: 10, bgMediaId: null }, values: { ...board } });

  const onSubmit = async (data) => {
    await updateGameBoard(slug, data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col flex-1' autoComplete='off'>
      <PageHeader title={'Board Settings'} />
      <FormPrompt hasUnsavedChanges={isDirty} />
      <TopicEditor ref={topicEditorRef} />
      <Paper className='flex flex-col min-h-[500px] w-full items-start flex-wrap p-2'>
        <div className='flex lg:flex-row md:flex-col w-full gap-4 mt-4'>
          <div className='flex-grow flex-col'>
            <Controller
              name='size'
              control={control}
              rules={{ required: 'Board size is required' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <Tooltip title='Side size: 10 => Board size: 10x10)'>
                    <TextField type='number' label='Side Size' {...field} />
                  </Tooltip>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name='sections'
              control={control}
              rules={{ required: 'Board should have at least one topic' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error} sx={{ my: 4 }}>
                  <List className='border border-slate-300 rounded' sx={{ px: 1 }}>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>Board Topics</InputLabel>
                    {(field.value ?? []).map((topic, idx) => {
                      if (topic.toDelete) {
                        return null;
                      }
                      return (
                        <ListItem
                          className='shadow-sm'
                          key={topic.id}
                          secondaryAction={
                            <>
                              <IconButton
                                edge='end'
                                aria-label='edit'
                                onClick={() => {
                                  topicEditorRef?.current?.show(topic, (data) => {
                                    const newValue = [...field.value];
                                    newValue[idx] = data;
                                    console.log({ newValue });
                                    setValue(field.name, newValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                                  });
                                }}>
                                <Edit color='primary' />
                              </IconButton>
                              <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => {
                                  const newValue = [...field.value];
                                  newValue[idx]['toDelete'] = true;
                                  console.log({ newValue });
                                  setValue(field.name, newValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                                }}>
                                <DeleteOutline color={'error'} />
                              </IconButton>
                            </>
                          }>
                          <ListItemAvatar>
                            <Circle sx={{ color: topic.color, fontSize: 40 }} />
                          </ListItemAvatar>
                          <ListItemText primary={topic.name} />
                        </ListItem>
                      );
                    })}
                    <div className='w-full flex flex-col items-end p-3'>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          topicEditorRef.current?.show({}, (data) => {
                            data.boardId = board.id;
                            setValue(field.name, [...field.value, { ...data }], { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                          });
                        }}>
                        {' '}
                        Add Topic
                      </Button>
                    </div>
                  </List>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div className='flex flex-col flex-none'>
            <Controller
              name='bgMediaId'
              control={control}
              rules={{ required: 'Background image is required' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <div className='px-2 flex flex-col border rounded-md border-gray-300 min-h-[320px] min-w-[320px] items-center'>
                    <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>Background Image</InputLabel>
                    <div className='mt-3 top-0 z-0'>
                      <GameAvatar imageId={field.value} height={300} width={300} />
                    </div>
                    <div className='flex flex-row justify-between pt-1 w-[290px] absolute top-1'>
                      <IconButton
                        onClick={() => {
                          mediaLibRef.current?.show(game?.id);
                        }}
                        sx={{ borderRadius: 1, py: 1, px: 0 }}
                        color='primary'
                        className='z-10'>
                        <Edit />
                      </IconButton>
                      {field.value && (
                        <IconButton
                          onClick={() => {
                            setValue(field.name, undefined, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                          }}
                          sx={{ borderRadius: 1, py: 1, px: 0 }}
                          color='error'
                          className='z-10'>
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <FormHelperText>{error?.message}</FormHelperText>
                  <div>
                    <MediaLibraryModal
                      ref={mediaLibRef}
                      mediaId={field.value}
                      mediaTypes={['image']}
                      onSelect={(mediaId) => {
                        setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                      }}
                    />
                  </div>
                </FormControl>
              )}
            />
          </div>
        </div>
        <div className='flex flex-row w-full gap-4 my-4'></div>
      </Paper>
      <Paper className='flex flex-row flex-1 mt-1 p-2 items-center justify-between'>
        <div>
          {!isValueEmpty(dirtyFields) && (
            <Button
              startIcon={<Close />}
              variant='outlined'
              color='error'
              disabled={loading}
              onClick={() => {
                reset();
              }}>
              Cancel
            </Button>
          )}
        </div>
        <Button startIcon={<Check />} variant='outlined' color='primary' type='submit' disabled={isValueEmpty(dirtyFields) || loading}>
          Save
        </Button>
      </Paper>
    </form>
  );
}
