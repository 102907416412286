import { io } from 'socket.io-client';
import { GAME_STATUS, SOCKET_EVENTS } from '@grethics/commons';
import { AppStore } from '../../model/store/AppStore';
import { setPlayerAnswerAction, setPlayerScoreAction } from '../../model/store/slices/PlaySlice';
import { GuiService } from '../../services/GuiService';
import { PassiveGameController } from './PassiveGameController';
import { BACKEND_URL } from '../../config/config';

let socket = null;
let token = null;

const setAuthToken = (tkn) => {
  token = tkn;
};

const eventListeners = [
  {
    event: SOCKET_EVENTS.PLAYER_CONNECTED,
    listener: ({ playerId }) => {
      PassiveGameController.opponentConnected(playerId);
    },
  },
  {
    event: SOCKET_EVENTS.PLAYER_DISCONNECTED,
    listener: ({ playerId }) => {
      PassiveGameController.opponentDisconnected(playerId);
    },
  },
  {
    event: SOCKET_EVENTS.SET_GAME_STATUS,
    listener: ({ status, side }) => {
      if (status === GAME_STATUS.STARTING_UP) {
        PassiveGameController.startGameUp(side);
      } else if (status === GAME_STATUS.STARTED) {
        PassiveGameController.startGamePlay();
      } else if (status === GAME_STATUS.COMPLETED) {
        PassiveGameController.completeGame();
      }
    },
  },
  {
    event: SOCKET_EVENTS.SET_ACTIVE_PLAYER,
    listener: ({ side }) => {
      PassiveGameController.setActivePlayer(side);
    },
  },
  {
    event: SOCKET_EVENTS.SET_LAST_ROLL,
    listener: ({ side, value }) => {
      PassiveGameController.opponentDiceRolled(side, value);
    },
  },
  {
    event: SOCKET_EVENTS.SET_PLAYER_SCORE,
    listener: ({ side, value, score }) => {
      AppStore.dispatch(setPlayerScoreAction({ side, score }));
    },
  },
  {
    event: SOCKET_EVENTS.INTERACTION_COMPLETED,
    listener: ({ side, value }) => {
      GuiService.hideContent();
    },
  },
  {
    event: SOCKET_EVENTS.HIDE_FEEDBACK,
    listener: ({ side }) => {
      GuiService.hideFeedback();
    },
  },
  {
    event: SOCKET_EVENTS.SET_PLAYER_ANSWER,
    listener: ({ side, answer, isCorrect }) => {
      AppStore.dispatch(setPlayerAnswerAction(side, answer, isCorrect));
    },
  },
  {
    event: SOCKET_EVENTS.SUBMIT_PLAYER_ANSWER,
    listener: ({ side, answer, isCorrect }) => {
      PassiveGameController.opponentResponseSubmitted(side);
    },
  },
];

const setUpListeners = () => {
  if (socket) {
    eventListeners.forEach((ev) => {
      socket.on(ev.event, ev.listener);
    });
  }
};

const connect = (slug, moderated = false) => {
  if (!socket || !socket.connected) {
    try {
      socket = io(BACKEND_URL, {
        reconnectionDelayMax: 10000,
        extraHeaders: {
          Authorization: `${token}`,
        },
        query: {
          slug,
          moderated,
        },
      });

      setUpListeners();
      socket.prependAny((event, ...args) => {
        console.debug('ws:', event, ...args);
      });
    } catch (err) {
      console.error('gxe:connector:connect:catch', err);
      throw err;
    }
  } else {
    console.debug('gxe:connector:connect: already connected...');
  }
};

const disconnect = () => {
  if (socket) {
    try {
      socket.removeAllListeners();
      socket.disconnect();
      socket = null;
      console.debug('gxe:connector:disconnect: disconnected!');
    } catch (err) {
      console.debug('gxe:connector:disconnect:catch', err);
    }
  } else {
    console.debug('gxe:connector:disconnect: already disconnected...');
  }
};

const setActivePlayer = (side) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SET_ACTIVE_PLAYER, {
      side,
    });
  }
};

const setPlayerRoll = (side, value) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SET_LAST_ROLL, {
      side,
      value,
    });
  }
};

const hideFeedback = (side) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.HIDE_FEEDBACK, {
      side,
    });
  }
};

const setPlayerScore = (side, score) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SET_PLAYER_SCORE, {
      side,
      score,
    });
  }
};

const startGameUp = (side) => {
  setGameStatus(GAME_STATUS.STARTING_UP, side);
};

const startGamePlay = (side) => {
  setGameStatus(GAME_STATUS.STARTED, side);
};

const setGameStatus = (status, side) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SET_GAME_STATUS, {
      status,
      side,
    });
  }
};

const setGameResult = (side) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SET_GAME_RESULT, {
      side,
    });
  }
};

const setPlayerCurrentSquare = (side, idx) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SET_CURRENT_SQUARE, { side, idx });
  }
};

const setPlayerAnswer = (side, answer, isCorrect) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SET_PLAYER_ANSWER, { side, answer, isCorrect });
  }
};

const submitPlayerAnswer = (side, answer, isCorrect, points, score, squareNum, contentId) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.SUBMIT_PLAYER_ANSWER, { side, answer, isCorrect, points, score, squareNum, contentId });
  }
};

const interactionCompleted = (side) => {
  if (socket) {
    socket.emit(SOCKET_EVENTS.INTERACTION_COMPLETED, { side });
  }
};

export const GameObserver = {
  setAuthToken,
  connect,
  disconnect,
  setActivePlayer,
  setPlayerRoll,
  startGameUp,
  startGamePlay,
  setGameStatus,
  setPlayerCurrentSquare,
  interactionCompleted,
  hideFeedback,
  setPlayerScore,
  setGameResult,
  setPlayerAnswer,
  submitPlayerAnswer,
};
