import { CustomResponseError } from '../../utils/Utils';
import { ApiClient } from './utils/ApiClient';

export const GameDao = {
  fetchAuthoredGames: async () => {
    try {
      const { data } = await ApiClient.get(`/make/games`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  fetchAuthoredGame: async (slug) => {
    try {
      const { data } = await ApiClient.get(`/make/games/${slug}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  fetchGamePath: async (slug) => {
    try {
      const { data } = await ApiClient.get(`/make/games/${slug}/path`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  fetchGameContents: async (slug) => {
    try {
      const { data } = await ApiClient.get(`/make/games/${slug}/contents`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async fetchPlayableGames() {
    try {
      const { data } = await ApiClient.get(`/play/games`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async checkSlugAvailability(slug) {
    try {
      const { data } = await ApiClient.get(`/make/games/${slug}/check`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async createNewGame(payload) {
    try {
      const { data } = await ApiClient.post(`/make/games`, payload);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async createNewGameContent(slug, content) {
    try {
      const { data } = await ApiClient.post(`/make/games/${slug}/contents`, { content });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async updateGame(slug, payload) {
    try {
      const { data } = await ApiClient.patch(`/make/games/${slug}`, payload);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async updateGameBoard(slug, payload) {
    try {
      const { data } = await ApiClient.patch(`/make/games/${slug}/board`, payload);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async updateGamePath(slug, path) {
    try {
      const { data } = await ApiClient.patch(`/make/games/${slug}/path`, {
        path,
      });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async updateGameSounds(slug, sounds) {
    try {
      const { data } = await ApiClient.patch(`/make/games/${slug}/sounds`, {
        sounds,
      });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async updateGameAuthors(slug, coAuthors) {
    try {
      const { data } = await ApiClient.patch(`/make/games/${slug}/authors`, {
        coAuthors,
      });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async updateGameContent(slug, contentId, content) {
    try {
      const { data } = await ApiClient.patch(`/make/games/${slug}/contents/${contentId}`, { content });
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async archiveGame(slug) {
    try {
      const { data } = await ApiClient.get(`/make/games/${slug}/archive`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async deleteGame(slug) {
    try {
      const { data } = await ApiClient.delete(`/make/games/${slug}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async forceDeleteGame(slug) {
    try {
      const { data } = await ApiClient.delete(`/make/games/${slug}/force-delete`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async deleteGameContent(slug, contentId) {
    try {
      await ApiClient.delete(`/make/games/${slug}/contents/${contentId}`);
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async getGameRankings(slug, ldrbdId) {
    try {
      const targetUrl = slug === 'any' ? `/rankings/${ldrbdId}` : `/rankings/game/${slug}`;
      const { data } = await ApiClient.get(targetUrl);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
  async getLeaderboardRankings(slug, ldrbdId, timeFrame) {
    try {
      const { data } = await ApiClient.get(`/rankings/${ldrbdId}/${timeFrame}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
};
