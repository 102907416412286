import React, { useCallback, useEffect, useState } from 'react';
import { SystemDao } from '../../model/dao/SystemDao';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import { setGames, updateUserAction } from '../../model/store/slices/admin/AdminSlice';
import { enqueueSnackbar } from 'notistack';
import { isValueEmpty } from '@grethics/commons';

export default function useSysGames() {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { games } = useSelector((state) => state.admin);
  const { loading } = useSelector((state) => state.ui);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        dispatch(setLoading(true));
        const games = await SystemDao.getGames();
        console.log('games fetched', games);
        dispatch(setGames({ games }));
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (!fetched && !loading) {
      fetchGames();
    }
  }, [fetched, dispatch, loading]);

  const updateGame = useCallback(
    async (data) => {
      try {
        dispatch(setLoading(true));
        const user = await SystemDao.updateUser(data);
        enqueueSnackbar('User updated...', { variant: 'success' });
        dispatch(updateUserAction({ user }));
        return user;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  return { games, updateGame };
}
