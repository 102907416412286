// FormPrompt.js

import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { GuiService } from '../../../services/GuiService';
import { GAME_STATUS } from '@grethics/commons';

export const TableExitPrompt = ({ gameStatus }) => {
  useEffect(() => {
    const onBeforeUnload = (e) => {
      if ([GAME_STATUS.STARTED, GAME_STATUS.STARTING_UP].includes(gameStatus)) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [gameStatus]);

  let block = useBlocker([GAME_STATUS.STARTED, GAME_STATUS.STARTING_UP].includes(gameStatus));

  useEffect(() => {
    if (block.state === 'blocked') {
      GuiService.showAlert({
        message: 'Game is not Completed. Are you sure you want to leave?',
        actions: [
          { title: 'Leave', color: 'error', callback: () => block.proceed() },
          { title: 'Stay', color: 'success', callback: () => block.reset() },
        ],
      });
    }
  }, [block]);
};
