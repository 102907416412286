import { Box, Typography } from '@mui/material';
import React from 'react';

export default function TabPanel({ value, index, ...props }) {
  const { children, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div className='p-1 w-full'>{children}</div>}
    </div>
  );
}
