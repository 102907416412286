import React, { useCallback, useEffect, useState } from 'react';
import { SystemDao } from '../../model/dao/SystemDao';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoading, setLoading } from '../../model/store/slices/UiSlice';
import { setUsers, updateUserAction } from '../../model/store/slices/admin/AdminSlice';
import { enqueueSnackbar } from 'notistack';
import { isValueEmpty } from '@grethics/commons';

export default function useSysUsers() {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { users } = useSelector((state) => state.admin);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch(setLoading(true));
        const users = await SystemDao.getUsers();
        console.log('users fetched', users);
        dispatch(setUsers({ users }));
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (isValueEmpty(users) && !fetched) {
      fetchUsers();
    }
  }, [fetched, users, dispatch]);

  const fetchUser = useCallback(async (id) => {
    try {
      console.log('fetching user');
      const user = await SystemDao.getUser(id);
      return { ...user };
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }, []);

  const updateUser = useCallback(
    async (data) => {
      try {
        dispatch(setLoading(true));
        const user = await SystemDao.updateUser(data);
        enqueueSnackbar('User updated...', { variant: 'success' });
        dispatch(updateUserAction({ user }));
        return user;
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        dispatch(resetLoading());
      }
    },
    [dispatch]
  );

  return { users, fetchUser, updateUser };
}
