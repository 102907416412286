import React, { useEffect, useState } from 'react';
import { SystemDao } from '../../model/dao/SystemDao';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, resetLoading } from '../../model/store/slices/UiSlice';
import { setStats } from '../../model/store/slices/admin/AdminSlice';

export default function useSysStats() {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const stats = useSelector((state) => state.admin.stats);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        dispatch(setLoading(true));
        const stats = await SystemDao.getStats();
        console.log('system stats fetched', stats);
        dispatch(setStats({ stats }));
      } finally {
        setFetched(true);
        dispatch(resetLoading());
      }
    };
    if (!fetched) {
      fetchStats();
    }
  }, [fetched, dispatch]);

  return { stats };
}
