import { createSlice } from '@reduxjs/toolkit';
import { authSlice } from './AuthSlice';

const initialState = { players: { left: null, right: null }, game: undefined, path: undefined, moderatorId: undefined };

export const specSlice = createSlice({
  name: 'spec',
  initialState,
  reducers: {
    setGameAction: (state, action) => {
      const { game, players, board, moderatorId } = action.payload;
      state.game = game;
      state.board = board;
      state.moderatorId = moderatorId;
      state.players = players;
    },
    setPlayersAction: (state, action) => {
      const { players } = action.payload;
      state.players = players;
    },
    leaveGameAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('auth/logoutAction', () => {
        return initialState;
      })
      .addCase('play/playerConnectedAction', (state, action) => {
        const { playerId } = action.payload;
        const player = Object.values(state.players).find((p) => p?.id === playerId);
        if (player) {
          player.connected = true;
        }
      })
      .addCase('play/playerDisconnectedAction', (state, action) => {
        const { playerId } = action.payload;
        const player = Object.values(state.players).find((p) => p?.id === playerId);
        if (player) {
          state.players[player.side].connected = false;
        }
      });
  },
});

export const { setPlayersAction, setGameAction, leaveGameAction } = specSlice.actions;
export default specSlice.reducer;
